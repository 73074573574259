import React, { useState, useEffect } from 'react'
import { handleRnnClg, HandlePDFChange1, HandlePDFChange3, HandlePDFChange4, handleNewSwindonClg, handleEastleigh, handleBristolPdf, tecPartnerPdf, lincolnCollegePDF, handleNCGPdf, HandlePDFNWSLC, HandleWightCollegePDF, handleSuttonColdFieldPDF, handleSccuPDF, handleHertfordshirePDF, handlePortsMouth, handleWiltShireCollege, handleBasingStokeCollege, handleNSWLCollege, handleLondonSECollege, handleLMPCollege, handleFTTCollege, handleRnnClg23OLD, handleHeartOfYorkshireCollege } from './pdfFunctions'
import { useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MaterialTable from 'material-table';
import { getCompleteUserFormDetails } from '../actions/formActions'
import { alpha } from '@material-ui/core/styles'
import { PostCodeListName } from './postcodeCheck'
import { getCollegeCount } from '../services/adminService'
import moment from 'moment'
import Papa from 'papaparse';
import { lmpProcessedData } from '../actions/extraActions'
import { countriesWithCodes, nationalitiesWithCodes } from './countries'

const Formdata = (props) => {
    const { history } = props;
    const dispatch = useDispatch()

    const [allCol, setAllCol] = useState([])
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [allColleges, setAllColleges] = useState([])
    const [collegesN, setCollegesN] = useState([])
    const [pdfCol, setpdfCol] = useState([])
    const [accounts, setAccounts] = useState([])
    const [callcenter, setCallcenter] = useState([])
    const [allCourses, setAllCourses] = useState({})
    const [courses, setCourses] = useState([])
    // let groupArray = []

    const [show, setShow] = useState(false);
    const [notes, setNotes] = useState('')
    const [notesSelected, setSelected] = useState('')

    function downloadLMPCSV() {
        fetch('/lmpcsv.csv')
            .then(response => response.text())
            .then(text => {
                // Parse CSV data
                const parsedData = Papa.parse(text);
                const originalData = parsedData.data;

                // Keep the first two rows intact
                const firstTwoRows = originalData.slice(0, 1);
                // Add custom data below the first two rows
                const customData = []

                arr.map((x, i) => {
                    if (new Date(x.dateApproved) >= new Date(startDate.setHours(0, 0, 0, 0)) && new Date(x.dateApproved) <= new Date(endDate.setHours(23, 59, 59, 999))) {
                        customData.push(lmpProcessedData(x))
                    }
                })
                const modifiedData = [...firstTwoRows, ...customData];

                // Convert modifiedData to CSV format
                const modifiedCSV = Papa.unparse(modifiedData);

                // Save the modified CSV file
                const blob = new Blob([modifiedCSV], { type: 'text/csv;charset=utf-8' });
                saveAs(blob, 'modified_lmpcsv.csv');
            })
            .catch(error => console.error('Error:', error));
    }




    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setSelected(id)
        setShow(true)
    };

    const handleNotes = async () => {
        setShow(false)
        const response = await axios.post(process.env.REACT_APP_API_URL + '/support/notes-add', {
            FormId: notesSelected,
            note: notes,
        })
        if (response.status === 200) {
            toast("Query sent to the team.")
        }
        console.log(response)
    }



    useEffect(async () => {
        const data = await axios.get('/contracts/all-for-studentsList')
        setAllCol(data.data.data)
        let array = []
        let array2
        for (let i = 0; i < data.data.data.length; i++) {
            array.push([
                data.data.data[i]._id, data.data.data[i].name
            ])
            let obj = Object.fromEntries(array)
            if (i === data.data.data.length - 1) {
                array2 = obj
            }
        }
        // console.log(array2)
        setAllColleges(array2)


        const pdf = await axios.get('/admin/collegedata')
        setpdfCol(pdf.data.data)
    }, [])

    useEffect(async () => {
        let response = await axios.get(process.env.REACT_APP_API_URL + '/course/all')
        setCourses(response.data.data)
        let data = response.data.data.sort((a, b) => a.courseCode.localeCompare(b.courseCode))
        let final = {}
        for (let i = 0; i < data.length; i++) {
            final[data[i].courseCode] = data[i].courseCode
        }
        setAllCourses(final)
    }, [])

    useEffect(async () => {
        const data = await axios.get('/support/getallnadmin')
        let array = []
        let array2
        for (let i = 0; i < data.data.data.length; i++) {
            array.push([
                data.data.data[i]._id, data.data.data[i].name
            ])
            let obj = Object.fromEntries(array)
            if (i === data.data.data.length - 1) {
                array2 = obj
            }
        }
        setAccounts(array2)
        array = []
        array = []
        const data2 = await axios.get('/callcenter/all')
        for (let i = 0; i < data2.data.data.length; i++) {
            array.push([
                data2.data.data[i]._id, data2.data.data[i].name
            ])
            let obj = Object.fromEntries(array)
            if (i === data2.data.data.length - 1) {
                array2 = obj
            }
        }

        setCallcenter(array2)
    }, [])

    useEffect(() => {
        getCollegeCount()
            .then((res) => {
                setCollegesN(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])


    const [groupArray, setGroupArray] = useState([])
    const { posts } = props
    if (!posts || posts.length === 0) return <p>Cannot find any posts</p>
    const arr = []
    //converting post data into array
    const obj = Object.entries(posts.data)
    obj.forEach(([key, value]) => arr.push(value))
    arr.sort((a, b) => new Date(b.dateApproved) - new Date(a.dateApproved));


    // compute items

    let a = arr.map((item) => {
        // item.collegeID
        let collegeName = '-'
        if (item.collegeId !== undefined || item.collegeID !== null) {
            for (let i = 0; i < allColleges.length; i++) {
                if (item.collegeID === allColleges[i]._id) {
                    collegeName = allColleges[i].collegeName
                    break
                }
            }
        }
        return {
            ...item,
            Name: item.personalDetails.firstName + ' ' + item.personalDetails.lastName,
            English: (item.assessment ? "✓" : ""),
            enrolled: (item.isEnrolled ? "✓" : ""),
            newDeclaration: (item.newDeclaration ? "✓" : ""),
            email: item.personalDetails.email,
            college: collegeName,
            appliedCourse: item?.detailsFormData?.appliedCourse || 'asd',
            city: item.personalDetails.city,
            phone: item.personalDetails.telephone,
            _classes: item.status
        }
    })
    const handleGroup = (id) => {
        // console.log(groupArray.includes(id));
        if (groupArray.includes(id)) {
            let temp = groupArray.filter(function (item) {
                return item !== id
            })
            setGroupArray(temp)
            let nam = a.find((x) => x._id === id)
            toast('Removed: ' + nam.personalDetails.firstName + ' ' + nam.personalDetails.lastName)
        } else {
            let temp = [...groupArray]
            temp.push(id)
            setGroupArray(temp)
            let nam = a.find((x) => x._id === id)
            toast('Added: ' + nam.personalDetails.firstName + ' ' + nam.personalDetails.lastName)
        }
    }
    const handleStatus = async (newStatus, formId) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_status', {
            formId: formId,
            status: newStatus,
        })
        if (response.data.success) {
            toast('Status Changed')
            return true
        } else {
            toast.error(response.data.message)
            return false
        }
    }
    const handleSupportMember = async (newMember, formId) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/assignedToSupport', {
            formId: formId,
            supportStaff: newMember,
        })
        if (response.status === 200) {
            toast('Assigned to a Member')
            return true
        } else {
            return false
        }
    }
    const handleCollege = async (newCollege, data) => {
        // in this page, college is basically contract
        let sclg = allCol.find(x => x._id === newCollege)
        let scollege = collegesN.find(x => x._id === sclg.collegeID)
        let validation = true
        let errorString = ''
        if (sclg.coursesList.includes(data.detailsFormData.appliedCourse)) {
            console.log("Course Exists")
        } else {
            console.log("Course Doesnt Exists")
            validation = false
            errorString = errorString + `Course is not acceptable by this college.`
        }


        let array1 = await PostCodeListName(data.personalDetails.postcode)
        let a = sclg.whitelist.filter(value => array1.includes(value))
        if (a.length > 0) {
            console.log("Post Code available")
        } else {
            console.log("Post Code unavailable")
            validation = false
            errorString = errorString + `
            PostCode is not acceptable by this college.`
        }

        //type of proof
        if (scollege.proofs.includes(data.typeOfProof)) {
            console.log("Type of proof included")
        } else {
            validation = false
            errorString = errorString + `
            This College accepts only ` + scollege.proofs.toString() + `.`
        }

        //fullTimeEducationElse
        if (scollege.fullTimeEducationElse) {
            if (data.qualificationDetails.fullTimeEducationElse) {
                console.log("fullTimeEducationElse available")
            } else {
                console.log("fullTimeEducationElse unavailable")
                validation = false
                errorString = errorString + `
                This College requires "Full time education else where".`
            }
        }

        //inWhichCountryDoYouLive
        if (scollege.inWhichCountryDoYouLive) {
            if (data.oppDetails.inWhichCountryDoYouLive) {
                console.log("inWhichCountryDoYouLive available")
            } else {
                console.log("inWhichCountryDoYouLive unavailable")
                validation = false
                errorString = errorString + `
                This College requires "In Which Country Do you Live?".`
            }
        }

        //longEnglishTest
        if (scollege.longEnglishTest) {
            if (data.assessment) {
                if (data.assessment.answer1) {
                    console.log("longEnglishTest available")
                } else {
                    console.log("longEnglishTest unavailable")
                    validation = false
                    errorString = errorString + `
                    This College requires the 15 Question English Assessment.`
                }
            }
        }

        //EnglishTest
        if (scollege.softEnglishTest) {
            if (data.assessment) {
                if (data.assessment.english) {
                    console.log("softEnglishTest available")
                } else {
                    console.log("softEnglishTest unavailable")
                    validation = false
                    errorString = errorString + `
                    This College requires English Assessment.`
                }
            }
        }

        //employmentdetailsNecessary
        if (scollege.employmentdetailsNecessary) {
            if (data.employmentDetails.employerName) {
                console.log("employmentdetailsNecessary available")
            } else {
                console.log("employmentdetailsNecessary unavailable")
                validation = false
                errorString = errorString + `
                This College requires Employment Details (Employer Name, Address, PostCode, Phone).`
            }
        }


        console.log('validation', validation)
        if (validation) {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_college', {
                formId: data._id,
                newCollegeId: newCollege,
            })
            if (response.status === 200) {
                toast('College Changed')
                return true
            } else {
                return false
            }
        } else {
            toast.error(errorString)
            return false
        }
    }


    const handleCourse = async (newCourse, formId) => {
        let response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_course', {
            formId: formId,
            newCourse: newCourse,
        })
        if (response.status === 200) {
            toast('Course Changed')
            return true
        } else {
            return false
        }
    }
    const handleCompletedBy = async (newVal, formId) => {
        let response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_completedBy', {
            formId: formId,
            newVal: newVal,
        })
        if (response.status === 200) {
            toast('Completed by Changed')
            return true
        } else {
            return false
        }
    }

    const handleDownloadedCheck = async (isChecked, formId) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_is_downloaded', {
            formId: formId,
            isChecked: isChecked,
        })
        if (response.status === 200) {
            toast('Status Changed')
            return true
        } else {
            return false
        }
    }

    const handleEnrolledCheck = async (isEnrolled, formId) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_is_enrolled', {
            formId: formId,
            isEnrolled: isEnrolled,
        })
        if (response.status === 200) {
            toast('Status Changed')
            return true
        } else {
            return false
        }
    }

    const handleActiveByCollege = async (isChecked, formId) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_is_active_college', {
            formId: formId,
            isChecked: isChecked,
        })
        if (response.status === 200) {
            toast('Status Changed')
            return true
        } else {
            return false
        }
    }



    function pivot(arr) {
        var mp = new Map()

        function setValue(a, path, val) {
            if (Object(val) !== val) {
                // primitive value
                var pathStr = path.join('.')
                var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr)
                a[i] = val
            } else {
                for (var key in val) {
                    setValue(a, key == '0' ? path : path.concat(key), val[key])
                }
            }
            return a
        }

        var result = arr.map((obj) => setValue([], [], obj))
        return [[...mp.keys()], ...result]
    }

    function toCsv(arr) {
        return arr
            .map((row) => row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(','))
            .join('\n')
    }

    const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmation',
            closeOnEscape: true,
            closeOnClickOutside: true,
            message: 'Are you Sure you want to delete this application?',
            buttons: [
                {
                    label: 'Confirm Delete',
                    onClick: async () => {
                        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_status', {
                            formId: id,
                            status: "Deleted",
                        })
                        if (response.status === 200) {
                            toast('Appliction Deleted')
                            window.location.reload()
                            return true
                        } else {
                            return false
                        }
                    }
                }
            ]
        });

    }


    // let groupArrayNames = []


    const LincolnCollegeCourseCodes = [
        {
            name: 'Mental Health Problems',
            code: '75439'
        },
        {
            name: 'Counselling Skills',
            code: '75900'
        },
        {
            name: 'Dignity and Safeguarding in Adult Health and Social Care',
            code: '74596'
        },
        {
            name: 'Equality and Diversity',
            code: '76924'
        },
        {
            name: 'Team Leading',
            code: '74274'
        },
        {
            name: 'Customer Service',
            code: '76659'
        },
        {
            name: 'Business Administration',
            code: '74626'
        },
        {
            name: 'Principles of Warehousing',
            code: '76804'
        },
        {
            name: 'Information, Advice or Guidance',
            code: '77062'
        },
        {
            name: "Children and Young People's Mental Health",
            code: '76825'
        },
        {
            name: 'Dementia Care',
            code: '76779'
        },
        {
            name: 'End of Life Care',
            code: '74987'
        },
        {
            name: 'Understanding Autism',
            code: '76053'
        },
        {
            name: 'Caring for children and young people',
            code: '74774'
        },
        {
            name: 'Certificate in Self-harm and Suicide Awareness and Prevention',
            code: '75000'
        },
        {
            name: 'Mental Health First Aid',
            code: '83023'
        },
        {
            name: 'Certificate in Nutrition and Health',
            code: '74229'
        },
        {
            name: 'Learn Organisation Management Techniques',
            code: '76479'
        },
        {
            name: 'Understanding Specific Learning Difficulties',
            code: '76991'
        },
        {
            name: 'Preparing to Work in Adult Social Care',
            code: '76747'
        },
    ]


    function downloadCSV() {
        var toDownload = []

        for (var i = 0; i < arr.length; i++) {
            // if (arr[i].dateApproved === undefined || arr[i].dateApproved === undefined) continue
            if (new Date(arr[i].dateApproved) >= new Date(startDate).setHours(0, 0, 0, 0) && new Date(arr[i].dateApproved) <= new Date(endDate).setHours(23, 59, 59, 999)) {
                //lincoln
                if (localStorage.getItem('collegeID') === "645a1475da6dcb0014c849f6") {

                    const parsedDate = moment(arr[i].personalDetails.dob);
                    const dateOfBirth = parsedDate.format("DD/MM/YYYY")
                    toDownload.push({
                        Course: (arr[i].detailsFormData.appliedCourse).toString(),
                        'Offering ID': (LincolnCollegeCourseCodes.find(x => x.name === arr[i].detailsFormData.appliedCourse))?.code,
                        Title: arr[i].personalDetails.title,
                        Firstname: arr[i].personalDetails.firstName,
                        MiddleName: arr[i].personalDetails.middleName,
                        Surname: arr[i].personalDetails.lastName,
                        Gender: arr[i].personalDetails.gender,
                        DOB: dateOfBirth,
                        AddressL1: arr[i].personalDetails.addLine1,
                        AddressL2: arr[i].personalDetails.city,
                        AddressL3: arr[i].personalDetails.county,
                        PostCode: arr[i].personalDetails.postcode,
                        TelephoneNo: arr[i].personalDetails.telephone,
                        Email: arr[i].personalDetails.email,
                        NINo: arr[i].personalDetails.nationalInsNo,
                        EmgL1: arr[i].emergencyDetails.emergencyContactName,
                        EmgL2: arr[i].emergencyDetails.emergencyTelephone,
                        EmgNi: arr[i].emergencyDetails.relationshipToLearner,
                        EmpSt: arr[i].employmentDetails.employementStatus,
                        EmpInt: arr[i].employmentDetails.hoursPerWeek,
                        EmpPeriod: arr[i].employmentDetails.length,
                        DateofEmp: arr[i].employmentDetails.startDateEmployment,
                        Q: arr[i].qualificationDetails.level,
                        Ethnicity: arr[i].oppDetails.ethnicOrigin,
                        LearningDiff: arr[i].oppDetails.disabilities,
                        UKresident: arr[i].oppDetails.resident,
                        Nationality: arr[i].oppDetails.nationality,
                        CountryofRes: arr[i].oppDetails.inWhichCountryDoYouLive,
                        CrimConv: arr[i].oppDetails.criminalConv,
                        CountryofBirth: arr[i].oppDetails.countryOfBirth,
                        ID: arr[i].typeOfProof,
                        identifier: "freecourses",
                        JSA: arr[i].employmentDetails.dValue1 ? 'Yes' : 'No',
                        'Income Support': arr[i].employmentDetails.dValue2 ? 'Yes' : 'No',
                        'Council Tax Benefit': arr[i].employmentDetails.dValue3 ? 'Yes' : 'No',
                        'ESA (Any)': arr[i].employmentDetails.dValue5 ? 'Yes' : 'No',
                        'Incapacity Benefit': arr[i].employmentDetails.dValue6 ? 'Yes' : 'No',
                        'Housing Benefit': arr[i].employmentDetails.dValue7 ? 'Yes' : 'No',
                        'Universal Credit': arr[i].employmentDetails.dValue8 ? 'Yes' : 'No',
                        'Employed but on low wage': arr[i].employmentDetails.dValue9 ? 'Yes' : 'No',
                        'Are you retired?': arr[i].employmentDetails.dValue10 ? 'Yes' : 'No',
                    })
                }

                if (localStorage.getItem('collegeID') === "64941c263cbbe80284999145") {
                    //nwslc
                    const parsedDate = moment(arr[i].personalDetails.dob);
                    const dateOfBirth = parsedDate.format("YYYY-MM-DD")
                    const newObj = {
                        ...arr[i],
                        personalDetails: {
                            ...arr[i].personalDetails,
                            dob: dateOfBirth
                        }
                    };
                    toDownload.push(newObj);
                } else if (localStorage.getItem('collegeID') === "66fba0d9e573f5f419ef1dc2") {
                    //heart of yorkshire
                    const parsedDate = moment(arr[i].personalDetails.dob);
                    const parsedApprovedDate = moment(arr[i].dateApproved);
                    const dateOfBirth = parsedDate.format("DD/MM/YYYY")
                    const startDate = moment(arr[i].employmentDetails.startDateEmployment)
                    let gender
                    if (arr[i].personalDetails.gender === 'Female') {
                        gender = 'F'
                    } else {
                        gender = 'M'
                    }

                    function splitPostcode(postcode) {
                        const outward = postcode.slice(0, postcode.length - 3); // First part
                        const inward = postcode.slice(-3); // Last 3 characters
                        return { outward, inward };
                    }

                    function capitalizeCountryName(countryName) {
                        return countryName.split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ');
                    }

                    function getNationalityCode(countryName) {
                        console.log(countryName)
                        const formattedName = capitalizeCountryName(countryName);
                        const code = nationalitiesWithCodes.find(x => x.label === formattedName)?.value
                        console.log(code)
                        return code || ''
                    }

                    function getCountryCode(countryName) {
                        const formattedName = capitalizeCountryName(countryName);
                        const code = countriesWithCodes.find(x => x.name.toString() === formattedName)?.code
                        return code || ""
                    }


                    let criminal
                    if (arr[i].oppDetails.criminalConv === "Yes") {
                        criminal = 2
                    } else {
                        criminal = 3
                    }

                    let ethnicity

                    if (arr[i].oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
                        ethnicity = '31'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Irish') {
                        ethnicity = '32'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
                        ethnicity = '33'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Any other white background') {
                        ethnicity = '34'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'White and Black Carribean') {
                        ethnicity = '35'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'White and Black African') {
                        ethnicity = '36'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'White and Asian') {
                        ethnicity = '37'
                    } else if (
                        arr[i].oppDetails.ethnicOrigin ===
                        'Any other mixed/multiple ethnic background'
                    ) {
                        ethnicity = '38'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Indian') {
                        ethnicity = '39'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Pakistani') {
                        ethnicity = '40'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Bangladeshi') {
                        ethnicity = '41'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Chinese') {
                        ethnicity = '42'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Any other Asian background') {
                        ethnicity = '43'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'African') {
                        ethnicity = '44'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Caribbean') {
                        ethnicity = '45'
                    } else if (
                        arr[i].oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
                    ) {
                        ethnicity = '46'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Arab') {
                        ethnicity = '47'
                    } else if (arr[i].oppDetails.ethnicOrigin === 'Any other ethnic group') {
                        ethnicity = '98'
                    }
                    let disability
                    let disabilityCode = []
                    if (arr[i].oppDetails.disabilities === 'Yes') {
                        disability = 1
                        if (arr[i].oppDetails.dValue1) {
                            disabilityCode.push('4')
                            // form.getCheckBox('Visual impairment').check()
                        }
                        if (arr[i].oppDetails.dValue2) {
                            disabilityCode.push('5')
                            // form.getCheckBox('Hearing impairment').check()
                        }
                        if (arr[i].oppDetails.dValue3) {
                            disabilityCode.push('6')
                            // form.getCheckBox('Disability affecting mobility').check()
                        }
                        if (arr[i].oppDetails.dValue4) {
                            disabilityCode.push('7')
                            // form.getCheckBox('Profound complex disabilities').check()
                        }
                        if (arr[i].oppDetails.dValue5) {
                            disabilityCode.push('8')
                            // form.getCheckBox('Social and Emotional Difficulties').check()
                        }
                        if (arr[i].oppDetails.dValue6) {
                            disabilityCode.push('9')
                            // form.getCheckBox('Mental health difficulty').check()
                        }
                        if (arr[i].oppDetails.dValue7) {
                            disabilityCode.push('10')
                            // form.getCheckBox('Moderate difficulties').check()
                        }
                        if (arr[i].oppDetails.dValue8) {
                            disabilityCode.push('11')
                            // form.getCheckBox('Severe difficulties').check()
                        }
                        if (arr[i].oppDetails.dValue9) {
                            disabilityCode.push('12')
                            // form.getCheckBox('Dyslexia').check()
                        }
                        if (arr[i].oppDetails.dValue10) {
                            disabilityCode.push('13')
                            // form.getCheckBox('Dyscalculia').check()
                        }
                        if (arr[i].oppDetails.dValue11) {
                            disabilityCode.push('14')
                            // form.getCheckBox('Autism spectrum disorder').check()
                        }
                        if (arr[i].oppDetails.dValue12) {
                            disabilityCode.push('15')
                            // form.getCheckBox('Aspergers Syndrome').check()
                        }
                        if (arr[i].oppDetails.dValue13) {
                            disabilityCode.push('16')
                            // form.getCheckBox('Temporary disability after illness').check()
                        }
                        if (arr[i].oppDetails.dValue14) {
                            disabilityCode.push('17')
                            // form.getCheckBox('Speech Language and').check()
                        }
                        if (arr[i].oppDetails.dValue15) {
                            disabilityCode.push('93')
                            // form.getCheckBox('Other physical disability').check()
                        }
                        if (arr[i].oppDetails.dValue16) {
                            disabilityCode.push('95')
                            // form.getCheckBox('Medical condition').check()
                        }
                        if (arr[i].oppDetails.dValue17) {
                            disabilityCode.push('94')
                            // form.getCheckBox('learning-difficulty').check()
                        }
                        if (arr[i].oppDetails.dValue18) {
                            disabilityCode.push('97')
                            // form.getCheckBox('Other').check()
                        }

                    } else {
                        disability = 2
                    }

                    let qualLevel

                    if (arr[i].qualificationDetails.level === 'Entry Level') {
                        qualLevel = '01'
                    } else if (arr[i].qualificationDetails.level === 'None') {
                        qualLevel = '97'
                    } else if (arr[i].qualificationDetails.level === 'Level 1') {
                        qualLevel = '02'
                    } else if (arr[i].qualificationDetails.level === 'Level 2') {
                        qualLevel = '03'
                    } else if (arr[i].qualificationDetails.level === 'Level 3') {
                        qualLevel = '05'
                    } else if (arr[i].qualificationDetails.level === 'Level 4') {
                        qualLevel = '07'
                    } else if (arr[i].qualificationDetails.level === 'Level 5') {
                        qualLevel = '08'
                    } else if (arr[i].qualificationDetails.level === 'Level 6') {
                        qualLevel = '09'
                    } else if (arr[i].qualificationDetails.level === 'Level 7') {
                        qualLevel = '10'
                    } else if (arr[i].qualificationDetails.level === 'Level 7 and above') {
                        qualLevel = '10'
                    } else if (arr[i].qualificationDetails.level === 'Full Level 2') {
                        qualLevel = '04'
                    } else if (arr[i].qualificationDetails.level === 'Full Level 3') {
                        qualLevel = '06'
                    }
                    let employmentStatus
                    let unemploymentLength
                    let benefit
                    let employetintensit
                    if (arr[i].employmentDetails.employementStatus === "In paid employment (or self employed)" ||
                        arr[i].employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
                        arr[i].employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
                        arr[i].employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
                        employmentStatus = '10'
                        if (arr[i].employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                            employetintensit = '5'
                        } else if (arr[i].employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                            employetintensit = '6'
                        } else if (arr[i].employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                            employetintensit = '7'
                        } else if (arr[i].employmentDetails.hoursPerWeek === "30+ hours per week") {
                            employetintensit = '8'
                        }

                    } else if (arr[i].employmentDetails.employementStatus === "Unemployed, looking for work") {
                        if (arr[i].employmentDetails.lookingForWork === "Yes") {
                            employmentStatus = "11"
                        } else {
                            employmentStatus = "12"
                        }
                        if (arr[i].employmentDetails.unemployedLength === "0-5 months") {
                            unemploymentLength = "1"
                        } else if (arr[i].employmentDetails.unemployedLength === "6-11 months") {
                            unemploymentLength = "2"
                        } else if (arr[i].employmentDetails.unemployedLength === "12-23 months") {
                            unemploymentLength = "3"
                        } else if (arr[i].employmentDetails.unemployedLength === "24-35 months") {
                            unemploymentLength = "4"
                        } else if (arr[i].employmentDetails.unemployedLength === "Over 36 months") {
                            unemploymentLength = "5"
                        }


                        if (arr[i].employmentDetails.dValue1) {
                            benefit = '1'
                        }
                        if (arr[i].employmentDetails.dValue2) {
                            benefit = '6'
                        }
                        if (arr[i].employmentDetails.dValue3) {
                            benefit = '6'
                        }
                        if (arr[i].employmentDetails.dValue6) {
                            benefit = '6'
                        }
                        if (arr[i].employmentDetails.dValue5) {
                            benefit = '5'
                        }
                        if (arr[i].employmentDetails.dValue7) {
                            benefit = '6'
                        }
                        if (arr[i].employmentDetails.dValue8) {
                            benefit = '4'
                        }
                    }

                    let currentStatus
                    if (arr[i].oppDetails.nationality === 'british') {
                        currentStatus = '17'
                    } else if (arr[i].oppDetails.statementScheme === 'Yes') {
                        currentStatus = '19'
                    } else if (arr[i].oppDetails.perField === 'Asylum Seeker') {
                        currentStatus = '3'
                    } else if (arr[i].oppDetails.perField === 'Indefinite Leave') {
                        currentStatus = '25'
                    } else if (arr[i].oppDetails.perField === 'Exceptional / Discretionary Leave') {
                        currentStatus = '24'
                    } else if (arr[i].oppDetails.perField === 'Refugee') {
                        currentStatus = '23'
                    } else if (arr[i].oppDetails.perField === 'Famile Member of EU citizen') {
                        currentStatus = '21'
                    } else if (arr[i].oppDetails.perField === 'British/EU/EEA/Citizen') {
                        currentStatus = '17'
                    }

                    let selectedCourse, learningAimRef, title
                    if (arr[i].detailsFormData.appliedCourse === "Understanding Autism") {
                        selectedCourse = 796650
                        learningAimRef = 60329427
                        title = 'TQUK Certificate Understanding Autism L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Bullying in Children and Young People") {
                        selectedCourse = 797022
                        learningAimRef = 60342146
                        title = 'TQUK Certificate Awareness of Bullying in Children & Young People L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Counselling Skills") {
                        selectedCourse = 795853
                        learningAimRef = 60178152
                        title = 'TQUK Certificate Counselling Skills L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Digital Marketing") {
                        selectedCourse = 797673
                        learningAimRef = 60362959
                        title = 'TQUK Certificate Digital Marketing L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Equality and Diversity") {
                        selectedCourse = 795712
                        learningAimRef = 60153192
                        title = 'TQUK Certificate Equality and Diversity L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "End of Life Care") {
                        selectedCourse = 796424
                        learningAimRef = 60153362
                        title = 'TQUK Certificate Understanding End of Life Care L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Information, Advice or Guidance") {
                        selectedCourse = 797694
                        learningAimRef = 60332001
                        title = 'TQUK Certificate Information Advice or Guidance L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Caring for children and young people") {
                        selectedCourse = 798473
                        learningAimRef = 60330089
                        title = 'TQUK Certificate Introducing Caring for Children and Young People L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Mental Health First Aid") {
                        selectedCourse = 795804
                        learningAimRef = 60351482
                        title = 'NCFE Certificate Understanding Mental Health First Aid L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Mental Health Problems") {
                        selectedCourse = 798266
                        learningAimRef = 60329580
                        title = 'TQUK Certificate Awareness of Mental Health Problems L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Lean Organisation Management Techniques") {
                        selectedCourse = 796315
                        learningAimRef = 60336882
                        title = 'TQUK Certificate Lean Organisation Management Techniques L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Business Administration") {
                        selectedCourse = 795309
                        learningAimRef = '6032949X'
                        title = 'TQUK Certificate Principles Business Administration L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Customer Service") {
                        selectedCourse = 797209
                        learningAimRef = 60329956
                        title = 'TQUK Certificate Principles Customer Service L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Dementia Care") {
                        selectedCourse = 797955
                        learningAimRef = '6015360X'
                        title = 'TQUK Certificate Principles Dementia Care L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Preparing to Work in Adult Social Care") {
                        selectedCourse = 799517
                        learningAimRef = 60140409
                        title = 'TQUK Certificate Preparing to Work in Adult Social Care L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Special Education Needs and Disability") {
                        selectedCourse = 797424
                        learningAimRef = 60359109
                        title = 'TQUK Certificate Special Educational Needs and Disability L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Certificate in Self-harm and Suicide Awareness and Prevention") {
                        selectedCourse = 796669
                        learningAimRef = 60359110
                        title = 'TQUK Certificate Self-harm and Suicide Awareness and Prevention L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Team Leading") {
                        selectedCourse = 795441
                        learningAimRef = '6032997X'
                        title = 'TQUK Certificate Principles Team Leading L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Understanding Behaviour that Challenges") {
                        selectedCourse = 796140
                        learningAimRef = 60329440
                        title = 'TQUK Certificate Understanding Behaviour that Challenges L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Dignity and Safeguarding in Adult Health and Social Care") {
                        selectedCourse = 796547
                        learningAimRef = 60143447
                        title = 'TQUK Certificate Understanding Dignity and Safeguarding in Adult Health and Social Care L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Understanding Specific Learning Difficulties") {
                        selectedCourse = 796062
                        learningAimRef = 60330077
                        title = 'TQUK Certificate Understanding Specific Learning Difficulties L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Certificate in Nutrition and Health") {
                        selectedCourse = 797497
                        learningAimRef = 60146564
                        title = 'TQUK Certificate Understanding Nutrition & Health L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Working with Individuals with Learning Disabilities") {
                        selectedCourse = 795545
                        learningAimRef = '6100828X'
                        title = 'TQUK Certificate Principles of Working with and Supporting Individuals with Learning Disabilitiess L2 (Aged 19-23 - Refer)'
                    } else if (arr[i].detailsFormData.appliedCourse === "Children and Young People's Mental Health") {
                        selectedCourse = 796466
                        learningAimRef = 60332049
                        title = "TQUK Certificate Understanding Children & Young People's Mental Health L2 (Aged 19-23 - Refer)"
                    }

                    let fundingIndicator

                    if (
                        arr[i].employmentDetails.employementStatus === "Unemployed, looking for work" ||
                        arr[i].employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
                        arr[i].employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
                        fundingIndicator = '01 - Fully funded learning aim'
                    } else {
                        fundingIndicator = '02 - Co funded learning aim'
                    }

                    toDownload.push({
                        "Audit Reference": "",
                        "Sent To College": parsedApprovedDate.format("DD/MM/YYYY"),
                        "Academic year": "24/25",
                        "Student Title": arr[i].personalDetails.title,
                        "Student Surname": arr[i].personalDetails.lastName,
                        "First Forename": arr[i].personalDetails.firstName,
                        "Date Of Birth": dateOfBirth,
                        "Sex": gender,
                        "Student Tel1": arr[i].personalDetails.telephone,
                        "Student Mobile Tel": arr[i].personalDetails.telephone,
                        "Student Email": arr[i].personalDetails.email,
                        "Student Address1": arr[i].personalDetails.addLine1,
                        "Student Address2": arr[i].personalDetails.city,
                        "Student Address3": arr[i].personalDetails.county,
                        "Student Address4": '',
                        "Student Postcode Out": splitPostcode(arr[i].personalDetails.postcode).outward,
                        "Student Postcode In": splitPostcode(arr[i].personalDetails.postcode).inward,
                        "NI Number": arr[i].personalDetails.nationalInsNo,
                        "Criminal Conviction": criminal,
                        "Ethnic GroupID": ethnicity,
                        "Citizenship": getNationalityCode(arr[i].oppDetails.nationality),
                        "Learning Difficulty or Disability ID": disability,
                        "Primary Student Disability Category ID": disabilityCode[0],
                        "Prior Attainment LevelID": qualLevel,
                        "Employment Status ID": employmentStatus,
                        "Date From": startDate.format("DD/MM/YYYY"),
                        "Employment Intensity ID": employetintensit,
                        "Length Of Unemployment ID": unemploymentLength,
                        "Benefit Status Indicator ID": benefit,
                        "Offering ID": selectedCourse,
                        "Learning Aim Ref": learningAimRef,
                        "Learning Aim Title": title,
                        "Start Date": '',
                        "Expected End Date": '',
                        "Resident in UK/EEA 3 years": 'Yes',
                        "UK Entry Date": arr[i].oppDetails.dateYouEnteredUK,
                        "Country of Residence": getCountryCode(arr[i].oppDetails.inWhichCountryDoYouLive),
                        "Prior Country": getCountryCode(arr[i].oppDetails.countryOfBirth),
                        "Immigration Permission": currentStatus,
                        "Funding Indicator": fundingIndicator,
                        "Funding Source": '',
                        "Note": '',
                        "Special Project": '',

                    });
                } else {
                    const parsedDate = moment(arr[i].personalDetails.dob);
                    const dateOfBirth = parsedDate.format("DD/MM/YYYY")
                    toDownload.push({
                        Course: (arr[i].detailsFormData.appliedCourse).toString(),
                        Title: arr[i].personalDetails.title,
                        Firstname: arr[i].personalDetails.firstName,
                        MiddleName: arr[i].personalDetails.middleName,
                        Surname: arr[i].personalDetails.lastName,
                        Gender: arr[i].personalDetails.gender,
                        DOB: dateOfBirth,
                        AddressL1: arr[i].personalDetails.addLine1,
                        AddressL2: arr[i].personalDetails.city,
                        AddressL3: arr[i].personalDetails.county,
                        PostCode: arr[i].personalDetails.postcode,
                        TelephoneNo: arr[i].personalDetails.telephone,
                        Email: arr[i].personalDetails.email,
                        "National Insurance No.": arr[i].personalDetails.nationalInsNo,
                        "Emergency Contact Name": arr[i].emergencyDetails.emergencyContactName,
                        "emergency Telephone": arr[i].emergencyDetails.emergencyTelephone,
                        EmployementStatus: arr[i].employmentDetails.employementStatus,
                        HoursPerWeek: arr[i].employmentDetails.hoursPerWeek,
                        Length: arr[i].employmentDetails.length,
                        EmployerName: arr[i].employmentDetails.employerName,
                        AnnualSalary: arr[i].employmentDetails.annualSalary,
                        EmploymentType: arr[i].employmentDetails.employmentType,
                        EmployerAdd: arr[i].employmentDetails.employerAdd,
                        EmploymentPostcode: arr[i].employmentDetails.postcode,
                        EmploymentPh: arr[i].employmentDetails.ph,
                        StartDateEmployment: arr[i].employmentDetails.startDateEmployment,
                        UnemployedLength: arr[i].employmentDetails.unemployedLength,
                        LookingForWork: arr[i].employmentDetails.lookingForWork,
                        Benefits: arr[i].employmentDetails.benefits,
                        AreYou: arr[i].employmentDetails.areYou,
                        JSA: arr[i].employmentDetails.dValue1,
                        'Income Support': arr[i].employmentDetails.dValue2 ? "Yes" : '',
                        'Council Tax Benefit': arr[i].employmentDetails.dValue3 ? "Yes" : '',
                        'ESA (Any)': arr[i].employmentDetails.dValue5 ? "Yes" : '',
                        'Incapacity Benefit': arr[i].employmentDetails.dValue6 ? "Yes" : '',
                        'Housing Benefit': arr[i].employmentDetails.dValue7 ? "Yes" : '',
                        'Universal Credit': arr[i].employmentDetails.dValue8 ? "Yes" : '',
                        'Employed but on low wage': arr[i].employmentDetails.dValue9 ? "Yes" : '',
                        Retired: arr[i].employmentDetails.dValue10 ? "Yes" : '',
                        "Receive Benefits": arr[i].employmentDetails.dValue11 ? "Yes" : '',
                        Evidence: arr[i].employmentDetails.evidence,
                        Evidence1: arr[i].employmentDetails.evidence1,
                        Evidence2: arr[i].employmentDetails.evidence2,

                        // qualificationDetails
                        Level: arr[i].qualificationDetails.level,
                        MathGrades: arr[i].qualificationDetails.mathGrades,
                        EnglishGrades: arr[i].qualificationDetails.englishGrades,
                        GCSEEnglishabove: arr[i].qualificationDetails.GCSEEnglishabove,
                        GCSEMathsabove: arr[i].qualificationDetails.GCSEMathsabove,
                        FullTimeEducationElse: arr[i].qualificationDetails.fullTimeEducationElse,

                        // oppDetails
                        EthnicOrigin: arr[i].oppDetails.ethnicOrigin,
                        Disabilities: arr[i].oppDetails.disabilities,
                        'Visual Impairment': arr[i].oppDetails.dValue1,
                        'Hearing Impairment': arr[i].oppDetails.dValue2,
                        'Disability affecting mobility': arr[i].oppDetails.dValue3,
                        'Profound complex disabilities': arr[i].oppDetails.dValue4,
                        'Social and emotional difficulties': arr[i].oppDetails.dValue5,
                        'Mental Health Difficulties': arr[i].oppDetails.dValue6,
                        'Moderate Learning Difficulties': arr[i].oppDetails.dValue7,
                        'Severe Learning Difficulties': arr[i].oppDetails.dValue8,
                        'Dyslexia': arr[i].oppDetails.dValue9,
                        'Dyscalculia': arr[i].oppDetails.dValue10,
                        'Autism Spectrum Disorder': arr[i].oppDetails.dValue11,
                        "Asperger's Syndrome": arr[i].oppDetails.dValue12,
                        'Temporary disability after illness or accident': arr[i].oppDetails.dValue13,
                        'Speech, language and communication needs': arr[i].oppDetails.dValue14,
                        'Other physical disabilities': arr[i].oppDetails.dValue15,
                        'Medical condition e.g. epilepsy, asthma, diabetes': arr[i].oppDetails.dValue16,
                        'Other learning difficulties': arr[i].oppDetails.dValue17,
                        'Other disabilities': arr[i].oppDetails.dValue18,
                        Wheelchair: arr[i].oppDetails.wheelchair,
                        FirstLang: arr[i].oppDetails.firstLang,
                        Nationality: arr[i].oppDetails.nationality,
                        InWhichCountryDoYouLive: arr[i].oppDetails.inWhichCountryDoYouLive,
                        CountryOfBirth: arr[i].oppDetails.countryOfBirth,
                        PerField: arr[i].oppDetails.perField,
                        StatementScheme: arr[i].oppDetails.statementScheme,
                        Resident: arr[i].oppDetails.resident,
                        NonEEACitizen: arr[i].oppDetails.nonEEACitizen,
                        CriminalConv: arr[i].oppDetails.criminalConv,
                        Attachment1: arr[i].oppDetails.attachment1,
                        Attachment2: arr[i].oppDetails.attachment2,
                        Attachment3: arr[i].oppDetails.attachment3,
                        Attachment4: arr[i].oppDetails.attachment4,
                        UKStayPermission12Month: arr[i].oppDetails.ukStayPermission12Month,
                        DateYouEnteredUK: arr[i].oppDetails.dateYouEnteredUK,

                        // declaration
                        PrefContact: arr[i].declaration?.prefContact,
                        PrefContact1: arr[i].declaration?.prefContact1,
                        PrefContact2: arr[i].declaration?.prefContact2,
                        ForSurveys: arr[i].declaration?.forSurveys,
                        AboutCourse: arr[i].declaration?.aboutCourse,
                        HouseHoldMem: arr[i].declaration?.houseHoldMem,
                        HouseHoldMem2: arr[i].declaration?.houseHoldMem2,
                        SingleHouse: arr[i].declaration?.singleHouse,
                        NoneOfThese: arr[i].declaration?.noneOfThese,
                        Confirmation: arr[i].declaration?.confirmation,
                        AreYouInCare: arr[i].declaration?.areYouInCare,
                        LookAfterSomeoneIll: arr[i].declaration?.lookAfterSomeoneIll,
                        ChildRequiringChildCare: arr[i].declaration?.childRequiringChildCare,
                        MentalHealthSupport: arr[i].declaration?.mentalHealthSupport,

                        NationalId: arr[i].nationalId,
                        CertificateLink: arr[i].certificateLink,
                        TypeOfProof: arr[i].typeOfProof,
                        WhyEnrollInThisProgram: arr[i].whyEnrollInthisProgram,
                        AnythingToBeAwareOf: arr[i].anythingToBeAwareOf,
                        dateApproved: arr[i].dateApproved,
                        "Submit Date": arr[i].createdAt,
                    });
                }
            }
        }
        function parseDate(dateString) {
            return new Date(dateString);
        }
        toDownload.sort(function (a, b) {
            return parseDate(a.dateApproved) - parseDate(b.dateApproved);
        });
        var csvContent = toCsv(pivot(toDownload))
        var blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        })
        saveAs(blob, 'test.csv')
    }

    const handleUploadLink = (data) => {
        history.push('/add-attachment-link', data)
    }

    const handlePDFbuttonCollege = (id) => {
        let pdfName = (pdfCol.find(x => x._id === id)).pdf
        if (pdfName === 'VisionWest') {
            HandlePDFChange1(groupArray)
        } else if (pdfName === 'DcGroupNational') {
            HandlePDFChange4(groupArray)
        } else if (pdfName === 'newSwindonPDF') {
            handleNewSwindonClg(groupArray)
        } else if (pdfName === 'EastleighPDF') {
            handleEastleigh(groupArray)
        } else if (pdfName === 'rnnPDF') {
            handleRnnClg(groupArray)
        } else if (pdfName === 'bristolPdf') {
            handleBristolPdf(groupArray)
        } else if (pdfName === 'tecPartnerPdf') {
            tecPartnerPdf(groupArray)
        } else if (pdfName === 'lincolnCollegePDF') {
            lincolnCollegePDF(groupArray)
        } else if (pdfName === 'handleNCGPdf') {
            handleNCGPdf(groupArray)
        } else if (pdfName === 'HandlePDFNWSLC') {
            HandlePDFNWSLC(groupArray)
        } else if (pdfName === 'wightCollege') {
            HandleWightCollegePDF(groupArray)
        } else if (pdfName === 'SuttonColdField') {
            handleSuttonColdFieldPDF(groupArray)
        } else if (pdfName === 'sccuGroup') {
            handleSccuPDF(groupArray)
        } else if (pdfName === 'Hertfordshire') {
            handleHertfordshirePDF(groupArray)
        } else if (pdfName === 'PortsMouth') {
            handlePortsMouth(groupArray)
        } else if (pdfName === "wiltshire") {
            handleWiltShireCollege(groupArray)
        } else if (pdfName === "basingstoke") {
            handleBasingStokeCollege(groupArray)
        } else if (pdfName === "Nswlc") {
            handleNSWLCollege(groupArray)
        } else if (pdfName === "LondonSE") {
            handleLondonSECollege(groupArray)
        } else if (pdfName === "lmpCollege") {
            handleLMPCollege(groupArray)
        } else if (pdfName === "fttCollege") {
            handleFTTCollege(groupArray)
        } else if (pdfName === "heartOfYorkShire") {
            handleHeartOfYorkshireCollege(groupArray)
        }

        else {
            //Generic
            HandlePDFChange3(groupArray)
        }
    }
    return (
        <div>
            <div>

                {/* If College is Any other College College */}
                {(props.collegeID) &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>

                        <Button
                            className='button button-group-download'
                            style={{ maxHeight: 50 }}
                            onClick={() => {
                                handlePDFbuttonCollege(props.collegeID)
                            }}
                        >
                            Download the selected Applications
                        </Button>
                        {localStorage.getItem('collegeID') === '62e2209a99ec714ad88d4fe4' &&
                            <Button
                                className='button button-group-download'
                                style={{ maxHeight: 50 }}
                                onClick={() => {
                                    handleRnnClg23OLD(groupArray)
                                }}
                            >
                                Download 23-24 PDF
                            </Button>
                        }
                        <div
                            className='csvConvBox'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            Start Date:{' '}
                            <input
                                placeholder='start date'
                                type='date'
                                onChange={(e) => {
                                    setStartDate(new Date(e.target.value))
                                }}
                            />
                            End Date:{' '}
                            <input
                                placeholder='end date'
                                type='date'
                                onChange={(e) => {
                                    setEndDate(new Date(e.target.value))
                                }}
                            />
                            {props.collegeID !== '66349265fdc7023702af57c2' ?
                                <Button
                                    style={{ width: '100%' }}
                                    className='button button-group-download'
                                    onClick={downloadCSV}
                                >
                                    Download CSV
                                </Button> :
                                <>
                                    <Button
                                        style={{ width: '100%' }}
                                        className='button button-group-download'
                                        onClick={downloadLMPCSV}
                                    >
                                        Download LMP CSV
                                    </Button>
                                    <p style={{ fontSize: "12px", textAlign: "center" }}>Approved Dates Considered</p>
                                </>}

                        </div>
                    </div>
                }



                {/* If Admin Show Buttons for all Colleges */}
                {!props.collegeID && (

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* Upper Green Buttons */}
                        <div>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleNewSwindonClg(groupArray)
                                }}
                            >
                                NewSwindon Clg
                            </Button>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleEastleigh(groupArray)
                                }}
                            >
                                Eastleigh
                            </Button>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    HandlePDFChange3(groupArray)
                                }}
                            >
                                Download Selected Applications
                            </Button>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleRnnClg(groupArray)
                                }}
                            >
                                RNN COllege
                            </Button> <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleBristolPdf(groupArray)
                                }}
                            >
                                Bristol
                            </Button> <Button
                                className='button button-group-download'
                                onClick={() => {
                                    tecPartnerPdf(groupArray)
                                }}
                            >
                                TecPartner
                            </Button> <Button
                                className='button button-group-download'
                                onClick={() => {
                                    lincolnCollegePDF(groupArray)
                                }}
                            >
                                Lincoln
                            </Button> <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleNCGPdf(groupArray)
                                }}
                            >
                                NCG College
                            </Button> <Button
                                className='button button-group-download'
                                onClick={() => {
                                    HandlePDFChange4(groupArray)
                                }}
                            >
                                DC Group National
                            </Button> <Button
                                className='button button-group-download'
                                onClick={() => {
                                    HandlePDFNWSLC(groupArray)
                                }}
                            >
                                NWSLC
                            </Button> <Button
                                className='button button-group-download'
                                onClick={() => {
                                    HandleWightCollegePDF(groupArray)
                                }}
                            >
                                Wight Clg
                            </Button> <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleSuttonColdFieldPDF(groupArray)
                                }}
                            >
                                Sutton Coldfield
                            </Button><Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleSccuPDF(groupArray)
                                }}
                            >
                                SCCU Group
                            </Button>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleHertfordshirePDF(groupArray)
                                }}
                            >
                                Hertfordshire
                            </Button>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handlePortsMouth(groupArray)
                                }}
                            >
                                PortsMouth
                            </Button>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleWiltShireCollege(groupArray)
                                }}
                            >
                                Wiltshire
                            </Button>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleBasingStokeCollege(groupArray)
                                }}
                            >
                                BasingStoke
                            </Button>

                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleNSWLCollege(groupArray)
                                }}
                            >
                                NWSLC
                            </Button>

                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleLondonSECollege(groupArray)
                                }}
                            >
                                London south east
                            </Button>
                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleLMPCollege(groupArray)
                                }}
                            >
                                LMP College
                            </Button>

                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleFTTCollege(groupArray)
                                }}
                            >
                                FTT College
                            </Button>

                            <Button
                                className='button button-group-download'
                                onClick={() => {
                                    handleHeartOfYorkshireCollege(groupArray)
                                }}
                            >
                                Heart of YorkShire
                            </Button>


                        </div>

                        {(!localStorage.getItem('supportID')) && <div
                            className='csvConvBox'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            Start Date:{' '}
                            <input
                                placeholder='start date'
                                type='date'
                                onChange={(e) => {
                                    setStartDate(new Date(e.target.value))
                                }}
                            />
                            End Date:{' '}
                            <input
                                placeholder='end date'
                                type='date'
                                onChange={(e) => {
                                    setEndDate(new Date(e.target.value))
                                }}
                            />
                            <Button
                                style={{ width: '100%' }}
                                className='button button-group-download'
                                onClick={downloadCSV}
                            >
                                Download
                            </Button>
                        </div>}
                    </div>
                )}
            </div>

            {/* If College then Remove Last 3 Buttons */}
            {
                props.collegeID && (
                    <>

                        <MaterialTable
                            title="Students list"
                            columns={[
                                { title: 'Name', field: 'Name', editable: 'never' },
                                ((localStorage.getItem('collegeID') === "62e2187399ec714ad88d4fe3") //New Swindon College
                                ) ? { title: 'Declaration', field: 'newDeclaration', editable: 'never' } : {},
                                { title: 'Active', field: 'isActiveByCollege', lookup: { true: "Yes", false: "No" }, emptyValue: "No", },
                                { title: 'Downloaded', field: 'isDownloaded', lookup: { true: "Yes", false: "No" } },
                                { title: 'Enrolled', field: 'isEnrolled', lookup: { true: "Yes", false: "No" } },
                                { title: 'Email', field: 'email', editable: 'never' },
                                { title: 'Applied Course', field: 'appliedCourse', lookup: allCourses },
                                { title: 'Mobile', field: 'phone', editable: 'never' },
                                {
                                    title: 'Submitted',
                                    field: 'createdAt',
                                    editable: 'never',
                                    render: (rowData) =>
                                        moment(rowData.createdAt)
                                            .utcOffset('+00:00') // Adjust to UK time zone (+01:00)
                                            .format('ddd, DD MMM YYYY h:mm A'),
                                    customFilterAndSearch: (term, rowData) => {
                                        const formattedDate = moment(rowData.createdAt)
                                            .utcOffset('+00:00')
                                            .format('ddd, DD MMM YYYY h:mm A');
                                        return formattedDate.toLowerCase().includes(term.toLowerCase());
                                    },
                                },
                                { title: 'Approved Date', field: 'dateApproved', editable: 'never' },
                                // { title: 'Time', field: 'time', editable: 'never' },
                                { title: 'City', field: 'city', editable: 'never' },
                                {
                                    title: 'Query Application',
                                    field: 'returnApplication',
                                    editable: 'never',
                                    hidden: ((localStorage.getItem('collegeID') !== '645e0b8a62a08b00146cf357') && //NCG Group
                                        (localStorage.getItem('collegeID') !== "62e2209a99ec714ad88d4fe4") && //rnn 
                                        (localStorage.getItem('collegeID') !== "6405f6fb765b1300144ca336") && //Bristol 
                                        (localStorage.getItem('collegeID') !== "645a1475da6dcb0014c849f6") && //Lincoln 
                                        (localStorage.getItem('collegeID') !== "651297e145c072fd90f7dd96") && //Hart 
                                        (localStorage.getItem('collegeID') !== "632f8d59577a0e1ce71e5e2b") && //Basingstoke 
                                        (localStorage.getItem('collegeID') !== "6523c679113a02453353ce7a")//COP
                                    ),
                                    render: rowData => (
                                        <Button className='back-Button-form' onClick={() => handleShow(rowData._id)}>
                                            Query
                                        </Button>
                                    ),
                                },
                            ]}
                            data={a.sort((a, b) => new Date(b.dateApproved) - new Date(a.dateApproved))}
                            options={{
                                pageSize: 50,
                                filtering: true,
                                actionsColumnIndex: -1,
                                rowStyle: rowData => ({
                                    backgroundColor: (rowData.isActiveByCollege) ? '#76d59291' : '#FFF'
                                })
                            }}
                            cellEditable={{
                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                    return new Promise((resolve, reject) => {
                                        if (columnDef.field === 'isDownloaded') {
                                            rowData.isDownloaded = newValue;
                                            handleDownloadedCheck(newValue, rowData._id)
                                        }
                                        else if (columnDef.field === 'isEnrolled') {
                                            rowData.isEnrolled = newValue;
                                            handleEnrolledCheck(newValue, rowData._id)
                                        }
                                        else if (columnDef.field === 'isActiveByCollege') {
                                            rowData.isActiveByCollege = newValue;
                                            handleActiveByCollege(newValue, rowData._id)
                                        }
                                        setTimeout(resolve, 1000);
                                    });
                                }
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'add',
                                    tooltip: 'Select',
                                    onClick: (event, rowData) => { handleGroup(rowData._id) },
                                }),
                                rowData => ({
                                    icon: 'preview',
                                    tooltip: 'Show Data',
                                    onClick: async (event, rowData) => {
                                        window.location.href = '/show-data?id=' + rowData._id
                                    }
                                })
                            ]}
                        />
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Have Any Query regarding this application?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Explain your query regarding this and our team would get it resolved.</p>
                                <Form.Group>
                                    <Form.Control
                                        as='textarea'
                                        rows={3}
                                        placeholder='Please write a detailed note.'
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill in this required field.
                                    </Form.Control.Feedback>
                                </Form.Group>


                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleNotes}>
                                    Send
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>

                )
            }

            {/* If is Admin Show All Buttons */}
            {
                !props.collegeID && (

                    <>
                        <br />
                        <MaterialTable
                            title="Students list"
                            columns={[
                                { title: 'Name', field: 'Name', editable: 'never' },
                                { title: 'Declaration', field: 'newDeclaration', editable: 'never' },
                                { title: 'English', field: 'English', editable: 'never' },
                                { title: 'Enrolled', field: 'enrolled', editable: 'never' },
                                { title: 'College', field: 'contractId', lookup: allColleges, editable: 'never' },
                                { title: 'Email', field: 'email', editable: 'never' },
                                { title: 'Applied Course', field: 'appliedCourse', lookup: allCourses, editable: 'never' },
                                { title: 'Mobile', field: 'phone', editable: 'never' },
                                {
                                    title: 'Submitted',
                                    field: 'createdAt',
                                    editable: 'never',
                                    render: (rowData) =>
                                        moment(rowData.createdAt)
                                            .utcOffset('+00:00') // Adjust to UK time zone (+01:00)
                                            .format('ddd, DD MMM YYYY h:mm A'),
                                    customFilterAndSearch: (term, rowData) => {
                                        const formattedDate = moment(rowData.createdAt)
                                            .utcOffset('+00:00')
                                            .format('ddd, DD MMM YYYY h:mm A');
                                        return formattedDate.toLowerCase().includes(term.toLowerCase());
                                    },
                                },
                                // { title: 'Time', field: 'time', editable: 'never' },
                                { title: 'City', field: 'city', editable: 'never' },
                                { title: 'Status', field: 'status', editable: 'never', lookup: { "pending": 'pending', "approved": 'approved', "Awaiting ID": 'Awaiting ID', "Awaiting Benefits": 'Awaiting Benefits', "Awaiting Income": 'Awaiting Income', "Not Contactable": 'Not Contactable', "Cancel/Delete": "Cancel/Delete", "Awaiting English Assessment": "Awaiting English Assessment", "Awaiting Visa Proof": "Awaiting Visa Proof", "Awaiting Qualification Level": "Awaiting Qualification Level", 'Awaiting Emergency Contact': "Awaiting Emergency Contact" } },
                                { title: 'Assigned', field: 'supportStaff', lookup: accounts, editable: 'never' },
                                { title: 'Completed by', field: 'completedBy', emptyValue: "none", lookup: callcenter, editable: 'never' },
                            ]}
                            data={a}
                            options={{
                                pageSize: 50,
                                filtering: true,
                                actionsColumnIndex: -1,
                                rowStyle: rowData => ({
                                    backgroundColor: (rowData.status === "approved") ? '#76d59291' : '#FFF'
                                })
                            }}
                            cellEditable={{
                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                    return new Promise(async (resolve, reject) => {
                                        // if (columnDef.field === "status") {
                                        //     let res = await handleStatus(newValue, rowData._id)
                                        //     if (res) {
                                        //         rowData.status = newValue;
                                        //     }
                                        // }
                                        // else if (columnDef.field === 'supportStaff') {
                                        //     rowData.supportStaff = newValue;
                                        //     handleSupportMember(newValue, rowData._id)
                                        // }
                                        // else if (columnDef.field === 'contractId') {
                                        //     let res = await handleCollege(newValue, rowData)
                                        //     if (res) {
                                        //         rowData.contractId = newValue;
                                        //     }
                                        // }
                                        // else if (columnDef.field === 'appliedCourse') {
                                        //     rowData.appliedCourse = newValue;
                                        //     handleCourse(newValue, rowData._id)
                                        // }
                                        // else if (columnDef.field === 'completedBy') {
                                        //     rowData.completedBy = newValue;
                                        //     handleCompletedBy(newValue, rowData._id)
                                        // }
                                        setTimeout(resolve, 1000);
                                    });
                                }
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'difference',
                                    isFreeAction: false,
                                    tooltip: 'Copy Link',
                                    onClick: (event, rowData) => { navigator.clipboard.writeText("myfreecourse.co.uk/nsc-declaration/review?form_id=" + rowData._id) },
                                    disabled: rowData.collegeID !== "62e2187399ec714ad88d4fe3"
                                }),
                                rowData => ({
                                    icon: 'add',
                                    tooltip: 'Select',
                                    onClick: (event, rowData) => { handleGroup(rowData._id) },
                                }),
                                // rowData => ({
                                //     icon: 'upload',
                                //     tooltip: 'Generate an Attachment Link',
                                //     onClick: (event, rowData) => { handleUploadLink(rowData) },
                                // }),
                                rowData => ({
                                    icon: 'preview',
                                    tooltip: 'Show Data',
                                    onClick: async (event, rowData) => {
                                        history.push('/show-data?id=' + rowData._id, { 'data': rowData, 'Contracts': allCol, 'courses': courses })
                                        // window.location.href = '/show-data?id=' + rowData._id
                                    }
                                }),

                            ]}
                        />
                        <br />

                    </>
                )
            }
        </div >
    )
}


export default Formdata
