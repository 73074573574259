import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import ScrollToMount from '../../common/ScrollToMount'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import FormContainer from './FormContainer'
import { saveDeclarationDetails } from '../../actions/formActions'
import FormCompletetionSteps from './FormCompletetionSteps'
import { toast } from 'react-toastify'
import axios from 'axios'

const DeclarationForm = ({ history }) => {
    const [prefContact, setPrefContact] = useState('')
    const [prefContact1, setPrefContact1] = useState('')
    const [prefContact2, setPrefContact2] = useState('')
    const [forSurveys, setForSurveys] = useState('')
    const [aboutCourse, setAboutCourse] = useState('')
    const [houseHoldMem, setHousehold1] = useState('')
    const [houseHoldMem2, setHousehold2] = useState('')
    const [singleHouse, setSingleHouse] = useState('')
    const [noneOfThese, setNoneOf] = useState('')
    const [confirmation, setConfirmation] = useState('')
    const [decl, setDeclare] = useState()
    const [areYouInCare, setAreYouInCare] = useState(false)
    const [lookAfterSomeoneIll, setLookAfterSomeoneIll] = useState(false)
    const [childRequiringChildCare, setChildRequiringChildCare] = useState(false)
    const [mentalHealthSupport, setMentalHealthSupport] = useState(false)
    const [validated, setValidated] = useState(false)
    const [introductorySession, setIntroductorySession] = useState('')
    const [changeCollege, setChangeCollege] = useState(false)
    const [newDataAfterChange, setNewDataAfterChange] = useState()


    const [error, setError] = useState(false)

    const dispatch = useDispatch()

    const submitHandler = async (e) => {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            var element = document.getElementById('Error-msg-form')
            element.classList.remove('Error-msg-form-displayNone')
            element.classList.add('Error-msg-form-display')
            document.getElementById('Error-msg-form').scrollIntoView({
                behavior: 'smooth',
            })
        } else if (houseHoldMem === '' && houseHoldMem2 === '' && singleHouse === '' && noneOfThese === '' && confirmation === '') {
            e.preventDefault()
            e.stopPropagation()
            toast.error("Please select atleast one")
            setError(true)

        } else {
            e.preventDefault()
            dispatch(
                saveDeclarationDetails({
                    prefContact,
                    prefContact1,
                    prefContact2,
                    forSurveys,
                    aboutCourse,
                    houseHoldMem,
                    houseHoldMem2,
                    singleHouse,
                    noneOfThese,
                    confirmation,
                    areYouInCare,
                    lookAfterSomeoneIll,
                    childRequiringChildCare,
                    mentalHealthSupport,
                    introductorySession: changeCollege ? '' : introductorySession,
                })
            )

            if (changeCollege) {
                const res = await axios.post(process.env.REACT_APP_API_URL + '/form/college-finder-with-exception/', {
                    appliedCourse: localStorage.getItem('affiliatedCourse'),
                    PostCodeBL: localStorage.getItem('PostCodeBL'),
                    exception: localStorage.getItem('selectedContract'),
                })
                console.log("res: ", res)
                if (res.data.success) {
                    console.log(res.data)
                    setNewDataAfterChange(res.data)
                    handleChangeOfCourse()
                } else {
                    localStorage.setItem('selectedContract', '66ea11b2c65fb384d89309d5')
                    localStorage.setItem('selectedCollege', '6246fe5af9f54d5ab1a78a7b')

                    const response = await axios.post('/form/incomplete-update-college', {
                        id: localStorage.getItem('incFormId'),
                        collegeID: "6246fe5af9f54d5ab1a78a7b",
                        contractId: '66ea11b2c65fb384d89309d5',
                        appliedCourse: localStorage.getItem('affiliatedCourse')
                    });
                }
            }
            history.push('/proof')
        }
        setValidated(true)
    }




    const handleChangeOfCourse = async () => {
        const response = await axios.post('/form/incomplete-update-college', {
            id: localStorage.getItem('incFormId'),
            collegeID: newDataAfterChange.college._id,
            contractId: newDataAfterChange.contract._id,
            appliedCourse: localStorage.getItem('affiliatedCourse')
        });
        if (response.status === 200) {
            const chosenContract = newDataAfterChange.contract
            const chosenCollege = newDataAfterChange.college
            localStorage.setItem('countryOfBirth', chosenCollege.countryOfBirth)
            localStorage.setItem('startDateEmployment', chosenCollege.startDateEmployment)
            localStorage.setItem('employmentdetailsNecessary', chosenCollege.employmentdetailsNecessary)
            localStorage.setItem('softEnglishTest', chosenCollege.softEnglishTest)
            localStorage.setItem('inWhichCountryDoYouLive', chosenCollege.inWhichCountryDoYouLive)
            localStorage.setItem('fullTimeEducationElse', chosenCollege.fullTimeEducationElse)
            localStorage.setItem('proofs', chosenCollege.proofs)
            localStorage.setItem('ifEmploymentType', chosenCollege.ifEmploymentType || false)
            localStorage.setItem('ifAnualSalary', chosenCollege.ifAnualSalary || false)
            localStorage.setItem('level', 'Level 3')
            localStorage.setItem('appliedCourse', localStorage.getItem('affiliatedCourse'))
            localStorage.setItem('selectedContract', newDataAfterChange.contract._id)
            localStorage.setItem('selectedCollege', newDataAfterChange.college._id)
        } else {
            toast.error("Some error occurred. Please continue")
        }
    }

    return (
        <FormContainer>
            <ScrollToMount />
            <FormCompletetionSteps step1 step2 step3 step4 step5 step6 step7 />
            <div>
                <h1>Step 7: Declaration</h1>
                <div
                    className='text-center mb-5 Error-msg-form Error-msg-form-displayNone'
                    id='Error-msg-form'
                >
                    <p>Please fix one or more fields.</p>
                </div>
                <Form noValidate validated={validated} onSubmit={submitHandler}>
                    <Form.Group controlId=''>
                        <b>Please tick which of the following apply (one or more may apply)</b>
                        <br />{error && <span style={{ color: 'red' }}>Please check at least one option.</span>}
                        <Form.Check
                            label='No household member is in employment, and the household includes one or more dependent children.'
                            type='checkbox'
                            value='houseHoldMem'
                            name='houseHoldMem'
                            e
                            onChange={(e) => setHousehold1(e.target.value)}
                            className='mr-2'
                        ></Form.Check>
                        <Form.Check
                            label='No household member is in employment, and the household does not include any dependent children.'
                            type='checkbox'
                            value='houseHoldMem2'
                            name='houseHoldMem2'
                            onChange={(e) => setHousehold2(e.target.value)}
                            className='mr-2'
                        ></Form.Check>
                        <Form.Check
                            label='Learner lives in a single adult household with dependent children.'
                            type='checkbox'
                            value='singleHouse'
                            name='singleHouse'
                            onChange={(e) => setSingleHouse(e.target.value)}
                            className='mr-2'
                        ></Form.Check>
                        <Form.Check
                            label='None of these statements apply.'
                            type='checkbox'
                            value='noneOfThese'
                            name='noneOfThese'
                            onChange={(e) => setNoneOf(e.target.value)}
                            className='mr-2'
                        ></Form.Check>
                        <Form.Check
                            label='I confirm that i wish to withhold this information.'
                            type='checkbox'
                            value='confirmation'
                            name='confirmation'
                            onChange={(e) => setConfirmation(e.target.value)}
                            className='mr-2'
                        ></Form.Check>

                        <br />
                        <br />
                        <Form.Label>
                            You can agree to be contacted by other third parties by ticking any of
                            the following boxes:{' '}
                        </Form.Label>
                        <Form.Check
                            type='checkbox'
                            label='About courses or learning opportunities'
                            name='aboutCourse'
                            value='aboutCourse'
                            onChange={(e) => setAboutCourse(e.target.value)}
                            className='mr-2'
                        ></Form.Check>

                        <Form.Check
                            type='checkbox'
                            label='For surveys and research'
                            name='forSurveys'
                            value='forSurveys'
                            onChange={(e) => setForSurveys(e.target.value)}
                            className='mr-2'
                        ></Form.Check>

                        <Form.Check
                            type='checkbox'
                            label='By Email'
                            name='email'
                            value='email'
                            onChange={(e) => setPrefContact(e.target.value)}
                            className='mr-2'
                        ></Form.Check>

                        <Form.Check
                            type='checkbox'
                            label='By Post'
                            name='post'
                            value='post'
                            onChange={(e) => setPrefContact1(e.target.value)}
                            className='mr-2'
                        ></Form.Check>

                        <Form.Check
                            type='checkbox'
                            label='By Phone'
                            name='phone'
                            value='phone'
                            onChange={(e) => setPrefContact2(e.target.value)}
                            className='mr-2'
                        ></Form.Check>
                    </Form.Group>
                    {/* /NCG group */}
                    {(localStorage.getItem('selectedCollege') === '645e0b8a62a08b00146cf357') && <>
                        <Form.Group controlId=''>
                            <Form.Check
                                label='Are you or have you been in care?'
                                type='checkbox'
                                value={areYouInCare}
                                name='areYouInCare'
                                onChange={(e) => setAreYouInCare(!areYouInCare)}
                                className='mr-2'
                            ></Form.Check>
                            <Form.Check
                                label='Do you help to look after someone who has an illness, disability, mental health illness or addiction?'
                                type='checkbox'
                                value={lookAfterSomeoneIll}
                                name='lookAfterSomeoneIll'
                                onChange={(e) => setLookAfterSomeoneIll(!lookAfterSomeoneIll)}
                                className='mr-2'
                            ></Form.Check>
                            <Form.Check
                                label='Do you have a child or children who require childcare whilst you study?'
                                type='checkbox'
                                value={childRequiringChildCare}
                                name='childRequiringChildCare'
                                onChange={(e) => setChildRequiringChildCare(!childRequiringChildCare)}
                                className='mr-2'
                            ></Form.Check>
                            <Form.Check
                                label='Do you require mental health support?'
                                type='checkbox'
                                value={mentalHealthSupport}
                                name='mentalHealthSupport'
                                onChange={(e) => setMentalHealthSupport(!mentalHealthSupport)}
                                className='mr-2'
                            ></Form.Check>
                        </Form.Group>
                    </>}
                    <Form.Group controlId='CHeckbox1'>
                        {(localStorage.getItem("selectedContract") === "65e9a129d6752edccfe5410e") ? <>

                            <Form.Label>
                                <b>Declaration for Fee Waiver </b><span style={{ fontSize: "12px" }}>(If you are employed/self-employed)</span> <b>:</b><br />
                                <ul>
                                    <li>Aged between 19 and 23 at the time they start the course and do not already hold a full level 3 qualification.</li>
                                    <li>Aged 24+ at the time they start the course and do not already hold a full level 3 qualification and the qualification being studied appears in the Level 3 Adult Offer</li>
                                    <li>Aged 19+ at the time they start the course and earn less than the National Living Wage annually (£25,000 gross) or who are unemployed. This is regardless of their prior qualification level and the qualification being studied appears in the Level 3 Adult Offer</li>
                                    <li>I declare that I do not already have a full Level 3 qualification or above and that all the information on this form is correct. I understand that if I declare false information the provider may take action against me to reclaim the tuition fees and any support costs provided. I have read the Privacy Notice overleaf.</li>
                                </ul>
                                <br />
                                <b>Declaration for the Benefit Waiver </b> <span style={{ fontSize: "12px" }}>(If you are on benefits)</span> <b>:</b><br />
                                <ul>
                                    <li>I am unemployed* AND I am in receipt of the benefit/s indicated below AND have provided my National Insurance number. I understand that I am entitled to remission of fees for funded provision, where the course being studied will support me either to enter employment or increase my income, which may result in ceasing to claim the identified benefit.</li>
                                    <li>In signing this form, I agree to the statement above and confirm that the information I have provided is accurate. I have read the Privacy Notice overleaf.</li>
                                </ul>
                                <br />
                                <b>Declaration for Charge Letter:</b><br />
                                <ul>
                                    <li>I agree to pay £200 should I not complete the course (in the required timeframe) or be able to provide evidence as to why I did not complete my studies.</li>
                                </ul>
                            </Form.Label>
                        </> : <>

                            <Form.Label>
                                I confirm that, to the best of my knowledge, the information given in this form is correct and
                                complete.<br /><br />
                                I understand that the course will be funded and delivered through one of the My Free Course
                                partners. In the event that you do not qualify for the course to be fully funded, the My Free Course
                                partners will waive the course fees, allowing you to still do the course for FREE.<br /><br />
                                As the My Free Course partners cover all course fees whether fully or partially funded, if a learner
                                enrols and fails to complete or withdraws early from the course the college or course provider may
                                charge upto but not limited to {(localStorage.getItem("selectedCollege") === "6523c679113a02453353ce7a") ? '£200' : '£125'} fee to cover administration costs.<br /><br />
                                The course is split into units that are due weekly (over 8-12 weeks). The course provider will also supply a tutor to help support you on the course and there is the option to have an extension if you are unable to submit an assignment on time.
                                <br /><br />
                            </Form.Label>
                        </>}




                        {/* COP College charges 200 pounds in line 231 */}
                        <Form.Check
                            type='checkbox'
                            label='Yes I understand'
                            name='decl'
                            value={decl}
                            required
                            feedback='You must agree before submitting.'
                            onChange={(e) => setDeclare(e.target.value)}
                            className='mr-2'
                        ></Form.Check>
                    </Form.Group>


                    {/* new Swindon COllege */}
                    {(localStorage.getItem("selectedCollege") === "62e2187399ec714ad88d4fe3") &&
                        <Form.Group controlId='CHeckbox3'>
                            <Form.Check
                                type='checkbox'
                                label={
                                    <span>
                                        I agree to the
                                        <Link to="/NewSwindon-Policy"> New Swindon College's Privacy Policy</Link>
                                    </span>
                                }
                                required
                                feedback='You must agree before submitting.'
                                className='mr-2'
                            ></Form.Check>
                        </Form.Group>
                    }
                    {/* Isle of Wight */}
                    {(localStorage.getItem("selectedCollege") === "64d61f2f76e9ec8ceb407c30") &&
                        <Form.Group controlId='CHeckbox3'>
                            <Form.Check
                                type='checkbox'
                                label={
                                    <span>
                                        I understand that if I fail to complete the course, I may be liable for a £100 withdrawal fee. This covers the course administration and registration fees. I understand that if withdraw at a point where some of my work has been marked, I may also be liable for the costs of tuition which can be up to £100 in addition to the withdrawal fee.
                                    </span>
                                }
                                required
                                feedback='You must agree before submitting.'
                                className='mr-2'
                            ></Form.Check>
                        </Form.Group>
                    }


                    {/* Heart of YorkShire Group */}
                    {(localStorage.getItem("selectedCollege") === "66fba0d9e573f5f419ef1dc2") &&
                        <Form.Group controlId='CHeckbox3'>
                            <Form.Check
                                type='checkbox'
                                label={
                                    <span>
                                        I agree to the
                                        <Link to="/yorkshire-Policy"> Heart of Yorkshire's Privacy Policy</Link>
                                    </span>
                                }
                                required
                                feedback='You must agree before submitting.'
                                className='mr-2'
                            ></Form.Check>
                        </Form.Group>
                    }
                    {/* NCG Group */}
                    {(localStorage.getItem("selectedCollege") === "645e0b8a62a08b00146cf357") &&
                        <>
                            <Form.Group controlId='decl-video-ncg'>
                                <Form.Label>
                                    <b>To meet their funding requirements the college will need to do a short introductory session via a video call. This is to explain the basics of your course and the college’s role in supporting you throughout your studies. At the end of the session you will be given your course login details so you can start the course.
                                    </b>
                                    <br />
                                    <br />
                                    Please choose a convenient time below for the introductory session.
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    value={introductorySession || ''}
                                    onChange={(e) => setIntroductorySession(e.target.value)}
                                >
                                    <option value=''>
                                        [Please Select One]
                                    </option>
                                    <option value='Monday 10am'>Monday 10am</option>
                                    <option value='Monday 3:30pm'>Monday 3:30pm</option>
                                    <option value='Tuesday 10am'>Tuesday 10am</option>
                                    <option value='Tuesday 3:30pm'>Tuesday 3:30pm</option>
                                    <option value='Wednesday 5:45m'>Wednesday 5:45m</option>
                                    <option value='Thursday 10am'>Thursday 10am</option>
                                    <option value='Thursday 3:30pm'>Thursday 3:30pm</option>
                                </Form.Control>
                                <br />
                                <br />
                                <p>IF none of the times above suit your schedule, please tick the below and we will find another college that will offer your funded course but not require the intro call. This could result in a delay in getting you access to your chosen course.
                                </p>
                                <br />
                                <br />
                                {introductorySession === '' && <Form.Check
                                    type='checkbox'
                                    label='No time suits me, find me another College'
                                    name='decl'
                                    checked={changeCollege}
                                    onChange={(e) => setChangeCollege(!changeCollege)}
                                    className='mr-2'
                                ></Form.Check>}
                            </Form.Group>

                            <Form.Group controlId='CHeckbox3'>
                                <Form.Check
                                    type='checkbox'
                                    label={
                                        <span>
                                            I agree to the
                                            <Link to="/NCG-Policy"> NCG's Privacy Policy</Link>
                                        </span>
                                    }
                                    required
                                    feedback='You must agree before submitting.'
                                    className='mr-2'
                                ></Form.Check>
                            </Form.Group>
                        </>
                    }
                    <Button
                        onClick={(e) => {
                            window.location.href = '/oppurtunities'
                        }}
                        variant='primary'
                        className='mr-5 back-Button-form'
                    >
                        Back
                    </Button>

                    <Button
                        type='submit'
                        variant='primary'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                        }}
                        className='mrt next-Button-form'
                    >
                        Save Progress & Continue
                    </Button>
                </Form>
            </div>
        </FormContainer>
    )
}

export default DeclarationForm
