
import { PDFDocument } from 'pdf-lib'
import authService from '../services/authService'
import moment from 'moment'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.rawFile)

        reader.onload = () =>
            resolve({
                fileName: file.title,
                base64: reader.result,
            })
        reader.onerror = reject
    })

function ensureHttps(url) {
    if (url?.startsWith('http://')) {
        return url?.replace('http://', 'https://');
    }
    return url;
}

// new decalaration new Swindon COllege
const newDeclarationPDF = async (data) => {
    const formPdfBytes = await fetch('/declaration.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    form.getTextField('applicantName').setFontSize(9)
    form.getTextField('appliedCourse').setFontSize(9)
    form.getTextField('applicantDate').setFontSize(9)
    form.getTextField('Text-date').setFontSize(9)
    form.getTextField('applicantName').setText(data.personalDetails.firstName + ' ' + data.personalDetails.lastName)
    form.getTextField('appliedCourse').setText(data.detailsFormData.appliedCourse)
    form.getTextField('applicantDate').setText(data.date)
    let date = new Date(data.updatedAt)
    form.getTextField('Text-date').setText((date).toLocaleDateString())

    const emblemUrl = data.signature //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

    const signImageField = form.getTextField('Text-signature')
    signImageField.setImage(emblemImage)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })

    return file;
}

// Englisht Assessment 1 Function
const englishassessment1 = async (data) => {
    const formPdfBytes = await fetch('/englishForm1.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    form.getTextField('applicantName').setText(data.personalDetails.firstName + ' ' + data.personalDetails.lastName)
    form.getTextField('appliedCourse').setText(data.detailsFormData.appliedCourse)
    form.getTextField('applicantDate').setText(data.date)
    form.getTextField('applicantAnswer').setText(data.assessment.english)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })

    return file;
}
// Englisht Assessment 2 Function
const englishassessment2 = async (data) => {
    const formPdfBytes = await fetch('/englishForm2.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    form.getTextField('applicantName').setText(data.personalDetails.firstName + ' ' + data.personalDetails.lastName)
    form.getTextField('appliedCourse').setText(data.detailsFormData.appliedCourse)
    form.getTextField('applicantDate').setText(data.date)
    const emblemUrl = data.signature //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
    const signImageField = form.getTextField('applicantSign')
    signImageField.setImage(emblemImage)
    form.getTextField('answer1').setText(data.assessment.answer1)
    form.getTextField('answer2').setText(data.assessment.answer2)
    form.getTextField('answer3').setText(data.assessment.answer3)
    form.getTextField('answer4').setText(data.assessment.answer4)
    form.getTextField('answer5').setText(data.assessment.answer5)
    form.getTextField('answer6').setText(data.assessment.answer6)
    form.getTextField('answer7').setText(data.assessment.answer7)
    form.getTextField('answer8').setText(data.assessment.answer8)
    form.getTextField('answer9').setText(data.assessment.answer9)
    form.getTextField('answer10').setText(data.assessment.answer10)
    form.getTextField('answer11a').setText(data.assessment.answer11a)
    form.getTextField('answer11b').setText(data.assessment.answer11b)
    form.getTextField('answer11c').setText(data.assessment.answer11c)
    form.getTextField('answer12').setText(data.assessment.answer12)
    form.getTextField('answer13').setText(data.assessment.answer13)
    form.getTextField('answer14').setText(data.assessment.answer14)
    form.getTextField('answer15').setText(data.assessment.answer15)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })

    return file;
}


// NCG COllege Immigration form
const ncgCollegeImmirationFormPDF = async (data) => {
    const formPdfBytes = await fetch('/ImmigrationNCg.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()
    form.getTextField('firstName').setText(data.personalDetails.firstName)
    form.getTextField('lastName').setText(data.personalDetails.lastName)
    if (data.oppDetails.statementScheme === 'Yes') {
        form.getCheckBox('statementScheme').check()
    }
    if (data.oppDetails.perField === 'Asylum Seeker') {
        form.getCheckBox('Asylum Seeker').check()
    } else if (data.oppDetails.perField === 'Indefinite Leave') {
        form.getCheckBox('Indefinite Leave').check()
    } else if (data.oppDetails.perField === 'Exceptional / Discretionary Leave') {
        form.getCheckBox('Exceptional / Discretionary Leave').check()
        form.getTextField('Text-other').setText('Exceptional / Discretionary Leave')
    } else if (data.oppDetails.perField === 'Humanitarian Protection') {
        form.getCheckBox('Humanitarian Protection').check()
    } else if (data.oppDetails.perField === 'Refugee') {
        form.getCheckBox('Refugee').check()
    } else if (data.oppDetails.perField === 'Family Member of EU citizen') {
        form.getCheckBox('familyMember').check()
    } else if (data.oppDetails.perField === 'British/EU/EEA/Citizen') {
        form.getTextField('Text-other').setText('British/EU/EEA/Citizen')
    }

    let date = new Date(data.createdAt)
    form.getTextField('Text-date').setText((date).toLocaleDateString())

    const emblemUrl = ensureHttps(data.signature) //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

    const signImageField = form.getTextField('Text-signature')
    signImageField.setImage(emblemImage)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    return file;
}


// COP Charge Letter PDF
const collegeCopChargeLetterPDF = async (data) => {
    const formPdfBytes = await fetch('/NCFEChargeLetter.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    form.getTextField('Name').setFontSize(9)
    form.getTextField('Date').setFontSize(9)
    form.getTextField('Name').setText(data.personalDetails.firstName + ' ' + data.personalDetails.lastName)
    let date = new Date(data.createdAt)
    form.getTextField('Date').setText((date).toLocaleDateString())

    const emblemUrl = ensureHttps(data.signature) //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

    const signImageField = form.getTextField('Signature')
    signImageField.setImage(emblemImage)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })

    return file;
}



// low income College of portsmouth
const collegeCopLowIncomePDF = async (data) => {
    const formPdfBytes = await fetch('/cop_lowincome.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    form.getTextField('NAME').setFontSize(9)
    form.getTextField('Salary per Year').setFontSize(9)
    form.getTextField('Hours per Week').setFontSize(9)
    form.getTextField('undefined').setFontSize(9)
    form.getTextField('NAME').setText(data.personalDetails.firstName + ' ' + data.personalDetails.lastName)
    form.getTextField('Salary per Year').setText(data?.employmentDetails?.annualSalary)
    if (data.employmentDetails.employementStatus === "Unemployed, looking for work") {
        form.getTextField('Salary per Year').setText("Unemployed")
    }
    form.getTextField('Hours per Week').setText(data?.employmentDetails?.hoursPerWeek)
    let date = new Date(data.createdAt)
    form.getTextField('undefined').setText((date).toLocaleDateString())

    const emblemUrl = ensureHttps(data.signature) //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

    const signImageField = form.getTextField('Signed Student')
    signImageField.setImage(emblemImage)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })

    return file;
}


// Benefits College of portsmouth
const collegeCopBenefitsPDF = async (data) => {
    const formPdfBytes = await fetch('/BenefitWaiverForm.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    form.getTextField('NAMW').setText(data.personalDetails.firstName + ' ' + data.personalDetails.lastName)
    form.getTextField('Text-NIN').setText(data.personalDetails.nationalInsNo)

    if (data.employmentDetails.dValue1) {
        form.getCheckBox('jsa').check()
    }
    if (data.employmentDetails.dValue2) {
        form.getCheckBox('income-support').check()
    }
    if (data.employmentDetails.dValue3) {
        form.getCheckBox('council-tax-benefit').check()
    }
    if (data.employmentDetails.dValue6) {
        form.getCheckBox('incapacity-benefit').check()
    }
    if (data.employmentDetails.dValue5) {
        form.getCheckBox('esa').check()
    }
    if (data.employmentDetails.dValue7) {
        form.getCheckBox('housing-benefit').check()
    }
    if (data.employmentDetails.dValue9) {
        form.getCheckBox('esa').check()
    }

    if (data.employmentDetails.dValue8) {
        form.getCheckBox('universal-credit').check()
    }

    if (data.employmentDetails.pipBenifits) {
        form.getCheckBox('personal-independence-payment').check()
    }

    let date = new Date(data.createdAt)
    form.getTextField('text-date').setText((date).toLocaleDateString())

    const emblemUrl = ensureHttps(data.signature) //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

    const signImageField = form.getTextField('signature')
    signImageField.setImage(emblemImage)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })

    return file;
}



// cop declaration
const collegeCopDeclartionPDF = async (data) => {
    const formPdfBytes = await fetch('/COP-declaraton.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    form.getTextField('Student Name').setFontSize(9)
    form.getTextField('Text-date').setFontSize(9)
    form.getTextField('Student Name').setText(data.personalDetails.firstName + ' ' + data.personalDetails.lastName)
    let date = new Date(data.createdAt)
    form.getTextField('Text-date').setText((date).toLocaleDateString())

    const emblemUrl = ensureHttps(data.signature) //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

    const signImageField = form.getTextField('Student Signature')
    signImageField.setImage(emblemImage)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    // convertFileToBase64(file).then((res: FileBase64) => {
    //     const { fileName, base64 } = res
    // })

    return file;
}


// cop declaration
const collegeCopL3FeePDF = async (data) => {
    const formPdfBytes = await fetch('/COPL3Fee.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    form.getTextField('Text1').setFontSize(9)
    form.getTextField('Text5').setFontSize(9)
    form.getTextField('Text1').setText(data.personalDetails.firstName + ' ' + data.personalDetails.lastName)
    let date = new Date(data.createdAt)
    form.getTextField('Text5').setText((date).toLocaleDateString())

    const emblemUrl = ensureHttps(data.signature) //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

    const signImageField = form.getTextField('Text-sign')
    signImageField.setImage(emblemImage)

    form.flatten()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    // convertFileToBase64(file).then((res: FileBase64) => {
    //     const { fileName, base64 } = res
    // })

    return file;
}




// College 1
export const HandlePDFChange = async (id) => {
    const res = await authService.getTestForm(id)
    const formPdfBytes = await fetch('/visionwest.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()
    const emblemUrl = res.data.data.signature //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
    const signImageField = form.getTextField('Text132')
    signImageField.setImage(emblemImage)

    const signImageField1 = form.getTextField('Text224')
    signImageField1.setImage(emblemImage)

    const signImageField2 = form.getTextField('Text219')
    signImageField2.setImage(emblemImage)

    // set field font
    form.getTextField('undefined_5').setFontSize(9)
    form.getTextField('undefined_39').setFontSize(9)

    // New Form Stuff
    if (res.data.data.personalDetails.title === 'Mr') {
        form.getCheckBox('Mrs').check()
    } else if (res.data.data.personalDetails.title === 'Mrs') {
        form.getCheckBox('Ms').check()
    } else if (res.data.data.personalDetails.title === 'Ms') {
        form.getCheckBox('Miss').check()
    } else if (res.data.data.personalDetails.title === 'Miss') {
        form.getCheckBox('Other').check()
    } else {
        form.getCheckBox('undefined').check()
    }
    form.getTextField('Text113').setText(res.data.data.personalDetails.lastName)
    form.getTextField('Text114').setText(res.data.data.personalDetails.firstName)
    let testDate = String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
    form.getTextField('Text116').setText(testDate)
    form.getTextField('Text117').setText(res.data.data.personalDetails.age)

    var yearDOB = parseInt(String(moment(res.data.data.personalDetails.dob).year()))
    var monthDOB = parseInt(String(moment(res.data.data.personalDetails.dob).month()))
    var calculatedAge = 2021 - yearDOB
    monthDOB++
    if (monthDOB > 8) {
        calculatedAge--
    }
    // console.log('Age: ', calculatedAge)

    form.getTextField('Text118').setText(calculatedAge.toString())

    const ins = res.data.data.personalDetails.nationalInsNo
    const first = ins.substr(0, 2)
    const sec = ins.substr(2, 2)
    const third = ins.substr(4, 2)
    const fourth = ins.substr(6, 3)

    form.getTextField('Age as of 31082022').setText(first)
    form.getTextField('Text119').setText(sec)
    form.getTextField('Text120').setText(third)
    form.getTextField('undefined_3').setText(fourth)

    if (res.data.data.personalDetails.gender === 'Female') {
        form.getCheckBox('undefined_2').check()
    } else {
        form.getCheckBox('Female').check()
    }

    form.getTextField('Text121').setText(res.data.data.personalDetails.addLine1)
    form.getTextField('Text122').setText(res.data.data.personalDetails.city)
    form.getTextField('Text123').setText(res.data.data.personalDetails.county)

    form.getTextField('Text124').setText(res.data.data.personalDetails.postcode)
    form.getTextField('Text125').setText(res.data.data.personalDetails.yearsAtAdd + ' years')

    form.getTextField('undefined_4').setText(
        res.data.data.personalDetails.telephone
    )
    form.getTextField('undefined_5').setText(res.data.data.personalDetails.telephone)

    form.getTextField('Text128').setText(res.data.data.personalDetails.email)
    form.getTextField('Text129').setText(res.data.data.emergencyDetails.emergencyContactName)
    form.getTextField('Text130').setText(res.data.data.emergencyDetails.relationshipToLearner)
    form.getTextField('Text131').setText(res.data.data.emergencyDetails.emergencyTelephone)

    //declaration
    if (res.data.data.declaration.houseHoldMem) {
        form.getCheckBox('CheckBox-OkTBYzYwo0').check()
    }
    if (res.data.data.declaration.houseHoldMem2) {
        form.getCheckBox('CheckBox-tDCKH3r-ex').check()
    }
    if (res.data.data.declaration.singleHouse) {
        form.getCheckBox('CheckBox-uZJKy9Kho8').check()
    }
    if (res.data.data.declaration.noneOfThese) {
        form.getCheckBox('CheckBox-2oXSy1q0EX').check()
    }
    if (res.data.data.declaration.confirmation) {
        form.getCheckBox('CheckBox-h6-WSXcfvL').check()
    }

    if (
        res.data.data.oppDetails.ethnicOrigin ===
        'English/Welsh/Scottish/Northern Irish/British'
    ) {
        form.getCheckBox('Check Box134').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
        form.getCheckBox('Check Box135').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
        form.getCheckBox('Check Box136').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
        form.getCheckBox('Check Box137').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
        form.getCheckBox('Check Box138').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
        form.getCheckBox('Check Box139').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
        form.getCheckBox('Check Box140').check()
    } else if (
        res.data.data.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background'
    ) {
        form.getCheckBox('Check Box141').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
        form.getCheckBox('Check Box142').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
        form.getCheckBox('Check Box143').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
        form.getCheckBox('Check Box144').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
        form.getCheckBox('Check Box145').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
        form.getCheckBox('Check Box146').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
        form.getCheckBox('Check Box147').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
        form.getCheckBox('Check Box148').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
        form.getCheckBox('Check Box149').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
        form.getCheckBox('Check Box150').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
        form.getCheckBox('Check Box151').check()
    }

    if (res.data.data.oppDetails.firstLang === 'Yes') {
        form.getCheckBox('Check Box153').check()
    } else {
        form.getCheckBox('Check Box154').check()
    }

    if (res.data.data.oppDetails.resident === 'Yes') {
        form.getCheckBox('Check Box155').check()
    } else {
        form.getCheckBox('Check Box156').check()
    }

    form.getTextField('Text159').setText(res.data.data.oppDetails.nationality)
    if (res.data.data.oppDetails.nonEEACitizen === 'Yes') {
        form.getCheckBox('Check Box161').check()
    } else {
        form.getCheckBox('Check Box162').check()
    }

    if (res.data.data.oppDetails.statementScheme === 'Yes') {
        form.getCheckBox('Check Box157').check()
    } else {
        form.getCheckBox('Check Box158').check()
    }

    if (res.data.data.employmentDetails.employementStatus !== 'Unemployed, looking for work') {
        form.getTextField('Text163').setText(res.data.data.employmentDetails.employerName)
        form.getTextField('Text164').setText(res.data.data.employmentDetails.employerAdd)
        form.getTextField('Text166').setText(res.data.data.employmentDetails.hoursPerWeek)
        if (res.data.data.employmentDetails.postcode) {
            let employerPostC = (res.data.data.employmentDetails.postcode).replace(/ /g, "")
            let firstPart = employerPostC.slice(0, 4)
            let secondPart = employerPostC.slice(4, 3)
            form.getTextField('undefined_6').setText(firstPart)
            form.getTextField('Text165').setText(secondPart)
        }
        form.getTextField('undefined_7').setText(res.data.data.employmentDetails.ph)
        if (res.data.data.employmentDetails.length === 'Up to 3') {
            form.getCheckBox('Check Box167').check()
        } else if (res.data.data.employmentDetails.length === '4-6') {
            form.getCheckBox('Check Box168').check()
        } else if (res.data.data.employmentDetails.length === '7-12') {
            form.getCheckBox('Check Box169').check()
        } else {
            form.getCheckBox('Check Box170').check()
        }
    }

    if (res.data.data.oppDetails.disabilities === 'Yes') {
        if (res.data.data.oppDetails.dValue1) {
            form.getCheckBox('Check Box86').check()
        }
        if (res.data.data.oppDetails.dValue2) {
            form.getCheckBox('Check Box87').check()
        }
        if (res.data.data.oppDetails.dValue3) {
            form.getCheckBox('Check Box88').check()
        }
        if (res.data.data.oppDetails.dValue4) {
            form.getCheckBox('Check Box89').check()
        }
        if (res.data.data.oppDetails.dValue5) {
            form.getCheckBox('Check Box90').check()
        }
        if (res.data.data.oppDetails.dValue6) {
            form.getCheckBox('Check Box91').check()
        }
        if (res.data.data.oppDetails.dValue7) {
            form.getCheckBox('Check Box92').check()
        }
        if (res.data.data.oppDetails.dValue8) {
            form.getCheckBox('Check Box93').check()
        }
        if (res.data.data.oppDetails.dValue9) {
            form.getCheckBox('Check Box94').check()
        }
        if (res.data.data.oppDetails.dValue10) {
            form.getCheckBox('Check Box95').check()
        }
        if (res.data.data.oppDetails.dValue11) {
            form.getCheckBox('Check Box107').check()
        }
        if (res.data.data.oppDetails.dValue12) {
            form.getCheckBox('Check Box106').check()
        }
        if (res.data.data.oppDetails.dValue13) {
            form.getCheckBox('Check Box105').check()
        }
        if (res.data.data.oppDetails.dValue14) {
            form.getCheckBox('Check Box104').check()
        }
        if (res.data.data.oppDetails.dValue15) {
            form.getCheckBox('Check Box103').check()
        }
        if (res.data.data.oppDetails.dValue16) {
            form.getCheckBox('Check Box102').check()
        }
        if (res.data.data.oppDetails.dValue17) {
            form.getCheckBox('Check Box101').check()
        }
        if (res.data.data.oppDetails.dValue18) {
            form.getCheckBox('Check Box100').check()
        }
    }
    if (res.data.data.oppDetails.wheelchair === 'Yes') {
        form.getCheckBox('Check Box98').check()
    } else {
        form.getCheckBox('Check Box99').check()
    }

    if (res.data.data.qualificationDetails.level === 'Entry Level') {
        form.getCheckBox('Check Box172').check()
    } else if (res.data.data.qualificationDetails.level === 'None') {
        form.getCheckBox('Check Box171').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 1') {
        form.getCheckBox('Check Box173').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 2') {
        form.getCheckBox('Check Box174').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 3') {
        form.getCheckBox('Check Box176').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 4') {
        form.getCheckBox('Check Box178').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 5') {
        form.getCheckBox('Check Box179').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 6') {
        form.getCheckBox('Check Box180').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 7') {
        form.getCheckBox('Check Box181').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
        form.getCheckBox('Check Box181').check()
    } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
        form.getCheckBox('Check Box175').check()
    } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
        form.getCheckBox('Check Box177').check()
    }

    form.getTextField('Text133').setText(res.data.data.date)
    form.getTextField('Text225').setText(res.data.data.date)

    form.getTextField('Text183').setText(res.data.data.qualificationDetails.englishGrades)
    form.getTextField('Text182').setText(res.data.data.qualificationDetails.mathGrades)
    form.getTextField(
        'Do you have any unspent criminal convictions or charges pending'
    ).setText(res.data.data.oppDetails.criminalConv)
    form.getTextField('Text184').setText(res.data.data.detailsFormData.appliedCourse)
    // form.getTextField("Text118").setText(res.data.data.date);
    form.getTextField('undefined_38').setText('ZZ99')
    form.getTextField('undefined_39').setText('9ZZ')

    if (res.data.data.employmentDetails.employementStatus === 'Unemployed, looking for work') {
        if (res.data.data.employmentDetails.dValue1) {
            form.getCheckBox('Check Box204').check()
        }
        if (res.data.data.employmentDetails.dValue2) {
            form.getCheckBox('Check Box209').check()
        }
        if (res.data.data.employmentDetails.dValue3) {
            form.getCheckBox('Check Box205').check()
        }
        if (res.data.data.employmentDetails.dValue5) {
            form.getCheckBox('Check Box210').check()
        }
        if (res.data.data.employmentDetails.dValue6) {
            form.getCheckBox('Check Box206').check()
        }
        if (res.data.data.employmentDetails.dValue7) {
            form.getCheckBox('Check Box211').check()
        }
        if (res.data.data.employmentDetails.dValue8) {
            form.getCheckBox('Check Box207').check()
        }
        if (res.data.data.employmentDetails.dValue9) {
            form.getCheckBox('Check Box212').check()
        }
        if (res.data.data.employmentDetails.dValue10 === 'Yes') {
            form.getCheckBox('Check Box208').check()
        }
    }
    if (
        res.data.data.employmentDetails.dValue2 ||
        res.data.data.employmentDetails.dValue1 ||
        res.data.data.employmentDetails.dValue3 ||
        res.data.data.employmentDetails.dValue5 ||
        res.data.data.employmentDetails.dValue6 ||
        res.data.data.employmentDetails.dValue7 ||
        res.data.data.employmentDetails.dValue8 ||
        res.data.data.employmentDetails.dValue9 ||
        res.data.data.employmentDetails.dValue10
    ) {
    } else {
        if (res.data.data.employmentDetails.dValue11) {
            form.getCheckBox('Check Box213').check()
        }
    }

    if (res.data.data.employmentDetails.unemployedLength === '0-5 months') {
        form.getCheckBox('Check Box214').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '6-11 months') {
        form.getCheckBox('Check Box215').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '12-23 months') {
        form.getCheckBox('Check Box216').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '24-35 months') {
        form.getCheckBox('Check Box217').check()
    } else if (res.data.data.employmentDetails.unemployedLength === 'Over 36 months') {
        form.getCheckBox('Check Box218').check()
    }
    if (res.data.data.employmentDetails.areYou === 'Yes') {
        form.getCheckBox('Check Box200').check()
    }
    if (
        res.data.data.employmentDetails.employementStatus ===
        'Employed but on less than £17,374.50 per year'
    ) {
        form.getCheckBox('Check Box212').check()
    }

    // if (res.data.data.declaration.prefContact1) form.getCheckBox('By post').check()
    // if (res.data.data.declaration.prefContact2) form.getCheckBox('By phone').check()
    // if (res.data.data.declaration.prefContact) form.getCheckBox('By email').check()
    // if (res.data.data.declaration.aboutCourse)
    // 	form.getCheckBox('About courses or learning opportunities').check()
    // if (res.data.data.declaration.forSurveys)
    // 	form.getCheckBox('For surveys and research').check()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file)
    //Open the URL on new Window
    window.open(fileURL)
}
export const HandlePDFChange1 = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const res = await authService.getTestForm(ids[i])
        const formPdfBytes = await fetch('/visionwest.pdf', { cache: 'reload' }).then((res) =>
            res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.data.data.signature //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text132')
        signImageField.setImage(emblemImage)

        const signImageField1 = form.getTextField('Text224')
        signImageField1.setImage(emblemImage)

        const signImageField2 = form.getTextField('Text219')
        signImageField2.setImage(emblemImage)

        // set field font
        form.getTextField('undefined_5').setFontSize(9)
        form.getTextField('undefined_39').setFontSize(9)

        // New Form Stuff
        if (res.data.data.personalDetails.title === 'Mr') {
            form.getCheckBox('Mrs').check()
        } else if (res.data.data.personalDetails.title === 'Mrs') {
            form.getCheckBox('Ms').check()
        } else if (res.data.data.personalDetails.title === 'Ms') {
            form.getCheckBox('Miss').check()
        } else if (res.data.data.personalDetails.title === 'Miss') {
            form.getCheckBox('Other').check()
        } else {
            form.getCheckBox('undefined').check()
        }
        form.getTextField('Text113').setText(res.data.data.personalDetails.lastName)
        form.getTextField('Text114').setText(res.data.data.personalDetails.firstName)
        let testDate = String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        form.getTextField('Text116').setText(testDate)
        form.getTextField('Text117').setText(res.data.data.personalDetails.age)

        var yearDOB = parseInt(String(moment(res.data.data.personalDetails.dob).year()))
        var monthDOB = parseInt(String(moment(res.data.data.personalDetails.dob).month()))
        var calculatedAge = 2021 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }
        // console.log('Age: ', calculatedAge)

        form.getTextField('Text118').setText(calculatedAge.toString())

        const ins = res.data.data.personalDetails.nationalInsNo
        const first = ins.substr(0, 2)
        const sec = ins.substr(2, 2)
        const third = ins.substr(4, 2)
        const fourth = ins.substr(6, 3)

        form.getTextField('Age as of 31082022').setText(first)
        form.getTextField('Text119').setText(sec)
        form.getTextField('Text120').setText(third)
        form.getTextField('undefined_3').setText(fourth)

        if (res.data.data.personalDetails.gender === 'Female') {
            form.getCheckBox('undefined_2').check()
        } else {
            form.getCheckBox('Female').check()
        }

        form.getTextField('Text121').setText(res.data.data.personalDetails.addLine1)
        form.getTextField('Text122').setText(res.data.data.personalDetails.city)
        form.getTextField('Text123').setText(res.data.data.personalDetails.county)

        form.getTextField('Text124').setText(res.data.data.personalDetails.postcode)
        form.getTextField('Text125').setText(res.data.data.personalDetails.yearsAtAdd + ' years')

        form.getTextField('undefined_4').setText(
            res.data.data.personalDetails.telephone
        )
        form.getTextField('undefined_5').setText(res.data.data.personalDetails.telephone)

        form.getTextField('Text128').setText(res.data.data.personalDetails.email)
        form.getTextField('Text129').setText(res.data.data.emergencyDetails.emergencyContactName)
        form.getTextField('Text130').setText(res.data.data.emergencyDetails.relationshipToLearner)
        form.getTextField('Text131').setText(res.data.data.emergencyDetails.emergencyTelephone)

        //declaration
        if (res.data.data.declaration.houseHoldMem) {
            form.getCheckBox('CheckBox-OkTBYzYwo0').check()
        }
        if (res.data.data.declaration.houseHoldMem2) {
            form.getCheckBox('CheckBox-tDCKH3r-ex').check()
        }
        if (res.data.data.declaration.singleHouse) {
            form.getCheckBox('CheckBox-uZJKy9Kho8').check()
        }
        if (res.data.data.declaration.noneOfThese) {
            form.getCheckBox('CheckBox-2oXSy1q0EX').check()
        }
        if (res.data.data.declaration.confirmation) {
            form.getCheckBox('CheckBox-h6-WSXcfvL').check()
        }

        if (
            res.data.data.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getCheckBox('Check Box134').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box135').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box136').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box137').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box138').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box139').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box140').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Check Box141').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box142').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box143').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box144').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box145').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box146').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box147').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box148').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
            form.getCheckBox('Check Box149').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box150').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box151').check()
        }

        if (res.data.data.oppDetails.firstLang === 'Yes') {
            form.getCheckBox('Check Box153').check()
        } else {
            form.getCheckBox('Check Box154').check()
        }

        if (res.data.data.oppDetails.resident === 'Yes') {
            form.getCheckBox('Check Box155').check()
        } else {
            form.getCheckBox('Check Box156').check()
        }

        form.getTextField('Text159').setText(res.data.data.oppDetails.nationality)
        if (res.data.data.oppDetails.nonEEACitizen === 'Yes') {
            form.getCheckBox('Check Box161').check()
        } else {
            form.getCheckBox('Check Box162').check()
        }

        if (res.data.data.oppDetails.statementScheme === 'Yes') {
            form.getCheckBox('Check Box157').check()
        } else {
            form.getCheckBox('Check Box158').check()
        }

        if (res.data.data.employmentDetails.employementStatus !== 'Unemployed, looking for work') {
            form.getTextField('Text163').setText(res.data.data.employmentDetails.employerName);
            form.getTextField('Text164').setText(res.data.data.employmentDetails.employerAdd);
            form.getTextField('Text166').setText(res.data.data.employmentDetails.hoursPerWeek);
            if (res.data.data.employmentDetails.postcode) {
                let employerPostC = (res.data.data.employmentDetails.postcode).replace(/ /g, "")
                let firstPart = employerPostC.slice(0, 4)
                let secondPart = employerPostC.slice(4, 3)
                form.getTextField('undefined_6').setText(firstPart)
                form.getTextField('Text165').setText(secondPart)
            }
            form.getTextField('undefined_7').setText(res.data.data.employmentDetails.ph)
            if (res.data.data.employmentDetails.length === 'Up to 3') {
                form.getCheckBox('Check Box167').check()
            } else if (res.data.data.employmentDetails.length === '4-6') {
                form.getCheckBox('Check Box168').check()
            } else if (res.data.data.employmentDetails.length === '7-12') {
                form.getCheckBox('Check Box169').check()
            } else {
                form.getCheckBox('Check Box170').check()
            }
        }

        if (res.data.data.oppDetails.disabilities === 'Yes') {
            if (res.data.data.oppDetails.dValue1) {
                form.getCheckBox('Check Box86').check()
            }
            if (res.data.data.oppDetails.dValue2) {
                form.getCheckBox('Check Box87').check()
            }
            if (res.data.data.oppDetails.dValue3) {
                form.getCheckBox('Check Box88').check()
            }
            if (res.data.data.oppDetails.dValue4) {
                form.getCheckBox('Check Box89').check()
            }
            if (res.data.data.oppDetails.dValue5) {
                form.getCheckBox('Check Box90').check()
            }
            if (res.data.data.oppDetails.dValue6) {
                form.getCheckBox('Check Box91').check()
            }
            if (res.data.data.oppDetails.dValue7) {
                form.getCheckBox('Check Box92').check()
            }
            if (res.data.data.oppDetails.dValue8) {
                form.getCheckBox('Check Box93').check()
            }
            if (res.data.data.oppDetails.dValue9) {
                form.getCheckBox('Check Box94').check()
            }
            if (res.data.data.oppDetails.dValue10) {
                form.getCheckBox('Check Box95').check()
            }
            if (res.data.data.oppDetails.dValue11) {
                form.getCheckBox('Check Box107').check()
            }
            if (res.data.data.oppDetails.dValue12) {
                form.getCheckBox('Check Box106').check()
            }
            if (res.data.data.oppDetails.dValue13) {
                form.getCheckBox('Check Box105').check()
            }
            if (res.data.data.oppDetails.dValue14) {
                form.getCheckBox('Check Box104').check()
            }
            if (res.data.data.oppDetails.dValue15) {
                form.getCheckBox('Check Box103').check()
            }
            if (res.data.data.oppDetails.dValue16) {
                form.getCheckBox('Check Box102').check()
            }
            if (res.data.data.oppDetails.dValue17) {
                form.getCheckBox('Check Box101').check()
            }
            if (res.data.data.oppDetails.dValue18) {
                form.getCheckBox('Check Box100').check()
            }
        }
        if (res.data.data.oppDetails.wheelchair === 'Yes') {
            form.getCheckBox('Check Box98').check()
        } else {
            form.getCheckBox('Check Box99').check()
        }

        if (res.data.data.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Check Box172').check()
        } else if (res.data.data.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box171').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Check Box173').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Check Box174').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Check Box176').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Check Box178').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Check Box179').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Check Box180').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Check Box181').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Check Box181').check()
        } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Check Box175').check()
        } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Check Box177').check()
        }

        form.getTextField('Text133').setText(res.data.data.date)
        form.getTextField('Text225').setText(res.data.data.date)

        form.getTextField('Text183').setText(res.data.data.qualificationDetails.englishGrades)
        form.getTextField('Text182').setText(res.data.data.qualificationDetails.mathGrades)
        form.getTextField(
            'Do you have any unspent criminal convictions or charges pending'
        ).setText(res.data.data.oppDetails.criminalConv)
        form.getTextField('Text184').setText(res.data.data.detailsFormData.appliedCourse)
        // form.getTextField("Text118").setText(res.data.data.date);
        form.getTextField('undefined_38').setText('ZZ99')
        form.getTextField('undefined_39').setText('9ZZ')

        if (res.data.data.employmentDetails.employementStatus === 'Unemployed, looking for work') {
            if (res.data.data.employmentDetails.dValue1) {
                form.getCheckBox('Check Box204').check()
            }
            if (res.data.data.employmentDetails.dValue2) {
                form.getCheckBox('Check Box209').check()
            }
            if (res.data.data.employmentDetails.dValue3) {
                form.getCheckBox('Check Box205').check()
            }
            if (res.data.data.employmentDetails.dValue5) {
                form.getCheckBox('Check Box210').check()
            }
            if (res.data.data.employmentDetails.dValue6) {
                form.getCheckBox('Check Box206').check()
            }
            if (res.data.data.employmentDetails.dValue7) {
                form.getCheckBox('Check Box211').check()
            }
            if (res.data.data.employmentDetails.dValue8) {
                form.getCheckBox('Check Box207').check()
            }
            if (res.data.data.employmentDetails.dValue9) {
                form.getCheckBox('Check Box212').check()
            }
            if (res.data.data.employmentDetails.dValue10 === 'Yes') {
                form.getCheckBox('Check Box208').check()
            }
        }
        if (
            res.data.data.employmentDetails.dValue2 ||
            res.data.data.employmentDetails.dValue1 ||
            res.data.data.employmentDetails.dValue3 ||
            res.data.data.employmentDetails.dValue5 ||
            res.data.data.employmentDetails.dValue6 ||
            res.data.data.employmentDetails.dValue7 ||
            res.data.data.employmentDetails.dValue8 ||
            res.data.data.employmentDetails.dValue9 ||
            res.data.data.employmentDetails.dValue10
        ) {
        } else {
            if (res.data.data.employmentDetails.dValue11) {
                form.getCheckBox('Check Box213').check()
            }
        }

        if (res.data.data.employmentDetails.unemployedLength === '0-5 months') {
            form.getCheckBox('Check Box214').check()
        } else if (res.data.data.employmentDetails.unemployedLength === '6-11 months') {
            form.getCheckBox('Check Box215').check()
        } else if (res.data.data.employmentDetails.unemployedLength === '12-23 months') {
            form.getCheckBox('Check Box216').check()
        } else if (res.data.data.employmentDetails.unemployedLength === '24-35 months') {
            form.getCheckBox('Check Box217').check()
        } else if (res.data.data.employmentDetails.unemployedLength === 'Over 36 months') {
            form.getCheckBox('Check Box218').check()
        }
        if (res.data.data.employmentDetails.areYou === 'Yes') {
            form.getCheckBox('Check Box200').check()
        }
        if (
            res.data.data.employmentDetails.employementStatus ===
            'Employed but on less than £17,374.50 per year'
        ) {
            form.getCheckBox('Check Box212').check()
        }

        // if (res.data.data.declaration.prefContact1) form.getCheckBox('By post').check()
        // if (res.data.data.declaration.prefContact2) form.getCheckBox('By phone').check()
        // if (res.data.data.declaration.prefContact) form.getCheckBox('By email').check()
        // if (res.data.data.declaration.aboutCourse)
        // 	form.getCheckBox('About courses or learning opportunities').check()
        // if (res.data.data.declaration.forSurveys)
        // 	form.getCheckBox('For surveys and research').check()

        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        var images = [res.data.data.nationalId, res.data.data.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
        ).file(
            res.data.data.personalDetails.firstName +
            res.data.data.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.data.data.nationalId) {
            const imageBlob = fetch(res.data.data.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('National_ID.' + (res.data.data.nationalId).substr((res.data.data.nationalId).length - 3), imageBlob)
        }
        if (res.data.data.certificateLink) {
            const imageBlob = fetch(res.data.data.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Certificate.' + (res.data.data.certificateLink).substr((res.data.data.certificateLink).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment1) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment1.' + (res.data.data.oppDetails.attachment1).substr((res.data.data.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment2) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment2.' + (res.data.data.oppDetails.attachment2).substr((res.data.data.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment3) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment3.' + (res.data.data.oppDetails.attachment3).substr((res.data.data.oppDetails.attachment3).length - 3), imageBlob)
        }

        if (res.data.data.oppDetails.attachment4) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment4.' + (res.data.data.oppDetails.attachment4).substr((res.data.data.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence.' + (res.data.data.employmentDetails.evidence).substr((res.data.data.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence1) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence1.' + (res.data.data.employmentDetails.evidence1).substr((res.data.data.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence2) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence2.' + (res.data.data.employmentDetails.evidence2).substr((res.data.data.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.data.data.assessment) {
            if (res.data.data.assessment.english) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res.data.data),
                    { base64: true }
                )
            }
            if (res.data.data.assessment.answer1) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res.data.data),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}
// College 2
export const HandlePDFChange2 = async (id) => {
    const res = await authService.getTestForm(id)
    const formPdfBytes = await fetch('/college2.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()
    const emblemUrl = res.data.data.signature //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
    const signImageField = form.getTextField('Text20')
    signImageField.setImage(emblemImage)

    const signImageField1 = form.getTextField('Text106')
    signImageField1.setImage(emblemImage)

    const signImageField2 = form.getTextField('Text112')
    signImageField2.setImage(emblemImage)

    // set field font
    form.getTextField('undefined_4').setFontSize(9)
    form.getTextField('undefined_14').setFontSize(9)

    // New Form Stuff
    if (res.data.data.personalDetails.title === 'Mr') {
        form.getCheckBox('Mrs').check()
    } else if (res.data.data.personalDetails.title === 'Mrs') {
        form.getCheckBox('Ms').check()
    } else if (res.data.data.personalDetails.title === 'Ms') {
        form.getCheckBox('Miss').check()
    } else if (res.data.data.personalDetails.title === 'Miss') {
        form.getCheckBox('Other').check()
    } else {
        form.getCheckBox('undefined').check()
    }
    form.getTextField('Text1').setText(res.data.data.personalDetails.lastName)
    form.getTextField('Text2').setText(res.data.data.personalDetails.firstName)
    let testDate = String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
    form.getTextField('Text3').setText(testDate)
    form.getTextField('Text4').setText(res.data.data.personalDetails.age)

    var yearDOB = parseInt(String(moment(res.data.data.personalDetails.dob).year()))
    var monthDOB = parseInt(String(moment(res.data.data.personalDetails.dob).month()))
    var calculatedAge = 2021 - yearDOB
    monthDOB++
    if (monthDOB > 8) {
        calculatedAge--
    }
    // console.log('Age: ', calculatedAge)

    form.getTextField('Text5').setText(calculatedAge.toString())

    const ins = res.data.data.personalDetails.nationalInsNo
    const first = ins.substr(0, 2)
    const sec = ins.substr(2, 2)
    const third = ins.substr(4, 2)
    const fourth = ins.substr(6, 3)

    form.getTextField('Text6').setText(first)
    form.getTextField('Text7').setText(sec)
    form.getTextField('Text8').setText(third)
    form.getTextField('Text9').setText(fourth)

    if (res.data.data.personalDetails.gender === 'Female') {
        form.getCheckBox('undefined_2').check()
    } else {
        form.getCheckBox('Female').check()
    }

    form.getTextField('Text10').setText(res.data.data.personalDetails.addLine1)
    form.getTextField('Text11').setText(res.data.data.personalDetails.city)
    form.getTextField('Text12').setText(res.data.data.personalDetails.county)

    form.getTextField('Text13').setText(res.data.data.personalDetails.postcode)
    form.getTextField('Text14').setText(res.data.data.personalDetails.yearsAtAdd + ' years')

    form.getTextField('How long have you lived at this address').setText(
        res.data.data.personalDetails.telephone
    )
    form.getTextField('undefined_4').setText(res.data.data.personalDetails.telephone)

    form.getTextField('Text15').setText(res.data.data.personalDetails.email)
    form.getTextField('Text16').setText(res.data.data.emergencyDetails.emergencyContactName)
    form.getTextField('Text17').setText(res.data.data.emergencyDetails.relationshipToLearner)
    form.getTextField('Text18').setText(res.data.data.emergencyDetails.emergencyTelephone)

    //declaration
    if (res.data.data.declaration.houseHoldMem) {
        form.getCheckBox('No household member is in employment and the household').check()
    }
    if (res.data.data.declaration.houseHoldMem2) {
        form.getCheckBox('No household member is in employment and the household_2').check()
    }
    if (res.data.data.declaration.singleHouse) {
        form.getCheckBox('Learner lives in a single adult household with dependent').check()
    }
    if (res.data.data.declaration.noneOfThese) {
        form.getCheckBox('children').check()
    }
    if (res.data.data.declaration.confirmation)
        form.getCheckBox('I confirm that I wish to withhold this information').check()

    if (
        res.data.data.oppDetails.ethnicOrigin ===
        'English/Welsh/Scottish/Northern Irish/British'
    ) {
        form.getCheckBox('Check Box45').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
        form.getCheckBox('Check Box46').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
        form.getCheckBox('Check Box47').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
        form.getCheckBox('Check Box48').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
        form.getCheckBox('Check Box49').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
        form.getCheckBox('Check Box50').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
        form.getCheckBox('Check Box51').check()
    } else if (
        res.data.data.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background'
    ) {
        form.getCheckBox('Check Box52').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
        form.getCheckBox('Check Box53').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
        form.getCheckBox('Check Box54').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
        form.getCheckBox('Check Box55').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
        form.getCheckBox('Check Box56').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
        form.getCheckBox('Check Box57').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
        form.getCheckBox('Check Box58').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
        form.getCheckBox('Check Box59').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
        form.getCheckBox('Check Box60').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
        form.getCheckBox('Check Box61').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
        form.getCheckBox('Check Box62').check()
    }

    if (res.data.data.oppDetails.firstLang === 'Yes') {
        form.getCheckBox('Check Box63').check()
    } else {
        form.getCheckBox('Check Box64').check()
    }

    if (res.data.data.oppDetails.resident === 'Yes') {
        form.getCheckBox('Check Box65').check()
    } else {
        form.getCheckBox('Check Box66').check()
    }

    form.getTextField('Text69').setText(res.data.data.oppDetails.nationality)
    if (res.data.data.oppDetails.nonEEACitizen === 'Yes') {
        form.getCheckBox('Check Box70').check()
    } else {
        form.getCheckBox('Check Box72').check()
    }

    if (res.data.data.oppDetails.statementScheme === 'Yes') {
        form.getCheckBox('Check Box67').check()
    } else {
        form.getCheckBox('Check Box68').check()
    }

    if (res.data.data.employmentDetails.employementStatus !== 'Unemployed, looking for work') {
        form.getTextField('Text73').setText(res.data.data.employmentDetails.employerName)
        form.getTextField('Text75').setText(res.data.data.employmentDetails.employerAdd)
        form.getTextField('Text30').setText(res.data.data.employmentDetails.hoursPerWeek)
        if (res.data.data.employmentDetails.postcode)
            form.getTextField('undefined_5').setText((res.data.data.employmentDetails.postcode).slice(0, 8))
        form.getTextField('undefined_6').setText(res.data.data.employmentDetails.ph)
        if (res.data.data.employmentDetails.length === 'Up to 3') {
            form.getCheckBox('Check Box76').check()
        } else if (res.data.data.employmentDetails.length === '4-6') {
            form.getCheckBox('Check Box77').check()
        } else if (res.data.data.employmentDetails.length === '7-12') {
            form.getCheckBox('Check Box78').check()
        } else {
            form.getCheckBox('Check Box79').check()
        }
    }

    if (res.data.data.oppDetails.disabilities === 'Yes') {
        if (res.data.data.oppDetails.dValue1) {
            form.getCheckBox('Check Box24').check()
        }
        if (res.data.data.oppDetails.dValue2) {
            form.getCheckBox('Check Box25').check()
        }
        if (res.data.data.oppDetails.dValue3) {
            form.getCheckBox('Check Box26').check()
        }
        if (res.data.data.oppDetails.dValue4) {
            form.getCheckBox('Check Box27').check()
        }
        if (res.data.data.oppDetails.dValue5) {
            form.getCheckBox('Check Box28').check()
        }
        if (res.data.data.oppDetails.dValue6) {
            form.getCheckBox('Check Box29').check()
        }
        if (res.data.data.oppDetails.dValue7) {
            form.getCheckBox('Check Box30').check()
        }
        if (res.data.data.oppDetails.dValue8) {
            form.getCheckBox('Check Box31').check()
        }
        if (res.data.data.oppDetails.dValue9) {
            form.getCheckBox('Check Box32').check()
        }
        if (res.data.data.oppDetails.dValue10) {
            form.getCheckBox('Check Box33').check()
        }
        if (res.data.data.oppDetails.dValue11) {
            form.getCheckBox('Check Box34').check()
        }
        if (res.data.data.oppDetails.dValue12) {
            form.getCheckBox('Check Box35').check()
        }
        if (res.data.data.oppDetails.dValue13) {
            form.getCheckBox('Check Box36').check()
        }
        if (res.data.data.oppDetails.dValue14) {
            form.getCheckBox('Check Box37').check()
        }
        if (res.data.data.oppDetails.dValue15) {
            form.getCheckBox('Check Box39').check()
        }
        if (res.data.data.oppDetails.dValue16) {
            form.getCheckBox('Check Box40').check()
        }
        if (res.data.data.oppDetails.dValue17) {
            form.getCheckBox('Check Box41').check()
        }
        if (res.data.data.oppDetails.dValue18) {
            form.getCheckBox('Check Box42').check()
        }
    }
    if (res.data.data.oppDetails.wheelchair === 'Yes') {
        form.getCheckBox('Check Box44').check()
    } else {
        form.getCheckBox('Check Box43').check()
    }

    if (res.data.data.qualificationDetails.level === 'Entry Level') {
        form.getCheckBox('Check Box81').check()
    } else if (res.data.data.qualificationDetails.level === 'None') {
        form.getCheckBox('Check Box80').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 1') {
        form.getCheckBox('Check Box82').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 2') {
        form.getCheckBox('Check Box83').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 3') {
        form.getCheckBox('Check Box85').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 4') {
        form.getCheckBox('Check Box87').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 5') {
        form.getCheckBox('Check Box88').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 6') {
        form.getCheckBox('Check Box90').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 7') {
        form.getCheckBox('Check Box91').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
        form.getCheckBox('Check Box92').check()
    } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
        form.getCheckBox('Check Box84').check()
    } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
        form.getCheckBox('Check Box86').check()
    }

    form.getTextField('Text21').setText(res.data.data.date)
    form.getTextField('Text113').setText(res.data.data.date)

    form.getTextField('Text95').setText(res.data.data.qualificationDetails.englishGrades)
    form.getTextField('Text93').setText(res.data.data.qualificationDetails.mathGrades)
    form.getTextField(
        'Do you have any unspent criminal convictions or charges pending'
    ).setText(res.data.data.oppDetails.criminalConv)
    form.getTextField('Text117').setText(res.data.data.detailsFormData.appliedCourse)
    // form.getTextField("Text118").setText(res.data.data.date);
    form.getTextField('undefined_13').setText('ZZ99')
    form.getTextField('undefined_14').setText('9ZZ')

    if (res.data.data.employmentDetails.employementStatus === 'Unemployed, looking for work') {
        if (res.data.data.employmentDetails.dValue1) {
            form.getCheckBox('Check Box75').check()
        }
        if (res.data.data.employmentDetails.dValue2) {
            form.getCheckBox('Check Box95').check()
        }
        if (res.data.data.employmentDetails.dValue3) {
            form.getCheckBox('Check Box97').check()
        }
        if (res.data.data.employmentDetails.dValue5) {
            form.getCheckBox('Check Box89').check()
        }
        if (res.data.data.employmentDetails.dValue6) {
            form.getCheckBox('Check Box96').check()
        }
        if (res.data.data.employmentDetails.dValue7) {
            form.getCheckBox('Check Box98').check()
        }
        if (res.data.data.employmentDetails.dValue8) {
            form.getCheckBox('Check Box93').check()
        }
        if (res.data.data.employmentDetails.dValue9) {
            form.getCheckBox('Check Box99').check()
        }
        if (res.data.data.employmentDetails.dValue10 === 'Yes') {
            form.getCheckBox('Check Box94').check()
        }
    }
    if (
        res.data.data.employmentDetails.dValue2 ||
        res.data.data.employmentDetails.dValue1 ||
        res.data.data.employmentDetails.dValue3 ||
        res.data.data.employmentDetails.dValue5 ||
        res.data.data.employmentDetails.dValue6 ||
        res.data.data.employmentDetails.dValue7 ||
        res.data.data.employmentDetails.dValue8 ||
        res.data.data.employmentDetails.dValue9 ||
        res.data.data.employmentDetails.dValue10
    ) {
    } else {
        if (res.data.data.employmentDetails.dValue11) {
            form.getCheckBox('Check Box100').check()
        }
    }

    if (res.data.data.employmentDetails.unemployedLength === '0-5 months') {
        form.getCheckBox('Check Box101').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '6-11 months') {
        form.getCheckBox('Check Box102').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '12-23 months') {
        form.getCheckBox('Check Box103').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '24-35 months') {
        form.getCheckBox('Check Box105').check()
    } else if (res.data.data.employmentDetails.unemployedLength === 'Over 36 months') {
        form.getCheckBox('Check Box104').check()
    }
    if (res.data.data.employmentDetails.areYou === 'Yes') {
        form.getCheckBox('Check Box74').check()
    }
    if (
        res.data.data.employmentDetails.employementStatus ===
        'Employed but on less than £17,374.50 per year'
    ) {
        form.getCheckBox('Check Box99').check()
    }

    if (res.data.data.declaration.prefContact1) form.getCheckBox('By post').check()
    if (res.data.data.declaration.prefContact2) form.getCheckBox('By phone').check()
    if (res.data.data.declaration.prefContact) form.getCheckBox('By email').check()
    if (res.data.data.declaration.aboutCourse)
        form.getCheckBox('About courses or learning opportunities').check()
    if (res.data.data.declaration.forSurveys)
        form.getCheckBox('For surveys and research').check()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file)
    //Open the URL on new Window
    window.open(fileURL)
}
export const HandlePDFChange3 = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const res = await authService.getTestForm(ids[i])
        const formPdfBytes = await fetch('/college2.pdf', { cache: 'reload' }).then((res) =>
            res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.data.data.signature //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text20')
        signImageField.setImage(emblemImage)

        const signImageField1 = form.getTextField('Text106')
        signImageField1.setImage(emblemImage)

        const signImageField2 = form.getTextField('Text112')
        signImageField2.setImage(emblemImage)

        // set field font
        form.getTextField('undefined_4').setFontSize(9)
        form.getTextField('undefined_14').setFontSize(9)

        // New Form Stuff
        if (res.data.data.personalDetails.title === 'Mr') {
            form.getCheckBox('Mrs').check()
        } else if (res.data.data.personalDetails.title === 'Mrs') {
            form.getCheckBox('Ms').check()
        } else if (res.data.data.personalDetails.title === 'Ms') {
            form.getCheckBox('Miss').check()
        } else if (res.data.data.personalDetails.title === 'Miss') {
            form.getCheckBox('Other').check()
        } else {
            form.getCheckBox('undefined').check()
        }
        form.getTextField('Text1').setText(res.data.data.personalDetails.lastName)
        form.getTextField('Text2').setText(res.data.data.personalDetails.firstName)
        let testDate = String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        form.getTextField('Text3').setText(testDate)
        form.getTextField('Text4').setText(res.data.data.personalDetails.age)

        var yearDOB = parseInt(String(moment(res.data.data.personalDetails.dob).year()))
        var monthDOB = parseInt(String(moment(res.data.data.personalDetails.dob).month()))
        var calculatedAge = 2021 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }
        // console.log('Age: ', calculatedAge)

        form.getTextField('Text5').setText(calculatedAge.toString())

        const ins = res.data.data.personalDetails.nationalInsNo
        const first = ins.substr(0, 2)
        const sec = ins.substr(2, 2)
        const third = ins.substr(4, 2)
        const fourth = ins.substr(6, 3)

        form.getTextField('Text6').setText(first)
        form.getTextField('Text7').setText(sec)
        form.getTextField('Text8').setText(third)
        form.getTextField('Text9').setText(fourth)

        if (res.data.data.personalDetails.gender === 'Female') {
            form.getCheckBox('undefined_2').check()
        } else {
            form.getCheckBox('Female').check()
        }

        form.getTextField('Text10').setText(res.data.data.personalDetails.addLine1)
        form.getTextField('Text11').setText(res.data.data.personalDetails.city)
        form.getTextField('Text12').setText(res.data.data.personalDetails.county)

        form.getTextField('Text13').setText(res.data.data.personalDetails.postcode)
        form.getTextField('Text14').setText(res.data.data.personalDetails.yearsAtAdd + ' years')

        form.getTextField('How long have you lived at this address').setText(
            (res.data.data.personalDetails.telephone.slice(0, 12))
        )
        form.getTextField('undefined_4').setText((res.data.data.personalDetails.telephone).slice(0, 12))

        form.getTextField('Text15').setText(res.data.data.personalDetails.email)
        form.getTextField('Text16').setText(res.data.data.emergencyDetails.emergencyContactName)
        form.getTextField('Text17').setText(
            res.data.data.emergencyDetails.relationshipToLearner
        )
        form.getTextField('Text18').setText(res.data.data.emergencyDetails.emergencyTelephone)

        //declaration
        if (res.data.data.declaration.houseHoldMem) {
            form.getCheckBox('No household member is in employment and the household').check()
        }
        if (res.data.data.declaration.houseHoldMem2) {
            form.getCheckBox('No household member is in employment and the household_2').check()
        }
        if (res.data.data.declaration.singleHouse) {
            form.getCheckBox('Learner lives in a single adult household with dependent').check()
        }
        if (res.data.data.declaration.noneOfThese) {
            form.getCheckBox('children').check()
        }
        if (res.data.data.declaration.confirmation)
            form.getCheckBox('I confirm that I wish to withhold this information').check()

        if (
            res.data.data.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getCheckBox('Check Box45').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box46').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box47').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box48').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box49').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box50').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box51').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Check Box52').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box53').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box54').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box55').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box56').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box57').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box58').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box59').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Check Box60').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box61').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box62').check()
        }

        if (res.data.data.oppDetails.firstLang === 'Yes') {
            form.getCheckBox('Check Box63').check()
        } else {
            form.getCheckBox('Check Box64').check()
        }

        if (res.data.data.oppDetails.resident === 'Yes') {
            form.getCheckBox('Check Box65').check()
        } else {
            form.getCheckBox('Check Box66').check()
        }

        form.getTextField('Text69').setText(res.data.data.oppDetails.nationality)
        if (res.data.data.oppDetails.nonEEACitizen === 'Yes') {
            form.getCheckBox('Check Box70').check()
        } else {
            form.getCheckBox('Check Box72').check()
        }

        if (res.data.data.oppDetails.statementScheme === 'Yes') {
            form.getCheckBox('Check Box67').check()
        } else {
            form.getCheckBox('Check Box68').check()
        }

        if (
            res.data.data.employmentDetails.employementStatus !== 'Unemployed, looking for work'
        ) {
            form.getTextField('Text73').setText(res.data.data.employmentDetails.employerName)
            form.getTextField('Text75').setText(res.data.data.employmentDetails.employerAdd)
            form.getTextField('Text30').setText(res.data.data.employmentDetails.hoursPerWeek)
            if (res.data.data.employmentDetails.postcode)
                form.getTextField('undefined_5').setText((res.data.data.employmentDetails.postcode).slice(0, 8))
            if (res.data.data.employmentDetails.ph)
                form.getTextField('undefined_6').setText((res.data.data.employmentDetails.ph).slice(0, 12))
            if (res.data.data.employmentDetails.length === 'Up to 3') {
                form.getCheckBox('Check Box76').check()
            } else if (res.data.data.employmentDetails.length === '4-6') {
                form.getCheckBox('Check Box77').check()
            } else if (res.data.data.employmentDetails.length === '7-12') {
                form.getCheckBox('Check Box78').check()
            } else {
                form.getCheckBox('Check Box79').check()
            }
        }

        if (res.data.data.oppDetails.disabilities === 'Yes') {
            if (res.data.data.oppDetails.dValue1) {
                form.getCheckBox('Check Box24').check()
            }
            if (res.data.data.oppDetails.dValue2) {
                form.getCheckBox('Check Box25').check()
            }
            if (res.data.data.oppDetails.dValue3) {
                form.getCheckBox('Check Box26').check()
            }
            if (res.data.data.oppDetails.dValue4) {
                form.getCheckBox('Check Box27').check()
            }
            if (res.data.data.oppDetails.dValue5) {
                form.getCheckBox('Check Box28').check()
            }
            if (res.data.data.oppDetails.dValue6) {
                form.getCheckBox('Check Box29').check()
            }
            if (res.data.data.oppDetails.dValue7) {
                form.getCheckBox('Check Box30').check()
            }
            if (res.data.data.oppDetails.dValue8) {
                form.getCheckBox('Check Box31').check()
            }
            if (res.data.data.oppDetails.dValue9) {
                form.getCheckBox('Check Box32').check()
            }
            if (res.data.data.oppDetails.dValue10) {
                form.getCheckBox('Check Box33').check()
            }
            if (res.data.data.oppDetails.dValue11) {
                form.getCheckBox('Check Box34').check()
            }
            if (res.data.data.oppDetails.dValue12) {
                form.getCheckBox('Check Box35').check()
            }
            if (res.data.data.oppDetails.dValue13) {
                form.getCheckBox('Check Box36').check()
            }
            if (res.data.data.oppDetails.dValue14) {
                form.getCheckBox('Check Box37').check()
            }
            if (res.data.data.oppDetails.dValue15) {
                form.getCheckBox('Check Box39').check()
            }
            if (res.data.data.oppDetails.dValue16) {
                form.getCheckBox('Check Box40').check()
            }
            if (res.data.data.oppDetails.dValue17) {
                form.getCheckBox('Check Box41').check()
            }
            if (res.data.data.oppDetails.dValue18) {
                form.getCheckBox('Check Box42').check()
            }
        }
        if (res.data.data.oppDetails.wheelchair === 'Yes') {
            form.getCheckBox('Check Box44').check()
        } else {
            form.getCheckBox('Check Box43').check()
        }

        if (res.data.data.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Check Box81').check()
        } else if (res.data.data.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box80').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Check Box82').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Check Box83').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Check Box85').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Check Box87').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Check Box88').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Check Box90').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Check Box91').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Check Box92').check()
        } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Check Box84').check()
        } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Check Box86').check()
        }

        form.getTextField('Text21').setText(res.data.data.date)
        form.getTextField('Text113').setText(res.data.data.date)

        form.getTextField('Text95').setText(res.data.data.qualificationDetails.englishGrades)
        form.getTextField('Text93').setText(res.data.data.qualificationDetails.mathGrades)
        form.getTextField(
            'Do you have any unspent criminal convictions or charges pending'
        ).setText(res.data.data.oppDetails.criminalConv)
        form.getTextField('Text117').setText(res.data.data.detailsFormData.appliedCourse)
        // form.getTextField("Text118").setText(res.data.data.date);
        form.getTextField('undefined_13').setText('ZZ99')
        form.getTextField('undefined_14').setText('9ZZ')

        if (
            res.data.data.employmentDetails.employementStatus ===
            'Unemployed, looking for work' ||
            res.data.data.employmentDetails.employementStatus ===
            'Employed but on less than £17,374.50 per year'
        ) {
            if (res.data.data.employmentDetails.dValue1) {
                form.getCheckBox('Check Box75').check()
            }
            if (res.data.data.employmentDetails.dValue2) {
                form.getCheckBox('Check Box95').check()
            }
            if (res.data.data.employmentDetails.dValue3) {
                form.getCheckBox('Check Box97').check()
            }
            if (res.data.data.employmentDetails.dValue5) {
                form.getCheckBox('Check Box89').check()
            }
            if (res.data.data.employmentDetails.dValue6) {
                form.getCheckBox('Check Box96').check()
            }
            if (res.data.data.employmentDetails.dValue7) {
                form.getCheckBox('Check Box98').check()
            }
            if (res.data.data.employmentDetails.dValue8) {
                form.getCheckBox('Check Box93').check()
            }
            if (res.data.data.employmentDetails.dValue9) {
                form.getCheckBox('Check Box99').check()
            }
            if (res.data.data.employmentDetails.dValue10 === 'Yes') {
                form.getCheckBox('Check Box94').check()
            }
        }

        if (
            res.data.data.employmentDetails.dValue2 ||
            res.data.data.employmentDetails.dValue1 ||
            res.data.data.employmentDetails.dValue3 ||
            res.data.data.employmentDetails.dValue5 ||
            res.data.data.employmentDetails.dValue6 ||
            res.data.data.employmentDetails.dValue7 ||
            res.data.data.employmentDetails.dValue8 ||
            res.data.data.employmentDetails.dValue9
        ) {
        } else {
            if (res.data.data.employmentDetails.dValue11) {
                form.getCheckBox('Check Box100').check()
            }
        }
        if (res.data.data.employmentDetails.unemployedLength === '0-5 months') {
            form.getCheckBox('Check Box101').check()
        } else if (res.data.data.employmentDetails.unemployedLength === '6-11 months') {
            form.getCheckBox('Check Box102').check()
        } else if (res.data.data.employmentDetails.unemployedLength === '12-23 months') {
            form.getCheckBox('Check Box103').check()
        } else if (res.data.data.employmentDetails.unemployedLength === '24-35 months') {
            form.getCheckBox('Check Box105').check()
        } else if (res.data.data.employmentDetails.unemployedLength === 'Over 36 months') {
            form.getCheckBox('Check Box104').check()
        }
        if (res.data.data.employmentDetails.areYou === 'Yes') {
            form.getCheckBox('Check Box74').check()
        }
        if (
            res.data.data.employmentDetails.employementStatus ===
            'Employed but on less than £17,374.50 per year'
        ) {
            form.getCheckBox('Check Box99').check()
        }

        if (res.data.data.declaration.prefContact1) form.getCheckBox('By post').check()
        if (res.data.data.declaration.prefContact2) form.getCheckBox('By phone').check()
        if (res.data.data.declaration.prefContact) form.getCheckBox('By email').check()
        if (res.data.data.declaration.aboutCourse)
            form.getCheckBox('About courses or learning opportunities').check()
        if (res.data.data.declaration.forSurveys)
            form.getCheckBox('For surveys and research').check()

        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        var images = [res.data.data.nationalId, res.data.data.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
        ).file(
            res.data.data.personalDetails.firstName +
            res.data.data.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.data.data.nationalId) {
            const imageBlob = fetch(res.data.data.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('National_ID.' + (res.data.data.nationalId).substr((res.data.data.nationalId).length - 3), imageBlob)
        }
        if (res.data.data.certificateLink) {
            const imageBlob = fetch(res.data.data.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Certificate.' + (res.data.data.certificateLink).substr((res.data.data.certificateLink).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment1) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment1.' + (res.data.data.oppDetails.attachment1).substr((res.data.data.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment2) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment2.' + (res.data.data.oppDetails.attachment2).substr((res.data.data.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment3) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment3.' + (res.data.data.oppDetails.attachment3).substr((res.data.data.oppDetails.attachment3).length - 3), imageBlob)
        }

        if (res.data.data.oppDetails.attachment4) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment4.' + (res.data.data.oppDetails.attachment4).substr((res.data.data.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence.' + (res.data.data.employmentDetails.evidence).substr((res.data.data.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence1) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence1.' + (res.data.data.employmentDetails.evidence1).substr((res.data.data.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence2) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence2.' + (res.data.data.employmentDetails.evidence2).substr((res.data.data.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.data.data.assessment) {
            if (res.data.data.assessment.english) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res.data.data),
                    { base64: true }
                )
            }
            if (res.data.data.assessment.answer1) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res.data.data),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}
// For DC group Colleges
export const HandlePDFChange5 = async (id) => {
    const res = await authService.getTestForm(id)
    const formPdfBytes = await fetch('/DCgroupCOllege.pdf', { cache: 'reload' }).then((res) =>
        res.arrayBuffer()
    )
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()
    const emblemUrl = res.data.data.signature //
    const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
    const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
    const signImageField = form.getTextField('Text20')
    signImageField.setImage(emblemImage)

    const signImageField1 = form.getTextField('Text106')
    signImageField1.setImage(emblemImage)

    const signImageField2 = form.getTextField('Text112')
    signImageField2.setImage(emblemImage)

    // set field font
    form.getTextField('undefined_4').setFontSize(9)
    form.getTextField('undefined_14').setFontSize(9)

    // New Form Stuff
    if (res.data.data.personalDetails.title === 'Mr') {
        form.getCheckBox('Mrs').check()
    } else if (res.data.data.personalDetails.title === 'Mrs') {
        form.getCheckBox('Ms').check()
    } else if (res.data.data.personalDetails.title === 'Ms') {
        form.getCheckBox('Miss').check()
    } else if (res.data.data.personalDetails.title === 'Miss') {
        form.getCheckBox('Other').check()
    } else {
        form.getCheckBox('undefined').check()
    }
    form.getTextField('Text1').setText(res.data.data.personalDetails.lastName)
    form.getTextField('Text2').setText(res.data.data.personalDetails.firstName)
    let testDate = String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
    form.getTextField('Text3').setText(testDate)
    form.getTextField('Text4').setText(res.data.data.personalDetails.age)

    var yearDOB = parseInt(String(moment(res.data.data.personalDetails.dob).year()))
    var monthDOB = parseInt(String(moment(res.data.data.personalDetails.dob).month()))
    var calculatedAge = 2021 - yearDOB
    monthDOB++
    if (monthDOB > 8) {
        calculatedAge--
    }
    // console.log('Age: ', calculatedAge)

    form.getTextField('Text5').setText(calculatedAge.toString())

    const ins = res.data.data.personalDetails.nationalInsNo
    const first = ins.substr(0, 2)
    const sec = ins.substr(2, 2)
    const third = ins.substr(4, 2)
    const fourth = ins.substr(6, 3)

    form.getTextField('Text6').setText(first)
    form.getTextField('Text7').setText(sec)
    form.getTextField('Text8').setText(third)
    form.getTextField('Text9').setText(fourth)

    if (res.data.data.personalDetails.gender === 'Female') {
        form.getCheckBox('undefined_2').check()
    } else {
        form.getCheckBox('Female').check()
    }

    form.getTextField('Text10').setText(res.data.data.personalDetails.addLine1)
    form.getTextField('Text11').setText(res.data.data.personalDetails.city)
    form.getTextField('Text12').setText(res.data.data.personalDetails.county)

    form.getTextField('Text13').setText(res.data.data.personalDetails.postcode)
    form.getTextField('Text14').setText(res.data.data.personalDetails.yearsAtAdd + ' years')

    form.getTextField('How long have you lived at this address').setText(
        res.data.data.personalDetails.telephone
    )
    form.getTextField('undefined_4').setText(res.data.data.personalDetails.telephone)

    form.getTextField('Text15').setText(res.data.data.personalDetails.email)
    form.getTextField('Text16').setText(res.data.data.emergencyDetails.emergencyContactName)
    form.getTextField('Text17').setText(res.data.data.emergencyDetails.relationshipToLearner)
    form.getTextField('Text18').setText(res.data.data.emergencyDetails.emergencyTelephone)

    //declaration
    if (res.data.data.declaration.houseHoldMem) {
        form.getCheckBox('No household member is in employment and the household').check()
    }
    if (res.data.data.declaration.houseHoldMem2) {
        form.getCheckBox('No household member is in employment and the household_2').check()
    }
    if (res.data.data.declaration.singleHouse) {
        form.getCheckBox('Learner lives in a single adult household with dependent').check()
    }
    if (res.data.data.declaration.noneOfThese) {
        form.getCheckBox('children').check()
    }
    if (res.data.data.declaration.confirmation)
        form.getCheckBox('I confirm that I wish to withhold this information').check()

    if (
        res.data.data.oppDetails.ethnicOrigin ===
        'English/Welsh/Scottish/Northern Irish/British'
    ) {
        form.getCheckBox('Check Box45').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
        form.getCheckBox('Check Box46').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
        form.getCheckBox('Check Box47').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
        form.getCheckBox('Check Box48').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
        form.getCheckBox('Check Box49').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
        form.getCheckBox('Check Box50').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
        form.getCheckBox('Check Box51').check()
    } else if (
        res.data.data.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background'
    ) {
        form.getCheckBox('Check Box52').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
        form.getCheckBox('Check Box53').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
        form.getCheckBox('Check Box54').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
        form.getCheckBox('Check Box55').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
        form.getCheckBox('Check Box56').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
        form.getCheckBox('Check Box57').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
        form.getCheckBox('Check Box58').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
        form.getCheckBox('Check Box59').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
        form.getCheckBox('Check Box60').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
        form.getCheckBox('Check Box61').check()
    } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
        form.getCheckBox('Check Box62').check()
    }

    if (res.data.data.oppDetails.firstLang === 'Yes') {
        form.getCheckBox('Check Box63').check()
    } else {
        form.getCheckBox('Check Box64').check()
    }

    if (res.data.data.oppDetails.resident === 'Yes') {
        form.getCheckBox('Check Box65').check()
    } else {
        form.getCheckBox('Check Box66').check()
    }

    form.getTextField('Text69').setText(res.data.data.oppDetails.nationality)
    if (res.data.data.oppDetails.nonEEACitizen === 'Yes') {
        form.getCheckBox('Check Box70').check()
    } else {
        form.getCheckBox('Check Box72').check()
    }

    if (res.data.data.oppDetails.statementScheme === 'Yes') {
        form.getCheckBox('Check Box67').check()
    } else {
        form.getCheckBox('Check Box68').check()
    }

    if (res.data.data.employmentDetails.employementStatus !== 'Unemployed, looking for work') {
        form.getTextField('Text73').setText(res.data.data.employmentDetails.employerName)
        form.getTextField('Text75').setText(res.data.data.employmentDetails.employerAdd)
        form.getTextField('Text30').setText(res.data.data.employmentDetails.hoursPerWeek)
        if (res.data.data.employmentDetails.postcode)
            form.getTextField('undefined_5').setText((res.data.data.employmentDetails.postcode).slice(0, 8))
        form.getTextField('undefined_6').setText(res.data.data.employmentDetails.ph)
        if (res.data.data.employmentDetails.length === 'Up to 3') {
            form.getCheckBox('Check Box76').check()
        } else if (res.data.data.employmentDetails.length === '4-6') {
            form.getCheckBox('Check Box77').check()
        } else if (res.data.data.employmentDetails.length === '7-12') {
            form.getCheckBox('Check Box78').check()
        } else {
            form.getCheckBox('Check Box79').check()
        }
    }

    if (res.data.data.oppDetails.disabilities === 'Yes') {
        if (res.data.data.oppDetails.dValue1) {
            form.getCheckBox('Check Box24').check()
        }
        if (res.data.data.oppDetails.dValue2) {
            form.getCheckBox('Check Box25').check()
        }
        if (res.data.data.oppDetails.dValue3) {
            form.getCheckBox('Check Box26').check()
        }
        if (res.data.data.oppDetails.dValue4) {
            form.getCheckBox('Check Box27').check()
        }
        if (res.data.data.oppDetails.dValue5) {
            form.getCheckBox('Check Box28').check()
        }
        if (res.data.data.oppDetails.dValue6) {
            form.getCheckBox('Check Box29').check()
        }
        if (res.data.data.oppDetails.dValue7) {
            form.getCheckBox('Check Box30').check()
        }
        if (res.data.data.oppDetails.dValue8) {
            form.getCheckBox('Check Box31').check()
        }
        if (res.data.data.oppDetails.dValue9) {
            form.getCheckBox('Check Box32').check()
        }
        if (res.data.data.oppDetails.dValue10) {
            form.getCheckBox('Check Box33').check()
        }
        if (res.data.data.oppDetails.dValue11) {
            form.getCheckBox('Check Box34').check()
        }
        if (res.data.data.oppDetails.dValue12) {
            form.getCheckBox('Check Box35').check()
        }
        if (res.data.data.oppDetails.dValue13) {
            form.getCheckBox('Check Box36').check()
        }
        if (res.data.data.oppDetails.dValue14) {
            form.getCheckBox('Check Box37').check()
        }
        if (res.data.data.oppDetails.dValue15) {
            form.getCheckBox('Check Box39').check()
        }
        if (res.data.data.oppDetails.dValue16) {
            form.getCheckBox('Check Box40').check()
        }
        if (res.data.data.oppDetails.dValue17) {
            form.getCheckBox('Check Box41').check()
        }
        if (res.data.data.oppDetails.dValue18) {
            form.getCheckBox('Check Box42').check()
        }
    }
    if (res.data.data.oppDetails.wheelchair === 'Yes') {
        form.getCheckBox('Check Box44').check()
    } else {
        form.getCheckBox('Check Box43').check()
    }

    if (res.data.data.qualificationDetails.level === 'Entry Level') {
        form.getCheckBox('Check Box81').check()
    } else if (res.data.data.qualificationDetails.level === 'None') {
        form.getCheckBox('Check Box80').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 1') {
        form.getCheckBox('Check Box82').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 2') {
        form.getCheckBox('Check Box83').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 3') {
        form.getCheckBox('Check Box85').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 4') {
        form.getCheckBox('Check Box87').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 5') {
        form.getCheckBox('Check Box88').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 6') {
        form.getCheckBox('Check Box90').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 7') {
        form.getCheckBox('Check Box91').check()
    } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
        form.getCheckBox('Check Box92').check()
    } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
        form.getCheckBox('Check Box84').check()
    } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
        form.getCheckBox('Check Box86').check()
    }

    form.getTextField('Text21').setText(res.data.data.date)
    form.getTextField('Text113').setText(res.data.data.date)

    form.getTextField('Text95').setText(res.data.data.qualificationDetails.englishGrades)
    form.getTextField('Text93').setText(res.data.data.qualificationDetails.mathGrades)
    form.getTextField(
        'Do you have any unspent criminal convictions or charges pending'
    ).setText(res.data.data.oppDetails.criminalConv)
    form.getTextField('Text117').setText(res.data.data.detailsFormData.appliedCourse)
    // form.getTextField("Text118").setText(res.data.data.date);
    form.getTextField('undefined_13').setText('ZZ99')
    form.getTextField('undefined_14').setText('9ZZ')

    if (res.data.data.employmentDetails.employementStatus === 'Unemployed, looking for work') {
        if (res.data.data.employmentDetails.dValue1) {
            form.getCheckBox('Check Box75').check()
        }
        if (res.data.data.employmentDetails.dValue2) {
            form.getCheckBox('Check Box95').check()
        }
        if (res.data.data.employmentDetails.dValue3) {
            form.getCheckBox('Check Box97').check()
        }
        if (res.data.data.employmentDetails.dValue5) {
            form.getCheckBox('Check Box89').check()
        }
        if (res.data.data.employmentDetails.dValue6) {
            form.getCheckBox('Check Box96').check()
        }
        if (res.data.data.employmentDetails.dValue7) {
            form.getCheckBox('Check Box98').check()
        }
        if (res.data.data.employmentDetails.dValue8) {
            form.getCheckBox('Check Box93').check()
        }
        if (res.data.data.employmentDetails.dValue9) {
            form.getCheckBox('Check Box99').check()
        }
        if (res.data.data.employmentDetails.dValue10 === 'Yes') {
            form.getCheckBox('Check Box94').check()
        }
    }
    if (
        res.data.data.employmentDetails.dValue2 ||
        res.data.data.employmentDetails.dValue1 ||
        res.data.data.employmentDetails.dValue3 ||
        res.data.data.employmentDetails.dValue5 ||
        res.data.data.employmentDetails.dValue6 ||
        res.data.data.employmentDetails.dValue7 ||
        res.data.data.employmentDetails.dValue8 ||
        res.data.data.employmentDetails.dValue9 ||
        res.data.data.employmentDetails.dValue10
    ) {
    } else {
        if (res.data.data.employmentDetails.dValue11) {
            form.getCheckBox('Check Box100').check()
        }
    }

    if (res.data.data.employmentDetails.unemployedLength === '0-5 months') {
        form.getCheckBox('Check Box101').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '6-11 months') {
        form.getCheckBox('Check Box102').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '12-23 months') {
        form.getCheckBox('Check Box103').check()
    } else if (res.data.data.employmentDetails.unemployedLength === '24-35 months') {
        form.getCheckBox('Check Box105').check()
    } else if (res.data.data.employmentDetails.unemployedLength === 'Over 36 months') {
        form.getCheckBox('Check Box104').check()
    }
    if (res.data.data.employmentDetails.areYou === 'Yes') {
        form.getCheckBox('Check Box74').check()
    }
    if (
        res.data.data.employmentDetails.employementStatus ===
        'Employed but on less than £17,374.50 per year'
    ) {
        form.getCheckBox('Check Box99').check()
    }

    if (res.data.data.declaration.prefContact1) form.getCheckBox('By post').check()
    if (res.data.data.declaration.prefContact2) form.getCheckBox('By phone').check()
    if (res.data.data.declaration.prefContact) form.getCheckBox('By email').check()
    if (res.data.data.declaration.aboutCourse)
        form.getCheckBox('About courses or learning opportunities').check()
    if (res.data.data.declaration.forSurveys)
        form.getCheckBox('For surveys and research').check()

    const pdfBytes = await pdfDoc.save()

    const file = new Blob([pdfBytes], {
        type: 'application/pdf',
    })

    convertFileToBase64(file).then((res) => {
        const { fileName, base64 } = res
    })
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file)
    //Open the URL on new Window
    window.open(fileURL)
}

export const HandlePDFChange4 = async (ids) => {
    let zip = new JSZip()

    for (let i = 0; i < ids.length; i++) {

        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/DCgroupCOllege.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text20')
        signImageField.setImage(emblemImage)

        // New Form Stuff
        if (res.personalDetails.title === 'Mr') {
            form.getCheckBox('Check Box 8').check()
        } else if (res.personalDetails.title === 'Mrs') {
            form.getCheckBox('Check Box 9').check()
        } else if (res.personalDetails.title === 'Ms') {
            form.getCheckBox('Check Box 11').check()
        } else if (res.personalDetails.title === 'Miss') {
            form.getCheckBox('Check Box 10').check()
        }


        form.getTextField('Text Field 28').setText(res.personalDetails.lastName)
        form.getTextField('Text Field 30').setText(res.personalDetails.firstName)
        let testDate = String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        form.getTextField('Text Field 32').setText(testDate.replaceAll('/', ''))

        let yearDOB = parseInt(String(moment(res.personalDetails.dob).year()))
        let monthDOB = parseInt(String(moment(res.personalDetails.dob).month()))
        let calculatedAge = 2023 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }

        let dobDate = String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))


        form.getTextField('Text Field 40').setText(calculatedAge.toString())


        form.getTextField('Text Field 41').setText(res.personalDetails.nationalInsNo)

        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('Check Box 13').check()
        } else {
            form.getCheckBox('Check Box 12').check()
        }

        form.getTextField('Text Field 50').setText(res.personalDetails.addLine1)
        form.getTextField('Text Field 51').setText(res.personalDetails.city + ' ' + res.personalDetails.county)

        form.getTextField('Text Field 52').setText(res.personalDetails.postcode)

        form.getTextField('Text Field 53').setText(
            res.personalDetails.telephone
        )
        form.getTextField('Text Field 54').setText(res.personalDetails.telephone)

        form.getTextField('Text Field 55').setText(res.personalDetails.email)
        form.getTextField('Text Field 56').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text Field 57').setText(
            res.emergencyDetails.relationshipToLearner
        )
        form.getTextField('Text Field 58').setText(res.emergencyDetails.emergencyTelephone)


        if (
            res.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getCheckBox('Check Box45').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box46').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box47').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box48').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box49').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box50').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box51').check()
        } else if (
            res.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Check Box52').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box53').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box54').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box55').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box56').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box57').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box58').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box59').check()
        } else if (
            res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Check Box60').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box61').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box62').check()
        }

        form.getTextField('Text Field 60').setText(res.oppDetails.nationality)
        form.getTextField('Text Field 61').setText(res.oppDetails.inWhichCountryDoYouLive)

        if (res.oppDetails.statementScheme === 'Yes') {
            form.getCheckBox('Check Box 139').check()
        } else {
            form.getCheckBox('Check Box 140').check()
            form.getTextField('Text Field 62').setText(res.oppDetails.perField)
        }

        if (res.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('Check Box 16').check()

            if (res.oppDetails.dValue1) {
                form.getCheckBox('Check Box 19').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('Check Box 20').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('Check Box 21').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('Check Box 22').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('Check Box 23').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('Check Box 24').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('Check Box 25').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('Check Box 26').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('Check Box 27').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('Check Box 28').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('Check Box 29').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('Check Box 30').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('Check Box 31').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('Check Box 32').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('Check Box 33').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('Check Box 34').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('Check Box 36').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('Check Box 37').check()
            }
        } else {
            form.getCheckBox('Check Box 17').check()
        }

        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('Check Box 56').check()
        } else {
            form.getCheckBox('Check Box 57').check()
        }


        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Check Box 62').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box 72').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Check Box 63').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Check Box 64').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Check Box 66').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Check Box 68').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Check Box 69').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Check Box 70').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Check Box 71').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Check Box 71').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Check Box 65').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Check Box 67').check()
        }



        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("Check Box 73").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Check Box 74").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("Check Box 75").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("Check Box 76").check()
            }


        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("Check Box 78").check()
            } else {
                form.getCheckBox("Check Box 79").check()
            }


            if (res.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("Check Box 81").check()
            } else if (res.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("Check Box 82").check()
            } else if (res.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("Check Box 83").check()
            } else if (res.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("Check Box 84").check()
            } else if (res.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("Check Box 85").check()
            }

            if (res.employmentDetails.dValue1) {
                form.getCheckBox('Check Box 86').check()
            }

            if (res.employmentDetails.dValue5) {
                form.getCheckBox('Check Box 86').check()
            }

            if (res.employmentDetails.dValue8) {
                form.getCheckBox('Check Box 88').check()
            }

            if (res.employmentDetails.dValue2 ||
                res.employmentDetails.dValue3 ||
                res.employmentDetails.dValue6 ||
                res.employmentDetails.dValue7 ||
                res.employmentDetails.dValue9) {
                form.getCheckBox('Check Box 89').check()
            }
        }

        let signDate = String(moment(res.createdAt).format('DD/MM/YYYY'))


        form.getTextField('Text Field 79').setText(signDate.replaceAll('/', ''))

        form.getTextField('Text Field 96').setText(res.detailsFormData.appliedCourse)

        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        // convertFileToBase64(file).then((res: FileBase64) => {
        //     const { fileName, base64 } = res
        // })

        let images = [ensureHttps(res.nationalId), ensureHttps(res.certificateLink)],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + ((res.nationalId)).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + ((res.certificateLink)).substr(((res.certificateLink)).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}

export const handleNewSwindonClg = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const res = await authService.getTestForm(ids[i])
        const formPdfBytes = await fetch('/newSwindon.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.data.data.signature
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

        let date = (String(moment(res.data.data.createdAt).format('DD/MM/YYYY'))).replaceAll('/', '')
        form.getTextField('Text32').setText(date[0])
        form.getTextField('Text34').setText(date[1])
        form.getTextField('Text35').setText(date[2])
        form.getTextField('Text36').setText(date[3])
        form.getTextField('Text37').setText(date[6])
        form.getTextField('Text38').setText(date[7])

        const signImageField = form.getTextField('Student Signature')
        signImageField.setImage(emblemImage)

        const signImageField1 = form.getTextField('Student Signature_2')
        signImageField1.setImage(emblemImage)

        form.getTextField('Text14').setText(date[0])
        form.getTextField('Text15').setText(date[1])
        form.getTextField('Text16').setText(date[2])
        form.getTextField('Text17').setText(date[3])
        form.getTextField('Text18').setText(date[6])
        form.getTextField('Text19').setText(date[7])


        // set field font
        form.getTextField('undefined').setFontSize(9)
        form.getTextField('Postcode').setFontSize(9)
        form.getTextField('Home Telephone').setFontSize(9)


        form.getTextField('Course Name  LevelRow1').setText(res.data.data.detailsFormData.appliedCourse)
        form.getTextField('Delivery PostcodeRow1').setText('ZZ99 9ZZ')

        form.getTextField('Title MrMrsMissMsDr').setText(res.data.data.personalDetails.title)
        form.getTextField('Surname Family Name').setText(res.data.data.personalDetails.lastName)
        form.getTextField('First Name').setText(res.data.data.personalDetails.firstName)
        let testDate = (String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))).replaceAll('/', '')
        form.getTextField('D1').setText(testDate[0])
        form.getTextField('D2').setText(testDate[1])
        form.getTextField('M2').setText(testDate[2])
        form.getTextField('M1').setText(testDate[3])
        form.getTextField('Y2').setText(testDate[6])
        form.getTextField('Y1').setText(testDate[7])


        if (res.data.data.personalDetails.gender === 'Female') {
            form.getCheckBox('CheckBox-female').check()
        } else {
            form.getCheckBox('CheckBox-male').check()
        }


        var yearDOB = parseInt(String(moment(res.data.data.personalDetails.dob).year()))
        var monthDOB = parseInt(String(moment(res.data.data.personalDetails.dob).month()))
        var calculatedAge = 2022 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }
        // console.log('Age: ', calculatedAge)

        form.getTextField('Age on 31 Aug 2022').setText(calculatedAge.toString())


        form.getTextField('if in receipt of benefits').setText(res.data.data.personalDetails.nationalInsNo)

        form.getTextField('Home Address 1').setText(res.data.data.personalDetails.addLine1 + ", " + res.data.data.personalDetails.city + ", " + res.data.data.personalDetails.county)
        if (res.data.data.personalDetails.postcode.length === 6) {
            form.getTextField('Postcode').setText(res.data.data.personalDetails.postcode.slice(0, 3))
            form.getTextField('undefined').setText(res.data.data.personalDetails.postcode.slice(3))
        } else {
            form.getTextField('Postcode').setText(res.data.data.personalDetails.postcode.slice(0, 4))
            form.getTextField('undefined').setText(res.data.data.personalDetails.postcode.slice(4))

        }
        if (res.data.data.personalDetails.oldPostcode) {
            form.getTextField('Home postcode').setText(res.data.data.personalDetails.oldPostcode.slice(0, 4))
            form.getTextField('undefined_2').setText(res.data.data.personalDetails.oldPostcode.slice(4))
        }

        form.getTextField('Home Telephone').setText(res.data.data.personalDetails.telephone)
        form.getTextField('Mob Number').setText(res.data.data.personalDetails.telephone)
        form.getTextField('Email Address').setText(res.data.data.personalDetails.email)

        form.getTextField('Nationality').setText(res.data.data.oppDetails.nationality)
        form.getCheckBox("CheckBox-havelivedfor 3 years").check()
        // form.getTextField('How long did you live there for').setText(res.data.data.personalDetails.yearsAtAdd + ' years')

        if (
            res.data.data.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getCheckBox('EnglishWelshScottish').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Irish 32').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Gypsy or Irish Traveller 33').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Any other white').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('White  Black Caribbean 35').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('White  Black African 36').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('White  Asian 37').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Any other mixedmultiple').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Indian 39').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Pakistani 40').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Bangladeshi 41').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Chinese 42').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Any other Asian').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('African 44').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Caribbean 45').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Any other Black African').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Arab 47').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Any other ethnic group 98').check()
        }

        if (res.data.data.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('CheckBox-disable-yes').check()

            if (res.data.data.oppDetails.dValue1) {
                form.getCheckBox('Visual impairment').check()
            }
            if (res.data.data.oppDetails.dValue2) {
                form.getCheckBox('Hearing impairment').check()
            }
            if (res.data.data.oppDetails.dValue3) {
                form.getCheckBox('Disability affecting mobility').check()
            }
            if (res.data.data.oppDetails.dValue4) {
                form.getCheckBox('Profound complex disabilities').check()
            }
            if (res.data.data.oppDetails.dValue5) {
                form.getCheckBox('Social and Emotional Difficulties').check()
            }
            if (res.data.data.oppDetails.dValue6) {
                form.getCheckBox('Mental health difficulty').check()
            }
            if (res.data.data.oppDetails.dValue7) {
                form.getCheckBox('Moderate difficulties').check()
            }
            if (res.data.data.oppDetails.dValue8) {
                form.getCheckBox('Severe difficulties').check()
            }
            if (res.data.data.oppDetails.dValue9) {
                form.getCheckBox('Dyslexia').check()
            }
            if (res.data.data.oppDetails.dValue10) {
                form.getCheckBox('Dyscalculia').check()
            }
            if (res.data.data.oppDetails.dValue11) {
                form.getCheckBox('Autism spectrum disorder').check()
            }
            if (res.data.data.oppDetails.dValue12) {
                form.getCheckBox('Aspergers Syndrome').check()
            }
            if (res.data.data.oppDetails.dValue13) {
                form.getCheckBox('Temporary disability after illness').check()
            }
            if (res.data.data.oppDetails.dValue14) {
                form.getCheckBox('Speech Language and').check()
            }
            if (res.data.data.oppDetails.dValue15) {
                form.getCheckBox('Other physical disability').check()
            }
            if (res.data.data.oppDetails.dValue16) {
                form.getCheckBox('Other Medical condition').check()
            }
            if (res.data.data.oppDetails.dValue17) {
                form.getCheckBox('Other_2').check()
            }
            if (res.data.data.oppDetails.dValue18) {
                form.getCheckBox('Other').check()
            }
        } else {
            form.getCheckBox('CheckBox-disable-no').check()
        }
        if (res.data.data.oppDetails.wheelchair === 'Yes') {
            form.getTextField('Please state further details').setText("WheelChair")
        }


        if (res.data.data.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Entry Level eg E1 E2 E3').check()
        } else if (res.data.data.qualificationDetails.level === 'None') {
            form.getCheckBox('No formal qualifications').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Level 1 5 or more GCSE grades 3 and below OR DG').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Level 2  not Full Level 2 see Full Level 2 box to know what Full Level 2 is').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Level 3 not Full Level 3 see Full Level 3 box to know what Full Level 3 is').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Level 4 HNC NVQ 4').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Level 5 Foundation Degree HND NVQ 5').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Level 6 AwardCertDip').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Level 7  Degree Postgraduate degree').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Level 7  Degree Postgraduate degree').check()
        } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Full Level 2 5 GCSEs 4 and above OR AC 2 AS Levels 1').check()
        } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Full Level 3 2 or more Alevels Certificate Diploma').check()
        }
        if (res.data.data.qualificationDetails.englishGrades) {
            form.getTextField('SubjectRow1').setText("English Grades")
            form.getTextField('Grade if completedRow1').setText(res.data.data.qualificationDetails.englishGrades)
        }
        if (res.data.data.qualificationDetails.mathGrades) {
            form.getTextField('SubjectRow2').setText("Maths Grades")
            form.getTextField('Grade if completedRow2').setText(res.data.data.qualificationDetails.mathGrades)
        }
        if (res.data.data.qualificationDetails.GCSEEnglishabove) {
            form.getCheckBox('GCSE English Grade 4C and above').check()
        }
        if (res.data.data.qualificationDetails.GCSEMathsabove) {
            form.getCheckBox('GCSE Maths Grade 4C and above').check()
        }


        if (res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year") {
            form.getCheckBox("OR").check()
        }
        if (res.data.data.employmentDetails.dValue5) {
            form.getCheckBox('Employment Support Allowance ESA').check()
        }
        if (res.data.data.employmentDetails.dValue8) {
            form.getCheckBox('Universal Credit AND unemployedmy earnings from employment is less than 345 individual claim OR earn less than 552 per month household claim').check()
        }


        if (res.data.data.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.data.data.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox("Employed").check()
            if (res.data.data.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("0 to 10 hours per week").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Between 11 to 20 hours").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("21 to 30 hours").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("31 hours").check()
            }

            if (res.data.data.employmentDetails.length === "Up to 3") {
                form.getCheckBox("Up to 3 months").check()
            } else if (res.data.data.employmentDetails.length === "4-6") {
                form.getCheckBox("4  6 months_2").check()
            } else if (res.data.data.employmentDetails.length === "7-12") {
                form.getCheckBox("7  12 months_2").check()
            } else if (res.data.data.employmentDetails.length === "12+") {
                form.getCheckBox("More than 12 months").check()
            }

        } else if (res.data.data.employmentDetails.employementStatus === "Unemployed, looking for work") {
            form.getCheckBox("Not in paid employment").check()

            if (res.data.data.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("Yes_12").check()
            } else if (res.data.data.employmentDetails.lookingForWork === "No") {
                form.getCheckBox("No_12").check()
            }

            if (res.data.data.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("Less than 6 months").check()
            } else if (res.data.data.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("6  11 months").check()
            } else if (res.data.data.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("12  23 months").check()
            } else if (res.data.data.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("24  35 months").check()
            } else if (res.data.data.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("36 months or over").check()
            }

            if (res.data.data.employmentDetails.dValue1) {
                form.getCheckBox('JSA').check()
            }

            if (res.data.data.employmentDetails.dValue5) {
                form.getCheckBox('ESA').check()
            }

            if (res.data.data.employmentDetails.dValue8) {
                form.getCheckBox('UC').check()
            }
            if (res.data.data.employmentDetails.dValue2 ||
                res.data.data.employmentDetails.dValue3 ||
                res.data.data.employmentDetails.dValue6 ||
                res.data.data.employmentDetails.dValue7 ||
                res.data.data.employmentDetails.dValue9) {
                form.getCheckBox('Other benefits').check()
            }

        }

        //declaration
        if (res.data.data.declaration.houseHoldMem) {
            form.getCheckBox('No household member is in employment with no dependent children').check()
        }
        if (res.data.data.declaration.houseHoldMem2) {
            form.getCheckBox('No household member is in employment with dependent children').check()
        }
        if (res.data.data.declaration.singleHouse) {
            form.getCheckBox('Learner lives in a single adult household with dependent children').check()
        }
        if (res.data.data.declaration.noneOfThese) {
            form.getCheckBox('None of these statements apply').check()
        }
        if (res.data.data.declaration.confirmation)
            form.getCheckBox('I confirm that I wish to withhold this information').check()


        // if (res.data.data.declaration.aboutCourse)
        // 	form.getCheckBox('Yes I am happy for New College to email me regarding courses and initiatives').check()
        // if (res.data.data.declaration.forSurveys)
        // 	form.getCheckBox('Yes I am happy for New College to email me for event evaluations and research purposes').check()
        form.getCheckBox('No I do not want to receive any further marketing information from New College').check()
        form.getCheckBox('Other Website').check()
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        var images = [res.data.data.nationalId, res.data.data.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
        ).file(
            res.data.data.personalDetails.firstName +
            res.data.data.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.data.data.nationalId) {
            const imageBlob = fetch(res.data.data.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('National_ID.' + (res.data.data.nationalId).substr((res.data.data.nationalId).length - 3), imageBlob)
        }
        if (res.data.data.certificateLink) {
            const imageBlob = fetch(res.data.data.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Certificate.' + (res.data.data.certificateLink).substr((res.data.data.certificateLink).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment1) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment1.' + (res.data.data.oppDetails.attachment1).substr((res.data.data.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment2) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment2.' + (res.data.data.oppDetails.attachment2).substr((res.data.data.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment3) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment3.' + (res.data.data.oppDetails.attachment3).substr((res.data.data.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment4) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment4.' + (res.data.data.oppDetails.attachment4).substr((res.data.data.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence.' + (res.data.data.employmentDetails.evidence).substr((res.data.data.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence1) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence1.' + (res.data.data.employmentDetails.evidence1).substr((res.data.data.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence2) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence2.' + (res.data.data.employmentDetails.evidence2).substr((res.data.data.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.data.data.newDeclaration) {
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res.data.data),
                { base64: true }
            )
        }
        if (res.data.data.assessment) {
            if (res.data.data.assessment.english) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res.data.data),
                    { base64: true }
                )
            }
            if (res.data.data.assessment.answer1) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res.data.data),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


export const handleRnnClg = async (ids) => {
    let zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/rnnform.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature)
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', '')
        form.getTextField('Text Field 20111').setText(date[0])
        form.getTextField('Text Field 20112').setText(date[1])
        form.getTextField('Text Field 20113').setText(date[2])
        form.getTextField('Text Field 20114').setText(date[3])
        form.getTextField('Text Field 20115').setText(date[4])
        form.getTextField('Text Field 20116').setText(date[5])
        form.getTextField('Text Field 20117').setText(date[6])
        form.getTextField('Text Field 20118').setText(date[7])

        const signImageField = form.getTextField('Text Field 20110')
        signImageField.setImage(emblemImage)


        form.getTextField('Text-fullname').setText(res.personalDetails.firstName + ' ' + res.personalDetails.lastName)

        // set field font
        // form.getTextField('undefined').setFontSize(9)
        // form.getTextField('Postcode').setFontSize(9)
        // form.getTextField('Home Telephone').setFontSize(9)


        form.getTextField('Text Field 20119').setText(res.detailsFormData.appliedCourse)
        if (res.personalDetails.title === "Mr") {
            form.getCheckBox("Mr").check()
        } else if (res.personalDetails.title === "Miss") {
            form.getCheckBox("Miss").check()
        } else if (res.personalDetails.title === "Mrs") {
            form.getCheckBox("Mrs ").check()
        } else if (res.personalDetails.title === 'Ms') {
            form.getCheckBox('Ms').check()
        } else if (res.personalDetails.title === "other") {
            form.getTextField("Other").setText("Other")
        }

        form.getTextField('Surname').setText(res.personalDetails.lastName)
        form.getTextField('First Name ').setText(res.personalDetails.firstName)

        form.getTextField('Text Field 131').setText(res.personalDetails.nationalInsNo[0])
        form.getTextField('Text Field 132').setText(res.personalDetails.nationalInsNo[1])
        form.getTextField('Text Field 133').setText(res.personalDetails.nationalInsNo[2])
        form.getTextField('Text Field 134').setText(res.personalDetails.nationalInsNo[3])
        form.getTextField('Text Field 135').setText(res.personalDetails.nationalInsNo[4])
        form.getTextField('Text Field 136').setText(res.personalDetails.nationalInsNo[5])
        form.getTextField('Text Field 137').setText(res.personalDetails.nationalInsNo[6])
        form.getTextField('Text Field 138').setText(res.personalDetails.nationalInsNo[7])
        form.getTextField('Text Field 139').setText(res.personalDetails.nationalInsNo[8])


        let testDate = (String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))).replaceAll('/', '')
        form.getTextField('Text Field 154').setText(testDate[0])
        form.getTextField('Text Field 155').setText(testDate[1])
        form.getTextField('Text Field 156').setText(testDate[2])
        form.getTextField('Text Field 157').setText(testDate[3])
        form.getTextField('Text Field 158').setText(testDate[4])
        form.getTextField('Text Field 159').setText(testDate[5])
        form.getTextField('Text Field 160').setText(testDate[6])
        form.getTextField('Text Field 161').setText(testDate[7])
        form.getTextField('Text Field 166').setText(res.personalDetails.addLine1 + ", " + res.personalDetails.city + ", " + res.personalDetails.county)

        form.getTextField('Text Field 167').setText(res.personalDetails.postcode[0])
        form.getTextField('Text Field 168').setText(res.personalDetails.postcode[1])
        form.getTextField('Text Field 169').setText(res.personalDetails.postcode[2])
        form.getTextField('Text Field 170').setText(res.personalDetails.postcode[3])
        form.getTextField('Text Field 171').setText(res.personalDetails.postcode[4])
        form.getTextField('Text Field 172').setText(res.personalDetails.postcode[5])
        form.getTextField('Text Field 173').setText(res.personalDetails.postcode[6])
        form.getTextField('Text Field 174').setText(res.personalDetails.postcode[7])

        form.getTextField('Text Field 175').setText(res.personalDetails.yearsAtAdd + ' years')
        form.getTextField('Text Field 176').setText(res.personalDetails.telephone[0])
        form.getTextField('Text Field 177').setText(res.personalDetails.telephone[1])
        form.getTextField('Text Field 178').setText(res.personalDetails.telephone[2])
        form.getTextField('Text Field 179').setText(res.personalDetails.telephone[3])
        form.getTextField('Text Field 180').setText(res.personalDetails.telephone[4])
        form.getTextField('Text Field 181').setText(res.personalDetails.telephone[5])
        form.getTextField('Text Field 182').setText(res.personalDetails.telephone[6])
        form.getTextField('Text Field 183').setText(res.personalDetails.telephone[7])
        form.getTextField('Text Field 184').setText(res.personalDetails.telephone[8])
        form.getTextField('Text Field 185').setText(res.personalDetails.telephone[9])
        form.getTextField('Text Field 186').setText(res.personalDetails.telephone[10])

        form.getTextField('Text Field 187').setText(res.personalDetails.email)
        form.getTextField('Text Field 188').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text Field 201').setText(res.emergencyDetails.relationshipToLearner)
        form.getTextField('Text Field 189').setText(res.emergencyDetails.emergencyTelephone[0])
        form.getTextField('Text Field 190').setText(res.emergencyDetails.emergencyTelephone[1])
        form.getTextField('Text Field 191').setText(res.emergencyDetails.emergencyTelephone[2])
        form.getTextField('Text Field 193').setText(res.emergencyDetails.emergencyTelephone[3])
        form.getTextField('Text Field 194').setText(res.emergencyDetails.emergencyTelephone[4])
        form.getTextField('Text Field 195').setText(res.emergencyDetails.emergencyTelephone[5])
        form.getTextField('Text Field 196').setText(res.emergencyDetails.emergencyTelephone[6])
        form.getTextField('Text Field 197').setText(res.emergencyDetails.emergencyTelephone[7])
        form.getTextField('Text Field 198').setText(res.emergencyDetails.emergencyTelephone[8])
        form.getTextField('Text Field 199').setText(res.emergencyDetails.emergencyTelephone[9])
        form.getTextField('Text Field 200').setText(res.emergencyDetails.emergencyTelephone[10])

        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox("Check Box 2").check()
            if (res.employmentDetails.employmentType === 'Self Employed') {
                form.getCheckBox("Check Box 6").check()
            }
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("Check Box 7").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Check Box 8").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("Check Box 10").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("Check Box 16").check()
            }

            if (res.employmentDetails.length === "Up to 3") {
                form.getCheckBox("Check Box 11").check()
            } else if (res.employmentDetails.length === "4-6") {
                form.getCheckBox("Check Box 12").check()
            } else if (res.employmentDetails.length === "7-12") {
                form.getCheckBox("Check Box 13").check()
            } else if (res.employmentDetails.length === "12+") {
                form.getCheckBox("Check Box 14").check()
            }

        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.employmentDetails.lookingForWork === "No") {
                form.getCheckBox("Check Box 3").check()
            } else {
                form.getCheckBox("Check Box 5").check()
            }

            if (res.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("Check Box 18").check()
            } else if (res.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("Check Box 19").check()
            } else if (res.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("Check Box 20").check()
            } else if (res.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("Check Box 21").check()
            } else if (res.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("Check Box 22").check()
            }

        }

        if (res.employmentDetails.dValue1) {
            form.getCheckBox('Check Box 51').check()
        }

        if (res.employmentDetails.dValue5) {
            form.getCheckBox('Check Box 46').check()
        }

        if (res.employmentDetails.dValue2) {
            form.getCheckBox('Check Box 49').check()
        }
        if (res.employmentDetails.dValue3) {
            form.getCheckBox('Check Box 48').check()
        }

        if (res.employmentDetails.dValue7) {
            form.getCheckBox('Check Box 47').check()
        }

        if (res.employmentDetails.dValue8) {
            form.getCheckBox('Check Box 50').check()
        }
        if (res.employmentDetails.dValue9) {
            form.getCheckBox('Check Box 45').check()
        }
        if (res.oppDetails.statementScheme === 'No') {
            form.getCheckBox('Check Box 41').check()
        } else {
            form.getCheckBox('Check Box 42').check()
        }

        form.getTextField('Text Field 2060').setText(res.oppDetails.nationality)
        if (res.oppDetails.inWhichCountryDoYouLive)
            form.getTextField('Text Field 2062').setText(res.oppDetails.inWhichCountryDoYouLive)

        if (res.qualificationDetails.fullTimeEducationElse) {
            if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
                form.getCheckBox('Check Box 74').check()
            } else {
                form.getCheckBox('Check Box 75').check()
            }

        }

        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Check Box 82').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box 76').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Check Box 77').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Check Box 83').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Check Box 84').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Check Box 85').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Check Box 80').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Check Box 86').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Check Box 81').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Check Box 81').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Check Box 78').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Check Box 79').check()
        }
        if (res.qualificationDetails.englishGrades) {
            form.getTextField('Text Field 1').setText(res.qualificationDetails.englishGrades)
        }
        if (res.qualificationDetails.mathGrades) {
            form.getTextField('Text Field 12').setText(res.qualificationDetails.mathGrades)
        }
        if (res.qualificationDetails.GCSEEnglishabove) {
            form.getCheckBox('Check Box 87').check()
        }
        if (res.qualificationDetails.GCSEMathsabove) {
            form.getCheckBox('Check Box 90').check()
        }



        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('Check Box 94').check()
        } else {
            form.getCheckBox('Check Box 93').check()
        }
        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('Check Box 99').check()
        } else {
            form.getCheckBox('Check Box 100').check()
        }


        if (
            res.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getCheckBox('Check Box 101').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box 102').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box 103').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('CheckBox-white-bg').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('CheckBox-carribbean').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box 106').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box 1034').check()
        } else if (
            res.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Check Box 108').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box 1032').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box 1031').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box 1030').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box 1029').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box 107').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box 1033').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box 1028').check()
        } else if (
            res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Check Box 109').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box 1027').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box 1026').check()
        }

        if (res.oppDetails.disabilities === 'Yes') {

            if (res.oppDetails.dValue1) {
                form.getCheckBox('Check Box 1025').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('Check Box 1035').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('Check Box 1011').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('Check Box 1013').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('Check Box 1021').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('Check Box 1036').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('Check Box 1020').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('Check Box 1039').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('Check Box 1010').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('Check Box 1038').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('Check Box 1016').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('Check Box 1022').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('Check Box 1023').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('Check Box 1019').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('Check Box 1024').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('Check Box 1014').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('Check Box 1015').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('Check Box 1024').check()
            }
        } else {
            form.getCheckBox('Check Box 1040').check()
        }

        form.getCheckBox('Check Box 43').check()

        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })


        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(res.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(res.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(res.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(res.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(res.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(res.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(res.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(res.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(res.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.newDeclaration) {
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res),
                { base64: true }
            )
        }
        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}



export const handleEastleigh = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const res = await authService.getTestForm(ids[i])
        const formPdfBytes = await fetch('/eastleigh.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.data.data.signature
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

        let date = (String(moment(res.data.data.createdAt).format('DD/MM/YYYY')))
        form.getTextField('date').setText(date)
        form.getTextField('date2').setText(date)
        const signImageField = form.getTextField('Student Signature')
        signImageField.setImage(emblemImage)

        const signImageField1 = form.getTextField('Student Signature_2')
        signImageField1.setImage(emblemImage)

        form.getTextField('learnername').setText(res.data.data.personalDetails.firstName + ' ' + res.data.data.personalDetails.lastName)

        // set field font
        form.getTextField('where do you live').setFontSize(9)
        form.getTextField('Course Name  LevelRow1').setFontSize(9)
        form.getTextField('Title MrMrsMissMsDr').setFontSize(9)
        form.getTextField('First Name').setFontSize(9)
        form.getTextField('Surname Family Name').setFontSize(9)
        form.getTextField('middle name').setFontSize(9)
        form.getTextField('Text-dob').setFontSize(9)
        form.getTextField('national insurance').setFontSize(9)
        form.getTextField('Home Address 1').setFontSize(9)
        form.getTextField('homepostcode').setFontSize(9)
        form.getTextField('Home Telephone').setFontSize(9)
        form.getTextField('yearataddress').setFontSize(9)
        form.getTextField('Mob Number').setFontSize(9)
        form.getTextField('Email Address').setFontSize(9)
        form.getTextField('Nationality').setFontSize(9)
        form.getTextField('Grade if completedRow1').setFontSize(12)
        form.getTextField('Grade if completedRow2').setFontSize(12)
        form.getTextField('emergenyname').setFontSize(9)
        form.getTextField('relationshop').setFontSize(9)
        form.getTextField('emergenyPhone').setFontSize(9)
        form.getTextField('emergenyMOb').setFontSize(9)
        form.getTextField('hoursperweek').setFontSize(9)
        form.getTextField('employeename').setFontSize(9)
        form.getTextField('employerPostcode').setFontSize(9)
        form.getTextField('employe Address').setFontSize(9)
        form.getTextField('employee number').setFontSize(9)
        form.getTextField('unemployedLength').setFontSize(9)
        form.getTextField('benefits').setFontSize(9)
        form.getTextField('learnername').setFontSize(9)
        form.getTextField('benefits').setFontSize(9)
        form.getTextField('First Name2').setFontSize(9)
        form.getTextField('Surname Family Name2').setFontSize(9)
        form.getTextField('Text-dob2').setFontSize(9)
        form.getTextField('qualification').setFontSize(9)
        form.getTextField('identification').setFontSize(9)
        form.getTextField('date').setFontSize(12)
        form.getTextField('date2').setFontSize(12)


        form.getCheckBox('agesCheckbox').check()
        form.getCheckBox('uklast3years').check()
        form.getCheckBox('19-23checkbox').check()
        form.getCheckBox('currenlty live in uk').check()
        form.getCheckBox('notstuddiedthiscourse').check()

        form.getTextField('where do you live').setText(res.data.data.personalDetails.addLine1 + ", " + res.data.data.personalDetails.city + ", " + res.data.data.personalDetails.county)
        form.getTextField('Course Name  LevelRow1').setText(res.data.data.detailsFormData.appliedCourse)

        form.getTextField('Title MrMrsMissMsDr').setText(res.data.data.personalDetails.title)
        form.getTextField('Surname Family Name').setText(res.data.data.personalDetails.lastName)
        form.getTextField('Surname Family Name2').setText(res.data.data.personalDetails.lastName)
        form.getTextField('First Name').setText(res.data.data.personalDetails.firstName)
        form.getTextField('First Name2').setText(res.data.data.personalDetails.firstName)
        form.getTextField('middle name').setText(res.data.data.personalDetails.middleName)


        if (res.data.data.personalDetails.gender === 'Female') {
            form.getCheckBox('CheckBox-female').check()
        } else {
            form.getCheckBox('gendermale').check()
        }

        let testDate = (String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY')))
        form.getTextField('Text-dob').setText(testDate)
        form.getTextField('Text-dob2').setText(testDate)
        form.getTextField('qualification').setText(res.data.data.qualificationDetails.level)


        form.getTextField('national insurance').setText(res.data.data.personalDetails.nationalInsNo)

        form.getTextField('Home Address 1').setText(res.data.data.personalDetails.addLine1 + ", " + res.data.data.personalDetails.city + ", " + res.data.data.personalDetails.county)
        form.getTextField('homepostcode').setText(res.data.data.personalDetails.postcode)

        form.getTextField('Home Telephone').setText(res.data.data.personalDetails.telephone)
        form.getTextField('Mob Number').setText(res.data.data.personalDetails.telephone)
        form.getTextField('Email Address').setText(res.data.data.personalDetails.email)

        form.getTextField('Nationality').setText(res.data.data.oppDetails.nationality)

        form.getCheckBox("CheckBox-havelivedfor 3 years").check()

        form.getTextField('yearataddress').setText(res.data.data.personalDetails.yearsAtAdd + ' years')
        if (
            res.data.data.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getCheckBox('EnglishWelshScottish').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Gypsy or Irish Traveller 33').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Gypsy or Irish Traveller 33').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Any other white').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('White  Black Caribbean 35').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('White  Black African 36').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('White  Asian 37').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Any other mixedmultiple').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Indian 39').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Pakistani 40').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Bangladeshi 41').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Chinese 42').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Any other Asian').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('African 44').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Caribbean 45').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Any other Black African').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Arab 47').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Any other ethnic group 98').check()
        }

        if (res.data.data.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('CheckBox-disable-yes').check()

            if (res.data.data.oppDetails.dValue1) {
                form.getCheckBox('Visual impairment').check()
            }
            if (res.data.data.oppDetails.dValue2) {
                form.getCheckBox('Hearing impairment').check()
            }
            if (res.data.data.oppDetails.dValue3) {
                form.getCheckBox('Disability affecting mobility').check()
            }
            if (res.data.data.oppDetails.dValue4) {
                form.getCheckBox('Profound complex disabilities').check()
            }
            if (res.data.data.oppDetails.dValue5) {
                form.getCheckBox('Social and Emotional Difficulties').check()
            }
            if (res.data.data.oppDetails.dValue6) {
                form.getCheckBox('Mental health difficulty').check()
            }
            if (res.data.data.oppDetails.dValue7) {
                form.getCheckBox('Moderate difficulties').check()
            }
            if (res.data.data.oppDetails.dValue8) {
                form.getCheckBox('Severe difficulties').check()
            }
            if (res.data.data.oppDetails.dValue9) {
                form.getCheckBox('Dyslexia').check()
            }
            if (res.data.data.oppDetails.dValue10) {
                form.getCheckBox('Dyscalculia').check()
            }
            if (res.data.data.oppDetails.dValue11) {
                form.getCheckBox('Autism spectrum disorder').check()
            }
            if (res.data.data.oppDetails.dValue12) {
                form.getCheckBox('Aspergers Syndrome').check()
            }
            if (res.data.data.oppDetails.dValue13) {
                form.getCheckBox('Temporary disability after illness').check()
            }
            if (res.data.data.oppDetails.dValue14) {
                form.getCheckBox('Speech Language and').check()
            }
            if (res.data.data.oppDetails.dValue15) {
                form.getCheckBox('Other physical disability').check()
            }
            if (res.data.data.oppDetails.dValue16) {
                form.getCheckBox('Other_2').check()
            }
            if (res.data.data.oppDetails.dValue17) {
                form.getCheckBox('Other_2').check()
            }
            if (res.data.data.oppDetails.dValue18) {
                form.getCheckBox('Other').check()
            }
        } else {
            form.getCheckBox('CheckBox-disable-no').check()
            form.getCheckBox('learning-disable-no').check()
        }

        if (res.data.data.qualificationDetails.englishGrades) {
            form.getTextField('Grade if completedRow1').setText(res.data.data.qualificationDetails.englishGrades)
        }
        if (res.data.data.qualificationDetails.mathGrades) {
            form.getTextField('Grade if completedRow2').setText(res.data.data.qualificationDetails.mathGrades)
        }


        form.getTextField('emergenyname').setText(res.data.data.emergencyDetails.emergencyContactName)
        form.getTextField('relationshop').setText(res.data.data.emergencyDetails.relationshipToLearner)
        form.getTextField('emergenyPhone').setText(res.data.data.emergencyDetails.emergencyTelephone)
        form.getTextField('emergenyMOb').setText(res.data.data.emergencyDetails.emergencyTelephone)


        if (res.data.data.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Entry Level eg E1 E2 E3').check()
        } else if (res.data.data.qualificationDetails.level === 'None') {
            form.getCheckBox('No formal qualifications').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Level 1 5 or more GCSE grades 3 and below OR DG').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Level 1 5 or more GCSE grades 3 and below OR DG').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Full Level 2 5 GCSEs 4 and above OR AC 2 AS Levels 1').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Level 4 HNC NVQ 4').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Level 5 Foundation Degree HND NVQ 5').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Level 6 AwardCertDip').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Level 7  Degree Postgraduate degree').check()
        } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Level 7  Degree Postgraduate degree').check()
        } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Full Level 2 5 GCSEs 4 and above OR AC 2 AS Levels 1').check()
        } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Full Level 3 2 or more Alevels Certificate Diploma').check()
        }

        if (res.data.data.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.data.data.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox("Employed").check()
            if (res.data.data.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("0 to 10 hours per week").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Between 11 to 20 hours").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("21 to 30 hours").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("31 hours").check()
            }
            form.getTextField('employeename').setText(res.data.data.employmentDetails.employerName)
            form.getTextField('employe Address').setText(res.data.data.employmentDetails.employerAdd)
            form.getTextField('hoursperweek').setText(res.data.data.employmentDetails.hoursPerWeek)
            // form.getTextField('EmployementLength').setText(res.data.data.employmentDetails.length+" Months")
            form.getTextField('employerPostcode').setText(res.data.data.employmentDetails.postcode)

            if (res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year") {
                form.getCheckBox("yesmorethan 1828").check()
            } else {
                form.getCheckBox("lessthan1825").check()
            }


        } else if (res.data.data.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.data.data.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("unemployedlookingforwork").check()
            } else {
                form.getCheckBox("unemployednotlookwork").check()
            }
            form.getTextField('unemployedLength').setText(res.data.data.employmentDetails.unemployedLength + " Months")
            let benefits = []
            if (res.data.data.employmentDetails.dValue1) {
                benefits.push('JSA')
                form.getCheckBox('JSA').check()
            }
            if (res.data.data.employmentDetails.dValue2) {
                benefits.push('Income Support')
            }
            if (res.data.data.employmentDetails.dValue3) {
                benefits.push('Council Tax Benefit')
            }
            if (res.data.data.employmentDetails.dValue6) {
                benefits.push('Incapacity Benefit')
            }
            if (res.data.data.employmentDetails.dValue5) {
                benefits.push('ESA')
                form.getCheckBox('esa').check()
            }
            if (res.data.data.employmentDetails.dValue7) {
                benefits.push('Housing Benefit')
            }
            if (res.data.data.employmentDetails.dValue9) {
                benefits.push('Employed but on low wage')
            }

            if (res.data.data.employmentDetails.dValue8) {
                benefits.push('UC')
                form.getCheckBox('UCcheckl').check()
            }
            form.getTextField('benefits').setText(benefits.join())
        }

        //declaration
        form.getCheckBox('privacypolicy').check()
        form.getCheckBox('confirmation').check()

        if (res.data.data.declaration.aboutCourse)
            form.getCheckBox('aboutcourseinfo').check()
        if (res.data.data.declaration.forSurveys)
            form.getCheckBox('forsurveys').check()

        if (res.data.data.declaration.prefContact1)
            form.getCheckBox('By post').check()
        if (res.data.data.declaration.prefContact2)
            form.getCheckBox('By phone').check()
        if (res.data.data.declaration.prefContact)
            form.getCheckBox('By email').check()
        form.getTextField('identification').setText(res.data.data.typeOfProof)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        var images = [res.data.data.nationalId, res.data.data.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
        ).file(
            res.data.data.personalDetails.firstName +
            res.data.data.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.data.data.nationalId) {
            const imageBlob = fetch(res.data.data.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('National_ID.' + (res.data.data.nationalId).substr((res.data.data.nationalId).length - 3), imageBlob)
        }
        if (res.data.data.certificateLink) {
            const imageBlob = fetch(res.data.data.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Certificate.' + (res.data.data.certificateLink).substr((res.data.data.certificateLink).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment1) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment1.' + (res.data.data.oppDetails.attachment1).substr((res.data.data.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment2) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment2.' + (res.data.data.oppDetails.attachment2).substr((res.data.data.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment3) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment3.' + (res.data.data.oppDetails.attachment3).substr((res.data.data.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment4) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment4.' + (res.data.data.oppDetails.attachment4).substr((res.data.data.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence.' + (res.data.data.employmentDetails.evidence).substr((res.data.data.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence1) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence1.' + (res.data.data.employmentDetails.evidence1).substr((res.data.data.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence2) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence2.' + (res.data.data.employmentDetails.evidence2).substr((res.data.data.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.data.data.newDeclaration) {
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res.data.data),
                { base64: true }
            )
        }
        if (res.data.data.assessment) {
            if (res.data.data.assessment.english) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res.data.data),
                    { base64: true }
                )
            }
            if (res.data.data.assessment.answer1) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res.data.data),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}



export const handleBristolPdf = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const res = await authService.getTestForm(ids[i])
        const formPdfBytes = await fetch('/bristol.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.data.data.signature
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)


        form.getCheckBox('lived in uk for 3 years').check()


        form.getTextField('Title').setText(res.data.data.personalDetails.title)
        form.getTextField('Surname Family Name').setText(res.data.data.personalDetails.lastName)
        form.getTextField('First Name').setText(res.data.data.personalDetails.firstName)
        form.getTextField('middle name').setText(res.data.data.personalDetails.middleName)


        if (res.data.data.personalDetails.gender === 'Female') {
            form.getCheckBox('CheckBox-female').check()
        } else {
            form.getCheckBox('gendermale').check()
        }
        if (res.data.data.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('criminal-yes').check()
        } else {
            form.getCheckBox('criminal-no').check()
        }
        form.getTextField('national insurance').setText(res.data.data.personalDetails.nationalInsNo)

        if (res.data.data.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            form.getCheckBox('EnglishWelshScottish').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('irish').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Gypsy or Irish Traveller').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Any other white').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('White  Black Caribbean').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('White  Black African').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('White Asian').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Any other mixedmultiple').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Indian').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Pakistani').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Bangladeshi').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Chinese').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Any other Asian').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('African').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Caribbean').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Any other Black African').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Arab').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Any other ethnic group').check()
        }


        let testDate = (String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY')))
        form.getTextField('Text-dob').setText(testDate)

        form.getTextField('Home Address 1').setText(res.data.data.personalDetails.addLine1 + ", " + res.data.data.personalDetails.city + ", " + res.data.data.personalDetails.county)
        form.getTextField('homepostcode').setText(res.data.data.personalDetails.postcode)

        form.getTextField('Home Telephone').setText(res.data.data.personalDetails.telephone)
        form.getTextField('Mob Number').setText(res.data.data.personalDetails.telephone)
        form.getTextField('Email Address').setText(res.data.data.personalDetails.email)


        form.getTextField('emergenyname').setText(res.data.data.emergencyDetails.emergencyContactName)
        form.getTextField('relationshop').setText(res.data.data.emergencyDetails.relationshipToLearner)
        form.getTextField('emergenyPhone').setText(res.data.data.emergencyDetails.emergencyTelephone)
        form.getTextField('emergenyMOb').setText(res.data.data.emergencyDetails.emergencyTelephone)
        form.getCheckBox('CheckBox-other').check()

        form.getTextField('yearataddress').setText(res.data.data.personalDetails.yearsAtAdd + ' years')

        form.getTextField('Nationality').setText(res.data.data.oppDetails.nationality)
        if (res.data.data.oppDetails.inWhichCountryDoYouLive)
            form.getTextField('inWhichCountryDoYouLive').setText(res.data.data.oppDetails.inWhichCountryDoYouLive)

        if (res.data.data.oppDetails.firstLang === 'Yes') {
            form.getCheckBox('firstLang').check()
        } else {
            form.getCheckBox('firstLang-no').check()
        }

        if (res.data.data.oppDetails.nationality === 'british') {
            form.getCheckBox('CheckBox-uk-national').check()
        } else {
            form.getCheckBox('uk-national- no').check()
        }
        if (res.data.data.oppDetails.nonEEACitizen === 'Yes') {
            form.getCheckBox('non-ea-nationa').check()
        } else {
            form.getCheckBox('non-eea - no').check()
        }


        if (res.data.data.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('CheckBox-disable-yes').check()

            if (res.data.data.oppDetails.dValue1) {
                form.getCheckBox('Visual impairment').check()
            }
            if (res.data.data.oppDetails.dValue2) {
                form.getCheckBox('Hearing impairment').check()
            }
            if (res.data.data.oppDetails.dValue3) {
                form.getCheckBox('Disability affecting mobility').check()
            }
            if (res.data.data.oppDetails.dValue4) {
                form.getCheckBox('Profound complex disabilities').check()
            }
            if (res.data.data.oppDetails.dValue5) {
                form.getCheckBox('Social and Emotional Difficulties').check()
            }
            if (res.data.data.oppDetails.dValue6) {
                form.getCheckBox('Mental health difficulty').check()
            }
            if (res.data.data.oppDetails.dValue7) {
                form.getCheckBox('Moderate difficulties').check()
            }
            if (res.data.data.oppDetails.dValue8) {
                form.getCheckBox('Severe difficulties').check()
            }
            if (res.data.data.oppDetails.dValue9) {
                form.getCheckBox('Dyslexia').check()
            }
            if (res.data.data.oppDetails.dValue10) {
                form.getCheckBox('Dyscalculia').check()
            }
            if (res.data.data.oppDetails.dValue11) {
                form.getCheckBox('Autism spectrum disorder').check()
            }
            if (res.data.data.oppDetails.dValue12) {
                form.getCheckBox('Aspergers Syndrome').check()
            }
            if (res.data.data.oppDetails.dValue13) {
                form.getCheckBox('Temporary disability after illness').check()
            }
            if (res.data.data.oppDetails.dValue14) {
                form.getCheckBox('Speech Language and').check()
            }
            if (res.data.data.oppDetails.dValue15) {
                form.getCheckBox('Other physical disability').check()
            }
            if (res.data.data.oppDetails.dValue16) {
                form.getCheckBox('Medical condition').check()
            }
            if (res.data.data.oppDetails.dValue17) {
                form.getCheckBox('learning-difficulty').check()
            }
            if (res.data.data.oppDetails.dValue18) {
                form.getCheckBox('Other').check()
            }
        } else {
            form.getCheckBox('CheckBox-disable-no').check()
        }
        if (res.data.data.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getCheckBox('fullTimeEducationElse').check()
        } else {
            form.getCheckBox('fullTimeEducationElse-no').check()
        }

        if (res.data.data.declaration.houseHoldMem) {
            form.getCheckBox('houseHoldMem').check()
        }
        if (res.data.data.declaration.houseHoldMem2) {
            form.getCheckBox('houseHoldMem2').check()
        }
        if (res.data.data.declaration.singleHouse) {
            form.getCheckBox('singleHouse').check()
        }
        if (res.data.data.declaration.noneOfThese) {
            form.getCheckBox('noneOfThese').check()
        }
        if (res.data.data.declaration.confirmation) {
            form.getCheckBox('confirmation').check()
        }

        if (res.data.data.qualificationDetails.englishGrades) {
            form.getTextField('Grade if completedRow1').setText(res.data.data.qualificationDetails.englishGrades)
        }
        if (res.data.data.qualificationDetails.mathGrades) {
            form.getTextField('Grade if completedRow2').setText(res.data.data.qualificationDetails.mathGrades)
        }
        if (res.data.data.qualificationDetails.GCSEEnglishabove) {
            form.getCheckBox('GCSEEnglishabove').check()
        } else {
            form.getCheckBox('GCSEEnglishabove-no').check()
        }

        if (res.data.data.qualificationDetails.GCSEMathsabove) {
            form.getCheckBox('GCSEMathsabove').check()
        } else {
            form.getCheckBox('GCSEMathsabove-no').check()
        }



        let qualLevel

        if (res.data.data.qualificationDetails.level === 'Entry Level') {
            qualLevel = '09'
        } else if (res.data.data.qualificationDetails.level === 'None') {
            qualLevel = '99'
        } else if (res.data.data.qualificationDetails.level === 'Level 1') {
            qualLevel = '01'
        } else if (res.data.data.qualificationDetails.level === 'Level 2') {
            qualLevel = '02'
        } else if (res.data.data.qualificationDetails.level === 'Level 3') {
            qualLevel = '03'
        } else if (res.data.data.qualificationDetails.level === 'Level 4') {
            qualLevel = '10'
        } else if (res.data.data.qualificationDetails.level === 'Level 5') {
            qualLevel = '11'
        } else if (res.data.data.qualificationDetails.level === 'Level 6') {
            qualLevel = '12'
        } else if (res.data.data.qualificationDetails.level === 'Level 7') {
            qualLevel = '13'
        } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
            qualLevel = '13'
        } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
            qualLevel = '02'
        } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
            qualLevel = '03'
        }
        form.getTextField('qualificationDetailslevel').setText(qualLevel)



        if (res.data.data.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.data.data.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox("Employed").check()
            if (res.data.data.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("0 to 10 hours per week").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Between 11 to 20 hours").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("21 to 30 hours").check()
            } else if (res.data.data.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("31 hours").check()
            }
            if (res.data.data.employmentDetails.length === "12+") {
                form.getCheckBox("12+").check()
            } else if (res.data.data.employmentDetails.length === "4-6") {
                form.getCheckBox("4-6").check()
            } else if (res.data.data.employmentDetails.length === "7-12") {
                form.getCheckBox("7-12").check()
            } else if (res.data.data.employmentDetails.length === "Up to 3") {
                form.getCheckBox("Up to 3").check()
            }
            if (res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
                res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
                form.getCheckBox("earning-less-than18").check()
            }

        } else if (res.data.data.employmentDetails.employementStatus === "Unemployed, looking for work") {
            form.getCheckBox("unemployedlookingforwork").check()
            if (res.data.data.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("Less than 6 months").check()
            } else if (res.data.data.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("6  11 months").check()
            } else if (res.data.data.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("12  23 months").check()
            } else if (res.data.data.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("24  35 months").check()
            } else if (res.data.data.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("36 months or over").check()
            }

            // form.getTextField('Course Name').setText(res.data.data.detailsFormData.appliedCourse)
            form.getTextField('courselevel').setText('Level 2')

            if (res.data.data.employmentDetails.dValue1) {
                form.getCheckBox('JSA').check()
            }
            if (res.data.data.employmentDetails.dValue2) {
                form.getCheckBox('Income Support').check()
            }
            if (res.data.data.employmentDetails.dValue3) {
                form.getCheckBox('Council Tax Benefit').check()
            }
            if (res.data.data.employmentDetails.dValue6) {
                form.getCheckBox('Incapacity Benefit').check()
            }
            if (res.data.data.employmentDetails.dValue5) {
                form.getCheckBox('esa').check()
            }
            if (res.data.data.employmentDetails.dValue7) {
                form.getCheckBox('Housing Benefit').check()
            }


            if (res.data.data.employmentDetails.dValue8) {
                form.getCheckBox('UCcheckl').check()
            }
        }


        if (res.data.data.declaration.aboutCourse) {
            form.getCheckBox('aboutcourseinfo').check()
        } else {
            form.getCheckBox('aboutcourseinfo-no').check()
        }
        if (res.data.data.declaration.forSurveys) {
            form.getCheckBox('forsurveys').check()
        } else {
            form.getCheckBox('forsurveys-no').check()
        }

        if (res.data.data.declaration.prefContact1)
            form.getCheckBox('By post').check()
        if (res.data.data.declaration.prefContact2)
            form.getCheckBox('By phone').check()
        if (res.data.data.declaration.prefContact)
            form.getCheckBox('By email').check()

        let date = (String(moment(res.data.data.createdAt).format('DD/MM/YYYY')))
        form.getTextField('date').setText(date)
        const signImageField = form.getTextField('Student Signature')
        signImageField.setImage(emblemImage)


        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        var images = [res.data.data.nationalId, res.data.data.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
        ).file(
            res.data.data.personalDetails.firstName +
            res.data.data.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.data.data.nationalId) {
            const imageBlob = fetch(res.data.data.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('National_ID.' + (res.data.data.nationalId).substr((res.data.data.nationalId).length - 3), imageBlob)
        }
        if (res.data.data.certificateLink) {
            const imageBlob = fetch(res.data.data.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Certificate.' + (res.data.data.certificateLink).substr((res.data.data.certificateLink).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment1) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment1.' + (res.data.data.oppDetails.attachment1).substr((res.data.data.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment2) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment2.' + (res.data.data.oppDetails.attachment2).substr((res.data.data.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment3) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment3.' + (res.data.data.oppDetails.attachment3).substr((res.data.data.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment4) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment4.' + (res.data.data.oppDetails.attachment4).substr((res.data.data.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence.' + (res.data.data.employmentDetails.evidence).substr((res.data.data.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence1) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence1.' + (res.data.data.employmentDetails.evidence1).substr((res.data.data.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence2) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence2.' + (res.data.data.employmentDetails.evidence2).substr((res.data.data.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.data.data.newDeclaration) {
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res.data.data),
                { base64: true }
            )
        }
        if (res.data.data.assessment) {
            if (res.data.data.assessment.english) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res.data.data),
                    { base64: true }
                )
            }
            if (res.data.data.assessment.answer1) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res.data.data),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


export const tecPartnerPdf = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const res = await authService.getTestForm(ids[i])
        const formPdfBytes = await fetch('/tecPartner.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.data.data.signature
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

        form.getTextField('Coursename1').setText(res.data.data.detailsFormData.appliedCourse)

        form.getTextField('Title').setText(res.data.data.personalDetails.title)
        form.getTextField('Surname Family Name').setText(res.data.data.personalDetails.lastName)
        form.getTextField('First Name').setText(res.data.data.personalDetails.firstName + ' ' + (res.data.data.personalDetails.middleName || ''))
        form.getTextField('prefered Name').setText(res.data.data.personalDetails.firstName)

        let testDate = (String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY')))
        form.getTextField('Text-dob').setText(testDate)
        form.getTextField('age').setText(res.data.data.personalDetails.age)

        form.getTextField('national insurance').setText(res.data.data.personalDetails.nationalInsNo)
        form.getTextField('gender').setText(res.data.data.personalDetails.gender)

        form.getTextField('Home Address 1').setText(res.data.data.personalDetails.addLine1 + ", " + res.data.data.personalDetails.city + ", " + res.data.data.personalDetails.county)
        form.getTextField('homepostcode').setText(res.data.data.personalDetails.postcode)
        form.getTextField('yearataddress').setText(res.data.data.personalDetails.yearsAtAdd + ' years')

        form.getTextField('Home Telephone').setText(res.data.data.personalDetails.telephone)
        form.getTextField('Mob Number').setText(res.data.data.personalDetails.telephone)
        form.getTextField('Email Address').setText(res.data.data.personalDetails.email)


        if (res.data.data.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('criminal-yes').check()
        } else {
            form.getCheckBox('criminal-no').check()
        }
        form.getCheckBox('residentinukfor3').check()
        form.getTextField('nationality').setText(res.data.data.oppDetails.nationality)

        if (res.data.data.oppDetails.inWhichCountryDoYouLive)
            form.getTextField('inWhichCountryDoYouLive').setText(res.data.data.oppDetails.inWhichCountryDoYouLive)
        form.getTextField('countryofbirth').setText(res.data.data.oppDetails.countryOfBirth)

        if (res.data.data.typeOfProof === 'Passport') {
            form.getCheckBox('CheckBox-passport').check()
        }
        form.getTextField('Text-typeofProof').setText(res.data.data.typeOfProof)

        let currentStatus
        if (res.data.data.oppDetails.nationality === 'british') {
            currentStatus = '01'
        } else if (res.data.data.oppDetails.statementScheme === 'Yes') {
            currentStatus = '13'
        } else if (res.data.data.oppDetails.perField === 'Asylum Seeker') {
            currentStatus = '05'
        } else if (res.data.data.oppDetails.perField === 'Indefinite Leave') {
            currentStatus = '02'
        } else if (res.data.data.oppDetails.perField === 'Exceptional / Discretionary Leave') {
            currentStatus = '03'
        } else if (res.data.data.oppDetails.perField === 'Humanitarian Protection') {
            currentStatus = '05'
        } else if (res.data.data.oppDetails.perField === 'Refugee') {
            currentStatus = '05'
        } else if (res.data.data.oppDetails.perField === 'Famile Member of EU citizen') {
            currentStatus = '06'
        } else if (res.data.data.oppDetails.perField === 'British/EU/EEA/Citizen') {
            currentStatus = '02'
        }
        form.getTextField('settled-currentStatus').setText(currentStatus)

        let ethnicity

        if (res.data.data.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            ethnicity = '31'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
            ethnicity = '32'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            ethnicity = '33'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
            ethnicity = '34'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            ethnicity = '35'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
            ethnicity = '36'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
            ethnicity = '37'
        } else if (
            res.data.data.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            ethnicity = '38'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
            ethnicity = '39'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
            ethnicity = '40'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
            ethnicity = '41'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
            ethnicity = '42'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
            ethnicity = '43'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
            ethnicity = '44'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
            ethnicity = '45'
        } else if (
            res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            ethnicity = '46'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
            ethnicity = '47'
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            ethnicity = '98'
        }

        form.getTextField('ethnicity-field').setText(ethnicity)

        if (res.data.data.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('CheckBox-disable-yes').check()
            let disabilityCode
            if (res.data.data.oppDetails.dValue1) {
                disabilityCode = '04'
                // form.getCheckBox('Visual impairment').check()
            }
            if (res.data.data.oppDetails.dValue2) {
                disabilityCode = '05'
                // form.getCheckBox('Hearing impairment').check()
            }
            if (res.data.data.oppDetails.dValue3) {
                disabilityCode = '06'
                // form.getCheckBox('Disability affecting mobility').check()
            }
            if (res.data.data.oppDetails.dValue4) {
                disabilityCode = '07'
                // form.getCheckBox('Profound complex disabilities').check()
            }
            if (res.data.data.oppDetails.dValue5) {
                disabilityCode = '08'
                // form.getCheckBox('Social and Emotional Difficulties').check()
            }
            if (res.data.data.oppDetails.dValue6) {
                disabilityCode = '09'
                // form.getCheckBox('Mental health difficulty').check()
            }
            if (res.data.data.oppDetails.dValue7) {
                disabilityCode = '10'
                // form.getCheckBox('Moderate difficulties').check()
            }
            if (res.data.data.oppDetails.dValue8) {
                disabilityCode = '11'
                // form.getCheckBox('Severe difficulties').check()
            }
            if (res.data.data.oppDetails.dValue9) {
                disabilityCode = '12'
                // form.getCheckBox('Dyslexia').check()
            }
            if (res.data.data.oppDetails.dValue10) {
                disabilityCode = '13'
                // form.getCheckBox('Dyscalculia').check()
            }
            if (res.data.data.oppDetails.dValue11) {
                disabilityCode = '14'
                // form.getCheckBox('Autism spectrum disorder').check()
            }
            if (res.data.data.oppDetails.dValue12) {
                disabilityCode = '15'
                // form.getCheckBox('Aspergers Syndrome').check()
            }
            if (res.data.data.oppDetails.dValue13) {
                disabilityCode = '16'
                // form.getCheckBox('Temporary disability after illness').check()
            }
            if (res.data.data.oppDetails.dValue14) {
                disabilityCode = '17'
                // form.getCheckBox('Speech Language and').check()
            }
            if (res.data.data.oppDetails.dValue15) {
                disabilityCode = '93'
                // form.getCheckBox('Other physical disability').check()
            }
            if (res.data.data.oppDetails.dValue16) {
                disabilityCode = '95'
                // form.getCheckBox('Medical condition').check()
            }
            if (res.data.data.oppDetails.dValue17) {
                disabilityCode = '94'
                // form.getCheckBox('learning-difficulty').check()
            }
            if (res.data.data.oppDetails.dValue18) {
                disabilityCode = '97'
                // form.getCheckBox('Other').check()
            }
            form.getTextField('Text-disability').setText(disabilityCode)
        } else {
            form.getCheckBox('CheckBox-disable-no').check()
        }


        if (res.data.data.declaration.houseHoldMem) {
            form.getCheckBox('houseHoldMem').check()
        }
        if (res.data.data.declaration.houseHoldMem2) {
            form.getCheckBox('houseHoldMem2').check()
        }
        if (res.data.data.declaration.singleHouse) {
            form.getCheckBox('singleHouse').check()
        }
        if (res.data.data.declaration.noneOfThese) {
            form.getCheckBox('noneOfThese').check()
        }
        if (res.data.data.declaration.confirmation) {
            form.getCheckBox('confirmation').check()
        }


        let qualLevel

        if (res.data.data.qualificationDetails.level === 'Entry Level') {
            qualLevel = '01'
        } else if (res.data.data.qualificationDetails.level === 'None') {
            qualLevel = '97'
        } else if (res.data.data.qualificationDetails.level === 'Level 1') {
            qualLevel = '02'
        } else if (res.data.data.qualificationDetails.level === 'Level 2') {
            qualLevel = '03'
        } else if (res.data.data.qualificationDetails.level === 'Level 3') {
            qualLevel = '05'
        } else if (res.data.data.qualificationDetails.level === 'Level 4') {
            qualLevel = '07'
        } else if (res.data.data.qualificationDetails.level === 'Level 5') {
            qualLevel = '08'
        } else if (res.data.data.qualificationDetails.level === 'Level 6') {
            qualLevel = '09'
        } else if (res.data.data.qualificationDetails.level === 'Level 7') {
            qualLevel = '10'
        } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
            qualLevel = '10'
        } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
            qualLevel = '04'
        } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
            qualLevel = '06'
        }
        form.getTextField('qualificationDetailslevel').setText(qualLevel)
        // form.getTextField('Course Name').setText(res.data.data.detailsFormData.appliedCourse)


        if (res.data.data.qualificationDetails.englishGrades) {
            form.getTextField('Grade if completedRow1').setText(res.data.data.qualificationDetails.englishGrades)
        }
        if (res.data.data.qualificationDetails.mathGrades) {
            form.getTextField('Grade if completedRow2').setText(res.data.data.qualificationDetails.mathGrades)
        }


        if (res.data.data.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getCheckBox('fullTimeEducationElse').check()
        }


        let employmentStatus
        let unemploymentLength
        let benefit
        let employetintensit
        if (res.data.data.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.data.data.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            employmentStatus = '10'
            if (res.data.data.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                employetintensit = '5'
            } else if (res.data.data.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                employetintensit = '6'
            } else if (res.data.data.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                employetintensit = '7'
            } else if (res.data.data.employmentDetails.hoursPerWeek === "30+ hours per week") {
                employetintensit = '8'
            }

        } else if (res.data.data.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.data.data.employmentDetails.lookingForWork === "Yes") {
                employmentStatus = "11"
            } else {
                employmentStatus = "12"
            }
            if (res.data.data.employmentDetails.unemployedLength === "0-5 months") {
                unemploymentLength = "1"
            } else if (res.data.data.employmentDetails.unemployedLength === "6-11 months") {
                unemploymentLength = "2"
            } else if (res.data.data.employmentDetails.unemployedLength === "12-23 months") {
                unemploymentLength = "3"
            } else if (res.data.data.employmentDetails.unemployedLength === "24-35 months") {
                unemploymentLength = "4"
            } else if (res.data.data.employmentDetails.unemployedLength === "Over 36 months") {
                unemploymentLength = "5"
            }


            if (res.data.data.employmentDetails.dValue1) {
                benefit = '1'
                // form.getCheckBox('JSA').check()
            }
            if (res.data.data.employmentDetails.dValue2) {
                form.getTextField('Text-benefit').setText("Income Support")
                benefit = '6'
            }
            if (res.data.data.employmentDetails.dValue3) {
                benefit = '6'
                form.getTextField('Text-benefit').setText("Council Tax Benefit")
            }
            if (res.data.data.employmentDetails.dValue6) {
                benefit = '6'
                form.getTextField('Text-benefit').setText("Incapacity Benefit")
            }
            if (res.data.data.employmentDetails.dValue5) {
                benefit = '5'
            }
            if (res.data.data.employmentDetails.dValue7) {
                benefit = '6'
                form.getTextField('Text-benefit').setText("Housing Benefit")
            }


            if (res.data.data.employmentDetails.dValue8) {
                // form.getCheckBox('UCcheckl').check()
                benefit = '4'
            }
        }
        form.getTextField('employmentStatus').setText(employmentStatus)
        form.getTextField('unemploymentLength').setText(unemploymentLength)
        form.getTextField('benefit').setText(benefit)
        form.getTextField('employetlength').setText(moment(res.data.data.employmentDetails.startDateEmployment).format('DD/MM/YYYY'))
        form.getTextField('employetintensit').setText(employetintensit)
        form.getTextField('Text163').setText(res.data.data.employmentDetails.employerName)
        form.getTextField('Text163').setText(res.data.data.employmentDetails.employerName)
        form.getTextField('Text164').setText(res.data.data.employmentDetails.employerAdd)
        form.getTextField('Text166').setText(res.data.data.employmentDetails.postcode)
        form.getTextField('emp-ph').setText(res.data.data.employmentDetails.ph)



        if (res.data.data.declaration.prefContact1)
            form.getCheckBox('By post').check()
        if (res.data.data.declaration.prefContact2)
            form.getCheckBox('By phone').check()
        if (res.data.data.declaration.prefContact)
            form.getCheckBox('By email').check()

        let date = (String(moment(res.data.data.createdAt).format('DD/MM/YYYY')))
        form.getTextField('date').setText(date)
        const signImageField = form.getTextField('Student Signature')
        signImageField.setImage(emblemImage)


        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        var images = [res.data.data.nationalId, res.data.data.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
        ).file(
            res.data.data.personalDetails.firstName +
            res.data.data.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.data.data.nationalId) {
            const imageBlob = fetch(res.data.data.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('National_ID.' + (res.data.data.nationalId).substr((res.data.data.nationalId).length - 3), imageBlob)
        }
        if (res.data.data.certificateLink) {
            const imageBlob = fetch(res.data.data.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Certificate.' + (res.data.data.certificateLink).substr((res.data.data.certificateLink).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment1) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment1.' + (res.data.data.oppDetails.attachment1).substr((res.data.data.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment2) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment2.' + (res.data.data.oppDetails.attachment2).substr((res.data.data.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment3) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment3.' + (res.data.data.oppDetails.attachment3).substr((res.data.data.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment4) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment4.' + (res.data.data.oppDetails.attachment4).substr((res.data.data.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence.' + (res.data.data.employmentDetails.evidence).substr((res.data.data.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence1) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence1.' + (res.data.data.employmentDetails.evidence1).substr((res.data.data.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence2) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence2.' + (res.data.data.employmentDetails.evidence2).substr((res.data.data.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.data.data.newDeclaration) {
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res.data.data),
                { base64: true }
            )
        }
        if (res.data.data.assessment) {
            if (res.data.data.assessment.english) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res.data.data),
                    { base64: true }
                )
            }
            if (res.data.data.assessment.answer1) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res.data.data),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


export const lincolnCollegePDF = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])

        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/lincolnCollege.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature)
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

        if (res.typeOfProof === 'Passport') {
            form.getCheckBox('CheckBox-passport').check()
        } else if (res.typeOfProof === 'Drivers licence') {
            form.getCheckBox('CheckBox-Driverslicence').check()
        } else if (res.typeOfProof === 'Birth certificate') {
            form.getCheckBox('CheckBox-Birthcertificate').check()
        } else if (res.typeOfProof === 'Utility Bill' || res.typeOfProof === 'Bank statement') {
            form.getCheckBox('CheckBox-other').check()
        }

        // form.getTextField('Coursename1').setText(res.detailsFormData.appliedCourse)

        form.getTextField('Title').setText(res.personalDetails.title)
        form.getTextField('Surname Family Name').setText(res.personalDetails.lastName)
        form.getTextField('First Name').setText(res.personalDetails.firstName)
        form.getTextField('middle Name').setText(res.personalDetails.middleName)

        let testDate = (String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY')))
        form.getTextField('Text-dob').setText(testDate)
        form.getTextField('age').setText(res.personalDetails.age)

        form.getTextField('national insurance').setText(res.personalDetails.nationalInsNo)
        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('female').check()
        } else {
            form.getCheckBox('male').check()
        }

        form.getTextField('Home Address 1').setText(res.personalDetails.addLine1)
        form.getTextField('Home city').setText(res.personalDetails.city)
        form.getTextField('Home county').setText(res.personalDetails.county)

        form.getTextField('homepostcode').setText(res.personalDetails.postcode)

        form.getTextField('Home Telephone').setText(res.personalDetails.telephone)
        form.getTextField('Mob Number').setText(res.personalDetails.telephone)
        form.getTextField('Email Address').setText(res.personalDetails.email)


        form.getTextField('emergencyContactName').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('relationshipToLearner').setText(res.emergencyDetails.relationshipToLearner)
        form.getTextField('emergencyTelephone').setText(res.emergencyDetails.emergencyTelephone)


        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('criminal-yes').check()
        } else {
            form.getCheckBox('criminal-no').check()
        }

        form.getCheckBox('residentinukfor3').check()

        if (res.oppDetails.inWhichCountryDoYouLive)
            form.getTextField('inWhichCountryDoYouLive').setText(res.oppDetails.inWhichCountryDoYouLive)

        if (res.oppDetails.statementScheme === 'Yes') {
            form.getCheckBox('CheckBox-settled').check()
        } else {
            form.getCheckBox('CheckBox-presettled').check()
        }
        form.getTextField('ethnicOrigin').setText(res.oppDetails.ethnicOrigin)
        form.getTextField('Text nationality').setText(res.oppDetails.nationality)


        form.getTextField('TextqualificationDetailslevel').setText(res.qualificationDetails.level)

        if (res.oppDetails.firstLang === 'Yes') {
            form.getTextField('Text-firstLang').setText("English")
        }

        if (res.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('CheckBox-disable-yes').check()
            let disabilitiesArr = []
            if (res.oppDetails.dValue1) {
                disabilitiesArr.push('Visual impairment')
            }
            if (res.oppDetails.dValue2) {
                disabilitiesArr.push('Hearing impairment')
            }
            if (res.oppDetails.dValue3) {
                disabilitiesArr.push('Disability affecting mobility')
            }
            if (res.oppDetails.dValue4) {
                disabilitiesArr.push('Profound complex disabilities')
            }
            if (res.oppDetails.dValue5) {
                disabilitiesArr.push('Social and Emotional Difficulties')
            }
            if (res.oppDetails.dValue6) {
                disabilitiesArr.push('Mental health difficulty')
            }
            if (res.oppDetails.dValue7) {
                disabilitiesArr.push('Moderate difficulties')
            }
            if (res.oppDetails.dValue8) {
                disabilitiesArr.push('Severe difficulties')
            }
            if (res.oppDetails.dValue9) {
                disabilitiesArr.push('Dyslexia')
            }
            if (res.oppDetails.dValue10) {
                disabilitiesArr.push('Dyscalculia')
            }
            if (res.oppDetails.dValue11) {
                disabilitiesArr.push('Autism spectrum disorder')
            }
            if (res.oppDetails.dValue12) {
                disabilitiesArr.push('Aspergers Syndrome')
            }
            if (res.oppDetails.dValue13) {
                disabilitiesArr.push('Temporary disability after illness')
            }
            if (res.oppDetails.dValue14) {
                disabilitiesArr.push('Speech Language and')
            }
            if (res.oppDetails.dValue15) {
                disabilitiesArr.push('Other physical disability')
            }
            if (res.oppDetails.dValue16) {
                disabilitiesArr.push('Medical condition')
            }
            if (res.oppDetails.dValue17) {
                disabilitiesArr.push('learning-difficulty')
            }
            if (res.oppDetails.dValue18) {
                disabilitiesArr.push('Other')
            }
            form.getTextField('Text-disablilites').setText(disabilitiesArr.toString())
        } else {
            form.getCheckBox('CheckBox-disable-no').check()
        }

        if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getCheckBox('fullTimeEducationElse').check()
        }


        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {

            if (res.employmentDetails.employmentType === 'Self Employed') {
                form.getCheckBox("selfEmployed").check()
            } else {
                form.getCheckBox("Employed").check()
            }
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("0 to 10 hours per week").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Between 11 to 20 hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("21 to 30 hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("31 hours").check()
            }

            form.getTextField('Text-FEJn08dIKL').setText(moment(res.employmentDetails.startDateEmployment).format('DD/MM/YYYY'))

        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {

            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("Yes_12").check()
            } else {
                form.getCheckBox("No_12").check()
            }

            if (res.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("Less than 6 months").check()
            } else if (res.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("6  11 months").check()
            } else if (res.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("12  23 months").check()
            } else if (res.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("24  35 months").check()
            } else if (res.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("36 months or over").check()
            }

        }

        if (res.employmentDetails.dValue1) {
            form.getCheckBox('CheckBox-selfdeclarefunds').check()
            form.getCheckBox('JSA').check()
        }

        if (res.employmentDetails.dValue5) {
            form.getCheckBox('CheckBox-selfdeclarefunds').check()
            form.getCheckBox('ESA').check()
        }

        if (res.employmentDetails.dValue8) {
            form.getCheckBox('CheckBox-selfdeclarefunds').check()
            form.getCheckBox('UC').check()
        }

        if (res.employmentDetails.dValue2 ||
            res.employmentDetails.dValue3 ||
            res.employmentDetails.dValue6 ||
            res.employmentDetails.dValue7 ||
            res.employmentDetails.dValue9) {
            form.getCheckBox('CheckBox-selfdeclarefunds').check()
        }

        if (res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox('anualGrossSalary').check()
        } else {
            form.getCheckBox('CheckBox-no-anualsalary').check()
        }

        //declaration
        // if (res.declaration.houseHoldMem) {
        //     form.getCheckBox('No household member is in employment and the household').check()
        // }
        // if (res.declaration.houseHoldMem2) {
        //     form.getCheckBox('No household member is in employment and the household_2').check()
        // }
        // if (res.declaration.singleHouse) {
        //     form.getCheckBox('Learner lives in a single adult household with dependent').check()
        // }
        // if (res.declaration.noneOfThese) {
        //     form.getCheckBox('noneofthese').check()
        // }
        // if (res.declaration.confirmation)
        //     form.getCheckBox('I confirm that I wish to withhold this information').check()


        let date = (String(moment(res.createdAt).format('DD/MM/YYYY')))
        form.getTextField('date').setText(date)
        const signImageField = form.getTextField('Text-singature')
        signImageField.setImage(emblemImage)


        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })


        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })


        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.newDeclaration) {
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res),
                { base64: true }
            )
        }
        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}



export const handleNCGPdf = async (ids) => {
    var zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/ncgClg.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.signature
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)


        if (res.personalDetails.title === 'Mr') {
            form.getCheckBox('Check Box 7').check()
        } else if (res.personalDetails.title === 'Mrs') {
            form.getCheckBox('Check Box 5').check()
        } else if (res.personalDetails.title === 'Ms') {
            form.getCheckBox('Check Box 4').check()
        } else if (res.personalDetails.title === 'Miss') {
            form.getCheckBox('Check Box 5').check()
        } else {
            form.getTextField('Text Field 2').setText(res.personalDetails.title)
        }

        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('Check Box 3').check()
        } else {
            form.getCheckBox('Check Box 2').check()
        }

        form.getTextField('Text Field 4').setText(res.personalDetails.lastName + ' ' + (res?.personalDetails?.middleName || ''))
        form.getTextField('Text Field 3').setText(res.personalDetails.firstName)

        form.getTextField('Text Field 6').setText(res.personalDetails.nationalInsNo)


        let testDate = (String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY')))
        form.getTextField('Text Field 5').setText(testDate)


        form.getTextField('Text Field 7').setText(res.personalDetails.addLine1 + ", " + res.personalDetails.city + ", " + res.personalDetails.county)
        form.getTextField('Text Field 9').setText(res.personalDetails.postcode)

        form.getTextField('Text Field 11').setText(res.personalDetails.telephone)
        form.getTextField('Text Field 83').setText(res.personalDetails.email)


        form.getTextField('Text Field 84').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text Field 85').setText(res.emergencyDetails.relationshipToLearner)
        form.getTextField('Text Field 16').setText(res.emergencyDetails.emergencyTelephone)


        form.getTextField('Text Field 22').setText(res.oppDetails.nationality)


        if (res.oppDetails.nationality === 'british' || res.oppDetails.nationality === 'irish') {
            form.getCheckBox('Check Boxbrith').check()
        } else {
            form.getCheckBox('Check Boxnobrit').check()
        }

        if (res.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            form.getCheckBox('Check Box 38').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box 39').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box 40').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box 41').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box 42').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box 43').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box 44').check()
        } else if (
            res.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Check Box 45').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box 46').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box 47').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box 48').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box 49').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box 50').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box 51').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box 53').check()
        } else if (
            res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Check Box 52').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box 54').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box 55').check()
        }

        if (res.oppDetails.firstLang === 'Yes') {
            form.getTextField('Text Field 20').setText("English")
        }




        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox("Check Box 68").check()
            if (res.employmentDetails.employmentType === 'Self Employed') {
                form.getCheckBox("selfEmployed").check()
            }
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("Check Box 74").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Check Box 73").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("Check Box 72").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("Check Box 71").check()
            }
            if (res.employmentDetails.length === "12+") {
                form.getCheckBox("Check Box 75").check()
            } else if (res.employmentDetails.length === "4-6") {
                form.getCheckBox("Check Box 77").check()
            } else if (res.employmentDetails.length === "7-12") {
                form.getCheckBox("Check Box 76").check()
            } else if (res.employmentDetails.length === "Up to 3") {
                form.getCheckBox("Check Box 78").check()
            }

        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("Check Box 83").check()
            } else {
                form.getCheckBox("Check Box 81").check()
            }

            if (res.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("Check Box 84").check()
            } else if (res.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("Check Box 85").check()
            } else if (res.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("Check Box 86").check()
            } else if (res.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("Check Box 87").check()
            } else if (res.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("Check Box 145").check()
            }
            if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
                form.getCheckBox('Check Box 146').check()
            }


            if (res.employmentDetails.dValue1) {
                form.getCheckBox('Check Box 88').check()
            }
            if (res.employmentDetails.dValue2) {
                form.getCheckBox('Check Box 90').check()
            }
            if (res.employmentDetails.dValue3) {
                form.getCheckBox('Check Box 90').check()
            }
            if (res.employmentDetails.dValue6) {
                form.getCheckBox('Check Box 90').check()
            }
            if (res.employmentDetails.dValue5) {
                form.getCheckBox('Check Box 126').check()
            }
            if (res.employmentDetails.dValue7) {
                form.getCheckBox('Check Box 90').check()
            }

            if (res.employmentDetails.dValue8) {
                form.getCheckBox('Check Box UC').check()
            }
            if (res.employmentDetails.dValue9) {
                form.getCheckBox('Check Box 90').check()
            }
        }


        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Check Box 18').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box 29').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Check Box 21').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Check Box 24').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Check Box 19').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Check Box 25').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Check Box 28').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Check Box 20').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Check Box 23').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Check Box 23').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Check Box 27').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Check Box 22').check()
        }
        if (res.qualificationDetails.englishGrades) {
            form.getTextField('Text Field 50').setText(res.qualificationDetails.englishGrades)
        }
        if (res.qualificationDetails.mathGrades) {
            form.getTextField('Text Field 51').setText(res.qualificationDetails.mathGrades)
        }



        if (res.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('Check Box 31').check()

            if (res.oppDetails.dValue1) {
                form.getCheckBox('Check Box 97').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('Check Box 98').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('Check Box 99').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('Check Box 100').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('Check Box 114').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('Check Box 105').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('Check Box 109').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('Check Box 110').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('Check Box 111').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('Check Box 112').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('Check Box 113').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('Check Box 115').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('Check Box 106').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('Check Box 101').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('Check Box 102').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('Check Box 107').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('Check Box 117').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('Check Box 103').check()
            }
        } else {
            form.getCheckBox('Check Box 30').check()
        }

        if (res.declaration.areYouInCare) {
            form.getCheckBox('Check Box 57').check()
        }
        if (res.declaration.lookAfterSomeoneIll) {
            form.getCheckBox('Check Box 58').check()
        }
        if (res.declaration.childRequiringChildCare) {
            form.getCheckBox('Check Box 62').check()
        }
        if (res.declaration.mentalHealthSupport) {
            form.getCheckBox('Check Box 64').check()
        }

        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('Check Box 59').check()
        }

        form.getCheckBox("Check Box 139").check()
        form.getCheckBox("Check Box 140").check()



        form.getCheckBox("Check Box a").check()
        form.getCheckBox("Check Box b").check()
        form.getCheckBox("Check Box c").check()
        form.getCheckBox("Check Box d").check()
        form.getCheckBox("Check Box e").check()
        form.getCheckBox("Check Box f").check()
        form.getCheckBox("Check Box g").check()


        let date = (String(moment(res.createdAt).format('DD/MM/YYYY')))
        form.getTextField('Text Field 77').setText(date)
        const signImageField = form.getTextField('Text Field 74')
        signImageField.setImage(emblemImage)


        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [ensureHttps(res.nationalId), ensureHttps(res.certificateLink)],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + ((res.nationalId)).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + ((res.certificateLink)).substr(((res.certificateLink)).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.newDeclaration) {
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res),
                { base64: true }
            )
        }
        if (res.oppDetails.perField && res.oppDetails.perField !== '') {
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file(
                'Immigration status.pdf',
                ncgCollegeImmirationFormPDF(res),
                { base64: true }
            )
        }
        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}



export const HandlePDFNWSLC = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const res = await authService.getTestForm(ids[i])
        const formPdfBytes = await fetch('/NWSLC.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.data.data.signature //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text-sign1')
        signImageField.setImage(emblemImage)
        form.getTextField('Text-sing2').setImage(emblemImage)
        form.getTextField('Text-sing3').setImage(emblemImage)

        // New Form Stuff
        form.getTextField('Text4').setText(res.data.data.personalDetails.title)

        form.getTextField('Text6').setText(res.data.data.personalDetails.lastName)
        form.getTextField('Text7').setText(res.data.data.personalDetails.firstName)
        let dobdate = String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        form.getTextField('datedob').setText(dobdate)
        form.getTextField('Text12').setText(res.data.data.personalDetails.nationalInsNo)


        if (res.data.data.personalDetails.gender === 'Female') {
            form.getRadioGroup('Group5').select('Choice2')
        } else {
            form.getRadioGroup('Group5').select('Choice1')
        }

        form.getTextField('Text13').setText(res.data.data.personalDetails.addLine1)
        form.getTextField('Text14').setText(res.data.data.personalDetails.city)
        form.getTextField('Text16').setText(res.data.data.personalDetails.county)

        form.getTextField('Text17').setText(res.data.data.personalDetails.postcode)

        form.getTextField('Text20').setText(
            res.data.data.personalDetails.telephone
        )
        form.getTextField('Text21').setText(res.data.data.personalDetails.telephone)

        form.getTextField('Text22').setText(res.data.data.personalDetails.email)

        if (
            res.data.data.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getRadioGroup('Group6').select('Choice1')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
            form.getRadioGroup('Group6').select('Choice2')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getRadioGroup('Group6').select('Choice3')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getRadioGroup('Group6').select('Choice4')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getRadioGroup('Group6').select('Choice5')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getRadioGroup('Group6').select('Choice6')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getRadioGroup('Group6').select('Choice7')
        } else if (
            res.data.data.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getRadioGroup('Group6').select('Choice8')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
            form.getRadioGroup('Group6').select('Choice9')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getRadioGroup('Group6').select('Choice10')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getRadioGroup('Group6').select('Choice11')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
            form.getRadioGroup('Group6').select('Choice12')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getRadioGroup('Group6').select('Choice13')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
            form.getRadioGroup('Group6').select('Choice14')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getRadioGroup('Group6').select('Choice15')
        } else if (
            res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getRadioGroup('Group6').select('Choice16')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
            form.getRadioGroup('Group6').select('Choice17')
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getRadioGroup('Group6').select('Choice18')
        }


        form.getTextField('Text23').setText(res.data.data.emergencyDetails.emergencyContactName)
        form.getTextField('Text25').setText(res.data.data.emergencyDetails.relationshipToLearner)
        form.getTextField('Text24').setText(res.data.data.emergencyDetails.emergencyTelephone)


        form.getRadioGroup('Group12').select('Choice1')
        if (res.data.data.oppDetails.nationality === 'british') {
            form.getRadioGroup('Group13').select('Choice1')
        } else {
            form.getRadioGroup('Group13').select('Choice2')
        }
        form.getTextField('nationality').setText(res.data.data.oppDetails.nationality)

        if (res.data.data.oppDetails.disabilities === 'Yes') {
            form.getRadioGroup('Group14').select('Choice1')
            let disabilityArray = []
            if (res.data.data.oppDetails.dValue1) {
                disabilityArray.push("4")
            }
            if (res.data.data.oppDetails.dValue2) {
                disabilityArray.push("5")
            }
            if (res.data.data.oppDetails.dValue3) {
                disabilityArray.push("6")
            }
            if (res.data.data.oppDetails.dValue4) {
                disabilityArray.push("7")
            }
            if (res.data.data.oppDetails.dValue5) {
                disabilityArray.push("8")
            }
            if (res.data.data.oppDetails.dValue6) {
                disabilityArray.push("9")
            }
            if (res.data.data.oppDetails.dValue7) {
                disabilityArray.push("10")
            }
            if (res.data.data.oppDetails.dValue8) {
                disabilityArray.push("11")
            }
            if (res.data.data.oppDetails.dValue9) {
                disabilityArray.push("12")
            }
            if (res.data.data.oppDetails.dValue10) {
                disabilityArray.push("13")
            }
            if (res.data.data.oppDetails.dValue11) {
                disabilityArray.push("14")
            }
            if (res.data.data.oppDetails.dValue12) {
                disabilityArray.push("15")
            }
            if (res.data.data.oppDetails.dValue13) {
                disabilityArray.push("16")
            }
            if (res.data.data.oppDetails.dValue14) {
                disabilityArray.push("17")
            }
            if (res.data.data.oppDetails.dValue15) {
                disabilityArray.push("93")
            }
            if (res.data.data.oppDetails.dValue16) {
                disabilityArray.push("95")
            }
            if (res.data.data.oppDetails.dValue17) {
                disabilityArray.push("94")
            }
            if (res.data.data.oppDetails.dValue18) {
                disabilityArray.push("93")
            }
            form.getTextField('Text31').setText(disabilityArray[0] || '')
            form.getTextField('Text32').setText(disabilityArray[1] || '')
            form.getTextField('Text33').setText(disabilityArray[2] || '')
            form.getTextField('Text34').setText(disabilityArray[3] || '')
            form.getTextField('Text35').setText(disabilityArray[4] || '')
        } else {
            form.getRadioGroup('Group14').select('Choice2')
        }


        if (res.data.data.qualificationDetails.level === 'Entry Level') {
            form.getRadioGroup('Group17').select('Choice3')
        } else if (res.data.data.qualificationDetails.level === 'None') {
            form.getRadioGroup('Group17').select('Choice2')
        } else if (res.data.data.qualificationDetails.level === 'Level 1') {
            form.getRadioGroup('Group17').select('Choice5')
        } else if (res.data.data.qualificationDetails.level === 'Level 2') {
            form.getRadioGroup('Group17').select('Choice6')
        } else if (res.data.data.qualificationDetails.level === 'Level 3') {
            form.getRadioGroup('Group17').select('Choice8')
        } else if (res.data.data.qualificationDetails.level === 'Level 4') {
            form.getRadioGroup('Group17').select('Choice10')
        } else if (res.data.data.qualificationDetails.level === 'Level 5') {
            form.getRadioGroup('Group17').select('Choice11')
        } else if (res.data.data.qualificationDetails.level === 'Level 6') {
            form.getRadioGroup('Group17').select('Choice12')
        } else if (res.data.data.qualificationDetails.level === 'Level 7') {
            form.getRadioGroup('Group17').select('Choice13')
        } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
            form.getRadioGroup('Group17').select('Choice13')
        } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
            form.getRadioGroup('Group17').select('Choice7')
        } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
            form.getRadioGroup('Group17').select('Choice9')
        }


        if (res.data.data.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getRadioGroup('Group19').select('Choice2')
        }

        if (res.data.data.qualificationDetails.GCSEEnglishabove) {
            form.getRadioGroup('Group21').select('Choice1')
        } else {
            form.getRadioGroup('Group21').select('Choice2')
        }

        if (res.data.data.qualificationDetails.GCSEMathsabove) {
            form.getRadioGroup('Group20').select('Choice1')
        } else {
            form.getRadioGroup('Group20').select('Choice2')
        }

        form.getTextField('Text40').setText(res.data.data.qualificationDetails.englishGrades)
        form.getTextField('Text39').setText(res.data.data.qualificationDetails.mathGrades)






        if (res.data.data.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.data.data.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            if (res.data.data.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getRadioGroup('Group24').select('Choice1')
            } else if (res.data.data.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getRadioGroup('Group24').select('Choice2')
            } else if (res.data.data.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getRadioGroup('Group24').select('Choice3')
            } else if (res.data.data.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getRadioGroup('Group24').select('Choice4')
            }

        } else if (res.data.data.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.data.data.employmentDetails.lookingForWork === "Yes") {
                form.getRadioGroup('Group27').select('Choice1')
            } else {
                form.getRadioGroup('Group27').select('Choice2')
            }

            if (res.data.data.employmentDetails.unemployedLength === "0-5 months") {
                form.getRadioGroup('Group28').select('Choice1')
            } else if (res.data.data.employmentDetails.unemployedLength === "6-11 months") {
                form.getRadioGroup('Group28').select('Choice3')
            } else if (res.data.data.employmentDetails.unemployedLength === "12-23 months") {
                form.getRadioGroup('Group28').select('Choice5')
            } else if (res.data.data.employmentDetails.unemployedLength === "24-35 months") {
                form.getRadioGroup('Group28').select('Choice2')
            } else if (res.data.data.employmentDetails.unemployedLength === "Over 36 months") {
                form.getRadioGroup('Group28').select('Choice4')
            }


            if (res.data.data.employmentDetails.dValue1) {
                form.getRadioGroup('Group31').select('Choice1')
                form.getRadioGroup('Group31').select('Choice2')
            }
            if (res.data.data.employmentDetails.dValue5) {
                form.getRadioGroup('Group31').select('Choice1')
                form.getRadioGroup('Group31').select('Choice3')
            }
            if (res.data.data.employmentDetails.dValue8) {
                form.getRadioGroup('Group31').select('Choice1')
                form.getRadioGroup('Group31').select('Choice4')
            }
        }

        if (res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getRadioGroup('Group31').select('Choice5')
        }

        let todayDate = (String(moment(res.data.data.createdAt).format('DD/MM/YYYY')))
        form.getTextField('todaydate1').setText(todayDate)
        form.getTextField('todaydate2').setText(todayDate)
        form.getTextField('todaydate3').setText(todayDate)
        // form.getTextField('Text Field 97').setText(res.data.data.detailsFormData.appliedCourse)

        //declaration
        if (res.data.data.declaration.houseHoldMem) {
            form.getRadioGroup('Group29').select('Choice1')
        }
        if (res.data.data.declaration.houseHoldMem2) {
            form.getRadioGroup('Group29').select('Choice2')
        }
        if (res.data.data.declaration.singleHouse) {
            form.getRadioGroup('Group29').select('Choice3')
        }
        if (res.data.data.declaration.noneOfThese) {
            form.getRadioGroup('Group29').select('Choice5')
        }
        if (res.data.data.declaration.confirmation)
            form.getRadioGroup('Group29').select('Choice4')


        if (res.data.data.declaration.prefContact1) form.getCheckBox('Check Box46').check()
        if (res.data.data.declaration.prefContact2) form.getCheckBox('Check Box47').check()
        if (res.data.data.declaration.prefContact) form.getCheckBox('Check Box48').check()
        if (res.data.data.declaration.aboutCourse)
            form.getCheckBox('Check Box44').check()
        if (res.data.data.declaration.forSurveys)
            form.getCheckBox('Check Box45').check()

        form.getCheckBox('Check Box49').check()

        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        var images = [res.data.data.nationalId, res.data.data.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
        ).file(
            res.data.data.personalDetails.firstName +
            res.data.data.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.data.data.nationalId) {
            const imageBlob = fetch(res.data.data.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('National_ID.' + (res.data.data.nationalId).substr((res.data.data.nationalId).length - 3), imageBlob)
        }
        if (res.data.data.certificateLink) {
            const imageBlob = fetch(res.data.data.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Certificate.' + (res.data.data.certificateLink).substr((res.data.data.certificateLink).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment1) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment1.' + (res.data.data.oppDetails.attachment1).substr((res.data.data.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment2) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment2.' + (res.data.data.oppDetails.attachment2).substr((res.data.data.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment3) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment3.' + (res.data.data.oppDetails.attachment3).substr((res.data.data.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment4) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment4.' + (res.data.data.oppDetails.attachment4).substr((res.data.data.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence.' + (res.data.data.employmentDetails.evidence).substr((res.data.data.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence1) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence1.' + (res.data.data.employmentDetails.evidence1).substr((res.data.data.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence2) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence2.' + (res.data.data.employmentDetails.evidence2).substr((res.data.data.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.data.data.assessment) {
            if (res.data.data.assessment.english) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res.data.data),
                    { base64: true }
                )
            }
            if (res.data.data.assessment.answer1) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res.data.data),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


export const HandleWightCollegePDF = async (ids) => {
    var zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const res = await authService.getTestForm(ids[i])
        const formPdfBytes = await fetch('/wightPdf.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.data.data.signature //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text-sign1')
        signImageField.setImage(emblemImage)

        // New Form Stuff
        form.getTextField('Title_es_:signer').setText(res.data.data.personalDetails.title)

        form.getTextField('Surname_es_:signer').setText(res.data.data.personalDetails.lastName)
        form.getTextField('Forenames_es_:signer').setText(res.data.data.personalDetails.firstName)
        let dobdate = String(moment(res.data.data.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        let createdDate = String(moment(res.data.data.createdAt).format('DD/MM/YYYY'))
        form.getTextField('DateSigned').setText(createdDate)
        form.getTextField('DateOfBirth_es_:signer').setText(dobdate)
        form.getTextField('NINumber_es_:signer').setText(res.data.data.personalDetails.nationalInsNo)
        form.getTextField('Nationality_es_:signer').setText(res.data.data.oppDetails.nationality)


        if (res.data.data.personalDetails.gender === 'Female') {
            form.getRadioGroup('Sex').select('Female')
        } else {
            form.getRadioGroup('Sex').select('Male')
        }

        form.getTextField('Address_es_:signer').setText(res.data.data.personalDetails.addLine1)
        form.getTextField('AddressTown_es_:signer').setText(res.data.data.personalDetails.city)
        form.getTextField('AddressCounty_es_:signer').setText(res.data.data.personalDetails.county)

        form.getTextField('Postcode_es_:signer').setText(res.data.data.personalDetails.postcode)

        form.getTextField('Telephone_es_:signer').setText(
            res.data.data.personalDetails.telephone
        )

        form.getTextField('Email_es_:signer').setText(res.data.data.personalDetails.email)

        if (res.data.data.oppDetails.inWhichCountryDoYouLive)
            form.getTextField('CountryOfResidence_es_:signer').setText(res.data.data.oppDetails.inWhichCountryDoYouLive)

        if (res.data.data.oppDetails.nationality === 'british') {
            form.getRadioGroup('Residency_es_:signer').select('UK')
        } else if (res.data.data.oppDetails.statementScheme === 'Yes') {
            form.getRadioGroup('Residency_es_:signer').select('EU')
        } else {
            form.getRadioGroup('Residency_es_:signer').select('NonUK')
        }

        if (
            res.data.data.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getCheckBox('CheckBox-FINFpLVGRD').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('CheckBox-RnPh6eL20R').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('CheckBox-3cEULcfeL2').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('CheckBox-7oXVOprFPn').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('CheckBox-g3hDMPXDj9').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('CheckBox-lO3bZT7SNz').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('CheckBox-TcCyH3NpLi').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background') {
            form.getCheckBox('CheckBox-HPk0lP6QlZ').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('CheckBox-sEpu3uhSsb').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('CheckBox-Un-ZbaG-9w').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('CheckBox-V_GXXfHz1a').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('CheckBox-ZtgDvtDXF0').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('CheckBox-mEAyrN_cw0').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('CheckBox-OOmVsx7y8q').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('CheckBox-LFxa7YOxqc').check()
        } else if (
            res.data.data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('CheckBox-FjmprPcVfX').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('CheckBox-5PckY78Sze').check()
        } else if (res.data.data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('CheckBox-a7HjVGO-JM').check()
        }



        if (res.data.data.oppDetails.disabilities === 'Yes') {
            if (res.data.data.oppDetails.dValue1) {
                form.getCheckBox('LDD Visual impairment_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue2) {
                form.getCheckBox('LDD Hearing impairment_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue3) {
                form.getCheckBox('LDD Disability affecting mobility_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue4) {
                form.getCheckBox('LDD Profound complex disabilities_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue5) {
                form.getCheckBox('LDD Social and emotional difficulties_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue6) {
                form.getCheckBox('LDD Mental health difficulty_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue7) {
                form.getCheckBox('LDD Moderate learning difficulty_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue8) {
                form.getCheckBox('LDD Severe learning difficulty_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue9) {
                form.getCheckBox('LDD Dyslexia_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue10) {
                form.getCheckBox('LDD Dyscalculia_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue11) {
                form.getCheckBox('LDD Autism spectrum disorder_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue12) {
                form.getCheckBox('LDD Aspergers syndrome_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue13) {
                form.getCheckBox('LDD Temporary disability_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue14) {
                form.getCheckBox('LDD Speech, Language and Communication Needs_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue15) {
                form.getCheckBox('LDD Other physical disability_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue16) {
                form.getCheckBox('LDD Multiple disabilities_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue17) {
                form.getCheckBox('LDD Multiple learning difficulties_es_:signer').check()
            }
            if (res.data.data.oppDetails.dValue18) {
                form.getCheckBox('LDD Other physical disability_es_:signer').check()
            }
        } else {
            form.getRadioGroup('PrimaryLDDOption_es_:signer').select('None')
        }



        if (res.data.data.qualificationDetails.level === 'Entry Level') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level1')
        } else if (res.data.data.qualificationDetails.level === 'None') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('None')
        } else if (res.data.data.qualificationDetails.level === 'Level 1') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level1')
        } else if (res.data.data.qualificationDetails.level === 'Level 2') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level2')
        } else if (res.data.data.qualificationDetails.level === 'Level 3') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level3')
        } else if (res.data.data.qualificationDetails.level === 'Level 4') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level4')
        } else if (res.data.data.qualificationDetails.level === 'Level 5') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level5')
        } else if (res.data.data.qualificationDetails.level === 'Level 6') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level6')
        } else if (res.data.data.qualificationDetails.level === 'Level 7') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level7')
        } else if (res.data.data.qualificationDetails.level === 'Level 7 and above') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('Level7')
        } else if (res.data.data.qualificationDetails.level === 'Full Level 2') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('FullLevel2')
        } else if (res.data.data.qualificationDetails.level === 'Full Level 3') {
            form.getRadioGroup('PriorAttainment_es_:signer').select('FullLevel3')
        }




        if (res.data.data.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.data.data.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getRadioGroup('EmploymentStatus_es_:signer').select('Employed')
            if (res.data.data.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getRadioGroup('HoursOfWork_es_:signer').select('0-10')
            } else if (res.data.data.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getRadioGroup('HoursOfWork_es_:signer').select('11-20')
            } else if (res.data.data.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getRadioGroup('HoursOfWork_es_:signer').select('21-30')
            } else if (res.data.data.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getRadioGroup('HoursOfWork_es_:signer').select('31+')
            }

        } else if (res.data.data.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.data.data.employmentDetails.lookingForWork === "Yes") {
                form.getRadioGroup('EmploymentStatus_es_:signer').select('Not Employed, Looking for work')
            } else {
                form.getRadioGroup('EmploymentStatus_es_:signer').select('Not Employed, Not Looking for work')
            }

            if (res.data.data.employmentDetails.unemployedLength === "0-5 months") {
                form.getRadioGroup('LengthOfUnemployment_es_:signer').select('<6')
            } else if (res.data.data.employmentDetails.unemployedLength === "6-11 months") {
                form.getRadioGroup('LengthOfUnemployment_es_:signer').select('6-11')
            } else if (res.data.data.employmentDetails.unemployedLength === "12-23 months") {
                form.getRadioGroup('LengthOfUnemployment_es_:signer').select('12-23')
            } else if (res.data.data.employmentDetails.unemployedLength === "24-35 months") {
                form.getRadioGroup('LengthOfUnemployment_es_:signer').select('24-35')
            } else if (res.data.data.employmentDetails.unemployedLength === "Over 36 months") {
                form.getRadioGroup('LengthOfUnemployment_es_:signer').select('36+')
            }


            if (res.data.data.employmentDetails.dValue1) {
                form.getCheckBox('Benefit Job Seekers Allowance_es_:signer').check()
            }
            if (res.data.data.employmentDetails.dValue5) {
                form.getCheckBox('Benefit Employment Support Allowance_es_:signer').check()
            }
            if (res.data.data.employmentDetails.dValue8) {
                form.getCheckBox('Benefit Active Universal Credit_es_:signer').check()
            }
            if (res.data.data.employmentDetails.dValue2 ||
                res.data.data.employmentDetails.dValue3 ||
                res.data.data.employmentDetails.dValue6 ||
                res.data.data.employmentDetails.dValue7 ||
                res.data.data.employmentDetails.dValue9) {
                form.getCheckBox('Benefit other_es_:signer').check()
            }

        }

        if (res.data.data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.data.data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getRadioGroup('LowIncome_es_:signer').select('Yes')
        } else {
            form.getRadioGroup('LowIncome_es_:signer').select('No')
        }

        //declaration
        if (res.data.data.declaration.houseHoldMem) {
            form.getCheckBox('HouseholdSingleAdult_es_:signer').check()
        }
        if (res.data.data.declaration.houseHoldMem2) {
            form.getCheckBox('HouseholdNobodyEmployed_es_:signer').check()
        }
        if (res.data.data.declaration.singleHouse) {
            form.getCheckBox('HouseholdDependentChildren_es_:signer').check()
        }
        if (res.data.data.declaration.noneOfThese) {
            form.getCheckBox('HouseholdNoneApply_es_:signer').check()
        }
        if (res.data.data.declaration.confirmation)
            form.getCheckBox('HouseholdPreferNotToSay').check()

        form.getTextField('Text-course').setText(res.data.data.detailsFormData.appliedCourse)

        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        var images = [res.data.data.nationalId, res.data.data.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
        ).file(
            res.data.data.personalDetails.firstName +
            res.data.data.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.data.data.nationalId) {
            const imageBlob = fetch(res.data.data.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('National_ID.' + (res.data.data.nationalId).substr((res.data.data.nationalId).length - 3), imageBlob)
        }
        if (res.data.data.certificateLink) {
            const imageBlob = fetch(res.data.data.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Certificate.' + (res.data.data.certificateLink).substr((res.data.data.certificateLink).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment1) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment1.' + (res.data.data.oppDetails.attachment1).substr((res.data.data.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment2) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment2.' + (res.data.data.oppDetails.attachment2).substr((res.data.data.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment3) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment3.' + (res.data.data.oppDetails.attachment3).substr((res.data.data.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.data.data.oppDetails.attachment4) {
            const imageBlob = fetch(res.data.data.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('Attachment4.' + (res.data.data.oppDetails.attachment4).substr((res.data.data.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence.' + (res.data.data.employmentDetails.evidence).substr((res.data.data.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence1) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence1.' + (res.data.data.employmentDetails.evidence1).substr((res.data.data.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.data.data.employmentDetails.evidence2) {
            const imageBlob = fetch(res.data.data.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
            ).file('evidence2.' + (res.data.data.employmentDetails.evidence2).substr((res.data.data.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.data.data.assessment) {
            if (res.data.data.assessment.english) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res.data.data),
                    { base64: true }
                )
            }
            if (res.data.data.assessment.answer1) {
                zip.folder(
                    res.data.data.personalDetails.firstName + res.data.data.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res.data.data),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


export const handleSuttonColdFieldPDF = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/SuttonColdfield.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature)
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

        form.getTextField('Text-1UXbVnNakr').setText(res.personalDetails.title)

        form.getTextField('Text Field 5').setText(res.personalDetails.lastName + ' ' + res.personalDetails.middleName)
        form.getTextField('Text Field 3').setText(res.personalDetails.firstName)

        form.getTextField('Text Field 6').setText(res.personalDetails.nationalInsNo)


        let testDate = (String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY')))
        form.getTextField('dateofBirth').setText(testDate)


        form.getTextField('addressLine').setText(res.personalDetails.addLine1)
        form.getTextField('city-text').setText(res.personalDetails.city)
        form.getTextField('county').setText(res.personalDetails.county)
        form.getTextField('postcode').setText(res.personalDetails.postcode)
        form.getTextField('Text Field 10').setText(res.personalDetails.telephone)
        form.getTextField('mobileField').setText(res.personalDetails.telephone)
        form.getTextField('Text Field 83').setText(res.personalDetails.email)
        form.getTextField('yearsat-Text125').setText(res.personalDetails.yearsAtAdd + ' years')

        const today = moment();
        form.getTextField('Text-EfApGjiAEa').setText(today.diff(testDate, 'years').toString())

        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('Check Box 3').check()
        } else {
            form.getCheckBox('Check Box 2').check()
        }




        if (res.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            form.getCheckBox('Check Box 38').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box 39').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box 40').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box 41').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box 42').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box 43').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box 44').check()
        } else if (
            res.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Check Box 45').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box 46').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box 47').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box 48').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box 49').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box 50').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box 51').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box 53').check()
        } else if (
            res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Check Box 52').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box 54').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box 55').check()
        }


        if (res.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('CheckBox-disable-yes').check()

            if (res.oppDetails.dValue1) {
                form.getCheckBox('Visual impairment').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('Hearing impairment').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('Disability affecting mobility').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('Profound complex disabilities').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('Social and Emotional Difficulties').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('Mental health difficulty').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('Moderate difficulties').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('Severe difficulties').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('Dyslexia').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('Dyscalculia').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('Autism spectrum disorder').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('Aspergers Syndrome').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('Temporary disability after illness').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('Speech Language and').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('Other physical disability').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('Other_2').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('Other_2').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('Other').check()
            }
        } else {
            form.getCheckBox('CheckBox-disable-no').check()
        }


        form.getTextField('Text Field 22').setText(res.oppDetails.nationality)

        if (res.oppDetails.nationality === 'british') {
            form.getCheckBox('CheckBox-oUI2jSMzoM').check()
        }
        if (res.oppDetails.statementScheme === 'Yes') {
            form.getCheckBox('CheckBox-6MyLV7NDEm').check()
        }
        if (res.oppDetails.perField === 'Family Member of EU citizen') {
            form.getCheckBox('CheckBox-HZp0XOeQVL').check()
        }
        if (res.oppDetails.perField === 'British/EU/EEA/Citizen') {
            form.getCheckBox('CheckBox-HZp0XOeQVL').check()
        }


        if (res.oppDetails.perField === 'Asylum Seeker') {
            form.getCheckBox('Asylum Seeker').check()
        } else if (res.oppDetails.perField === 'Indefinite Leave') {
            form.getCheckBox('Indefinite Leave').check()
        } else if (res.oppDetails.perField === 'Exceptional / Discretionary Leave') {
            form.getCheckBox('Exceptional / Discretionary Leave').check()
        } else if (res.oppDetails.perField === 'Humanitarian Protection') {
            form.getCheckBox('Humanitarian Protection').check()
        } else if (res.oppDetails.perField === 'Refugee') {
            form.getCheckBox('Refugee').check()
        }

        if (res.declaration.houseHoldMem) {
            form.getCheckBox('houseHoldMem').check()
        }
        if (res.declaration.houseHoldMem2) {
            form.getCheckBox('houseHoldMem2').check()
        }
        if (res.declaration.singleHouse) {
            form.getCheckBox('singleHouse').check()
        }
        if (res.declaration.noneOfThese) {
            form.getCheckBox('noneOfThese').check()
        }
        if (res.declaration.confirmation) {
            form.getCheckBox('confirmation').check()
        }

        if (res.qualificationDetails.englishGrades) {
            form.getTextField('Text subject english').setText("English")
            form.getTextField('Text subject english grade').setText(res.qualificationDetails.englishGrades)
        }
        if (res.qualificationDetails.mathGrades) {
            form.getTextField('Text subject maths').setText("Maths")
            form.getTextField('Text subject maths grade').setText(res.qualificationDetails.mathGrades)
        }



        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox("Check Box 68").check()
            form.getCheckBox("CheckBox-pJaK1JmtQg").check()
            form.getTextField('employeename').setText(res.employmentDetails.employerName)
            form.getTextField('employment hours').setText(res.employmentDetails.hoursPerWeek)
            if (res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
                res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
                form.getCheckBox('CheckBox-x1BdHlYaSH').check()
            } else {
                form.getCheckBox('CheckBox-N2VwymPCaC').check()
            }

        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            form.getCheckBox("unemployed checkbox").check()
            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("Check Box 83").check()
            } else {
                form.getCheckBox("Check Box 8123").check()
            }

            form.getTextField('employment Length').setText(res.employmentDetails.unemployedLength)
            let benefits = []

            if (res.employmentDetails.dValue1) {
                benefits.push('JSA')
            }
            if (res.employmentDetails.dValue2) {
                benefits.push('Income Support')
            }
            if (res.employmentDetails.dValue3) {
                benefits.push('Council Tax Benefit')
            }
            if (res.employmentDetails.dValue6) {
                benefits.push('Incapacity Benefit')
            }
            if (res.employmentDetails.dValue5) {
                benefits.push('ESA')
            }
            if (res.employmentDetails.dValue7) {
                benefits.push('Housing Benefit')
            }
            if (res.employmentDetails.dValue9) {
                benefits.push('Employed but on low wage')
            }

            if (res.employmentDetails.dValue8) {
                benefits.push('UC')
            }
            form.getTextField('benefits').setText(benefits.join())
        }

        if (res.typeOfProof === 'Passport') {
            form.getCheckBox('CheckBox-passport').check()
        } else if (res.typeOfProof === 'Drivers licence') {
            form.getCheckBox('CheckBox-Driverslicence').check()
        } else if (res.typeOfProof === 'Birth certificate') {
            form.getCheckBox('CheckBox-Birthcertificate').check()
        }


        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Check Box 18').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box 29').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Check Box 21').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Check Box 24').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Check Box 19').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Check Box 25').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Check Box 28').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Check Box 20').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Check Box 23').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Check Box 23').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Check Box 27').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Check Box 22').check()
        }

        if (res.declaration.prefContact1) form.getCheckBox('By post').check()
        if (res.declaration.prefContact2) form.getCheckBox('By phone').check()
        if (res.declaration.prefContact) form.getCheckBox('By email').check()
        if (res.declaration.aboutCourse)
            form.getCheckBox('About courses or learning opportunities').check()
        if (res.declaration.forSurveys)
            form.getCheckBox('For surveys and research').check()






        form.getTextField('last name').setText(res.personalDetails.firstName + ' ' + res.personalDetails.lastName)
        let date = (String(moment(res.createdAt).format('DD/MM/YYYY')))
        form.getTextField('last date').setText(date)

        const signImageField = form.getTextField('Text Field 74')
        signImageField.setImage(emblemImage)


        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [ensureHttps(res.nationalId), ensureHttps(res.certificateLink)],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + ((res.nationalId)).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.newDeclaration) {
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res),
                { base64: true }
            )
        }
        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


// SCCU Group  College PDF
export const handleSccuPDF = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/SccaGroup.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text-sign1')
        signImageField.setImage(emblemImage)

        // New Form Stuff

        form.getTextField('Surname_es_:signer').setText(res.personalDetails.lastName)
        form.getTextField('Forenames_es_:signer').setText(res.personalDetails.firstName)
        form.getTextField('title_en').setText(res.personalDetails.title)
        form.getTextField('dob_en').setText(res.personalDetails.dob)
        form.getTextField('NINumber_es_:signer').setText(res.personalDetails.nationalInsNo)
        form.getTextField('Text-gender').setText(res.personalDetails.gender)
        form.getTextField('Text-ethnic').setText(res.oppDetails.ethnicOrigin)

        form.getTextField('Address_es_:signer').setText(res.personalDetails.addLine1)
        form.getTextField('AddressTown_es_:signer').setText(res.personalDetails.city)
        form.getTextField('AddressCounty_es_:signer').setText(res.personalDetails.county)

        form.getTextField('Postcode_es_:signer').setText(res.personalDetails.postcode)

        form.getTextField('Telephone_es_:signer').setText(res.personalDetails.telephone)

        form.getTextField('Email_es_:signer').setText(res.personalDetails.email)

        let disabilitiesArr = []
        if (res.oppDetails.dValue1) {
            disabilitiesArr.push('Visual impairment')
        }
        if (res.oppDetails.dValue2) {
            disabilitiesArr.push('Hearing impairment')
        }
        if (res.oppDetails.dValue3) {
            disabilitiesArr.push('Disability affecting mobility')
        }
        if (res.oppDetails.dValue4) {
            disabilitiesArr.push('Profound complex disabilities')
        }
        if (res.oppDetails.dValue5) {
            disabilitiesArr.push('Social and Emotional Difficulties')
        }
        if (res.oppDetails.dValue6) {
            disabilitiesArr.push('Mental health difficulty')
        }
        if (res.oppDetails.dValue7) {
            disabilitiesArr.push('Moderate difficulties')
        }
        if (res.oppDetails.dValue8) {
            disabilitiesArr.push('Severe difficulties')
        }
        if (res.oppDetails.dValue9) {
            disabilitiesArr.push('Dyslexia')
        }
        if (res.oppDetails.dValue10) {
            disabilitiesArr.push('Dyscalculia')
        }
        if (res.oppDetails.dValue11) {
            disabilitiesArr.push('Autism spectrum disorder')
        }
        if (res.oppDetails.dValue12) {
            disabilitiesArr.push('Aspergers Syndrome')
        }
        if (res.oppDetails.dValue13) {
            disabilitiesArr.push('Temporary disability after illness')
        }
        if (res.oppDetails.dValue14) {
            disabilitiesArr.push('Speech Language and')
        }
        if (res.oppDetails.dValue15) {
            disabilitiesArr.push('Other physical disability')
        }
        if (res.oppDetails.dValue16) {
            disabilitiesArr.push('Medical condition')
        }
        if (res.oppDetails.dValue17) {
            disabilitiesArr.push('learning-difficulty')
        }
        if (res.oppDetails.dValue18) {
            disabilitiesArr.push('Other')
        }
        if (res.oppDetails.disabilities === 'Yes') {
            form.getTextField('Text-disablilites-ask').setText("Yes")
            form.getTextField('Text-disablilites').setText(disabilitiesArr.toString())
        } else {
            form.getTextField('Text-disablilites-ask').setText("No")
        }

        if (res.employmentDetails.employmentType) {
            form.getTextField('Text-employmentStatus').setText(res.employmentDetails.employmentType)
        } else {
            form.getTextField('Text-employmentStatus').setText(res.employmentDetails.employementStatus)
        }

        if (res.employmentDetails.length)
            form.getTextField('Text-employmentLength').setText(res.employmentDetails.length + ' months')
        if (res.employmentDetails.hoursPerWeek)
            form.getTextField('Text-employmentHours').setText(res.employmentDetails.hoursPerWeek)
        if (res.employmentDetails.unemployedLength)
            form.getTextField('Text-unemployedLength').setText(res.employmentDetails.unemployedLength)


        let benefits = []
        if (res.employmentDetails.dValue1) {
            benefits.push('JSA')
        }
        if (res.employmentDetails.dValue2) {
            benefits.push('Income Support')
        }
        if (res.employmentDetails.dValue3) {
            benefits.push('Council Tax Benefit')
        }
        if (res.employmentDetails.dValue6) {
            benefits.push('Incapacity Benefit')
        }
        if (res.employmentDetails.dValue5) {
            benefits.push('ESA')
        }
        if (res.employmentDetails.dValue7) {
            benefits.push('Housing Benefit')
        }
        if (res.employmentDetails.dValue9) {
            benefits.push('Employed but on low wage')
        }

        if (res.employmentDetails.dValue8) {
            benefits.push('UC')
        }

        form.getTextField('Text-RJBa9lOd8k').setText(benefits.join())
        if (res.employmentDetails.employerName) {
            form.getTextField('Text163').setText(res.employmentDetails.employerName);
        }
        if (res.oppDetails.ukStayPermission12Month) {
            form.getCheckBox('CheckBox-km7Rjzhi0m').check()
        }


        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        form.getTextField('DateSigned').setText(date)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


// Hertfordshire PDF
export const handleHertfordshirePDF = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/hertfordshire.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text-sign1')
        signImageField.setImage(emblemImage)

        // New Form Stuff

        form.getTextField('Surname_es_:signer').setText(res.personalDetails.lastName)
        form.getTextField('Forenames_es_:signer').setText(res.personalDetails.firstName)


        form.getTextField('Surname_es_:f1').setText(res.personalDetails.lastName)
        form.getTextField('Forenames_es_:f1').setText(res.personalDetails.firstName)
        if (res.personalDetails.title === 'Mr') {
            form.getCheckBox('mr-checkbox').check()
        } else if (res.personalDetails.title === 'Mrs') {
            form.getCheckBox('Mrs-checkbox').check()
        } else if (res.personalDetails.title === 'Ms') {
            form.getCheckBox('Ms-checkbox').check()
        } else if (res.personalDetails.title === 'Miss') {
            form.getCheckBox('Miss-checkbox').check()
        } else {
            form.getCheckBox('Others').check()
        }

        form.getTextField('Address_es_:signer').setText(res.personalDetails.addLine1)
        form.getTextField('AddressTown_es_:signer').setText(res.personalDetails.city)
        form.getTextField('AddressCounty_es_:signer').setText(res.personalDetails.county)
        form.getTextField('Postcode_es_:signer').setText(res.personalDetails.postcode)
        form.getTextField('Telephone_es_:signer').setText(res.personalDetails.telephone)
        form.getTextField('Email_es_:signer').setText(res.personalDetails.email)

        let dobdate = String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        form.getTextField('DateOfBirth').setText(dobdate)

        let yearDOB = parseInt(String(moment(res.personalDetails.dob).year()))
        let monthDOB = parseInt(String(moment(res.personalDetails.dob).month()))
        let calculatedAge = 2023 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }
        form.getTextField('Text118').setText(calculatedAge.toString())

        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('CheckBox-female').check()
        } else {
            form.getCheckBox('CheckBox-male').check()
        }


        form.getTextField('NINumber_es_:signer').setText(res.personalDetails.nationalInsNo)

        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('criminal-yes').check()
        } else {
            form.getCheckBox('criminal-no').check()
        }
        if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getCheckBox('fullTimeEducationElse').check()
        } else {
            form.getCheckBox('fullTimeEducationElse-no').check()
        }

        form.getTextField('Text129').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text130').setText(res.emergencyDetails.relationshipToLearner)
        form.getTextField('Text131').setText(res.emergencyDetails.emergencyTelephone)
        form.getTextField('countryofbirth').setText(res.oppDetails.countryOfBirth)
        form.getTextField('nationality').setText(res.oppDetails.nationality)
        form.getTextField('CountryOfResidence_es_:signer').setText(res.oppDetails.inWhichCountryDoYouLive)
        form.getCheckBox('CheckBox-CaM2es-vU2').check()

        if (res.oppDetails.perField === 'Asylum Seeker') {
            form.getCheckBox('CheckBox-T-nNOKH6Fj').check()
        } else if (res.oppDetails.perField === 'Indefinite Leave') {
            form.getCheckBox('CheckBox-UR3qjLIhJF').check()
        } else if (res.oppDetails.perField === 'Exceptional / Discretionary Leave') {
            form.getCheckBox('CheckBox-8kb1frgotz').check()
        } else if (res.oppDetails.perField === 'Humanitarian Protection') {
            form.getCheckBox('CheckBox-8kb1frgotz').check()
        } else if (res.oppDetails.perField === 'Refugee') {
            form.getCheckBox('CheckBox-7sL5eoGJL8').check()
        } else if (res.oppDetails.perField === 'Family Member of EU citizen') {
            form.getCheckBox('CheckBox-BvvE0HwtM_').check()
        } else if (res.oppDetails.perField === 'British/EU/EEA/Citizen') {
            form.getCheckBox('CheckBox-AcuT7bEqtB').check()
        }

        if (res.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            form.getCheckBox('Check Box 38').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box 39').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box 40').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box 41').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box 42').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box 43').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box 44').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background') {
            form.getCheckBox('Check Box 45').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box 46').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box 47').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box 48').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box 49').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box 50').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box 51').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box 53').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
            form.getCheckBox('Check Box 52').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box 54').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box 55').check()
        }

        if (res.oppDetails.dValue1) {
            form.getCheckBox('Visual impairment').check()
        }
        if (res.oppDetails.dValue2) {
            form.getCheckBox('Hearing impairment').check()
        }
        if (res.oppDetails.dValue3) {
            form.getCheckBox('Disability affecting mobility').check()
        }
        if (res.oppDetails.dValue4) {
            form.getCheckBox('Profound complex disabilities').check()
        }
        if (res.oppDetails.dValue5) {
            form.getCheckBox('Social and Emotional Difficulties').check()
        }
        if (res.oppDetails.dValue6) {
            form.getCheckBox('Mental health difficulty').check()
        }
        if (res.oppDetails.dValue7) {
            form.getCheckBox('Moderate difficulties').check()
        }
        if (res.oppDetails.dValue8) {
            form.getCheckBox('Severe difficulties').check()
        }
        if (res.oppDetails.dValue9) {
            form.getCheckBox('Dyslexia').check()
        }
        if (res.oppDetails.dValue10) {
            form.getCheckBox('Dyscalculia').check()
        }
        if (res.oppDetails.dValue11) {
            form.getCheckBox('Autism spectrum disorder').check()
        }
        if (res.oppDetails.dValue12) {
            form.getCheckBox('Aspergers Syndrome').check()
        }
        if (res.oppDetails.dValue13) {
            form.getCheckBox('Temporary disability after illness').check()
        }
        if (res.oppDetails.dValue14) {
            form.getCheckBox('Speech Language and').check()
        }
        if (res.oppDetails.dValue15) {
            form.getCheckBox('Other physical disability').check()
        }
        if (res.oppDetails.dValue16) {
            form.getCheckBox('Other_2').check()
        }
        if (res.oppDetails.dValue17) {
            form.getCheckBox('Other_2').check()
        }
        if (res.oppDetails.dValue18) {
            form.getCheckBox('Other').check()
        }

        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('CheckBox-3udI8lxEvA').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('CheckBox-o8KBM2F7Z1').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('CheckBox-PalchQ_aol').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('CheckBox-wmigTW5RYp').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('CheckBox-p_Xb4jlK2i').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('CheckBox-SzAm4xacQq').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('CheckBox-WSgt5TQv6k').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('CheckBox-1Hed8hmt4t').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('CheckBox-Q0-rQaiMFp').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('CheckBox-Q0-rQaiMFp').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('CheckBox-Roi65vPnKv').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('CheckBox-FxkpPDDlFA').check()
        }

        form.getTextField('Text-english').setText(res.qualificationDetails.englishGrades)
        form.getTextField('Text-math').setText(res.qualificationDetails.mathGrades)

        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {

            if (res.employmentDetails.employmentType === 'Self Employed') {
                form.getCheckBox("selfEmployed").check()
            } else {
                form.getCheckBox("Employed").check()
            }
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("0 to 10 hours per week").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Between 11 to 20 hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("21 to 30 hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("31 hours").check()
            }

        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("unemployedlookingforwork").check()
            } else {
                form.getCheckBox("unemployednotlookwork").check()
            }

            if (res.employmentDetails.unemployedLength === '0-5 months') {
                form.getCheckBox('Check Box214').check()
            } else if (res.employmentDetails.unemployedLength === '6-11 months') {
                form.getCheckBox('Check Box215').check()
            } else if (res.employmentDetails.unemployedLength === '12-23 months') {
                form.getCheckBox('Check Box216').check()
            } else if (res.employmentDetails.unemployedLength === '24-35 months') {
                form.getCheckBox('Check Box217').check()
            } else if (res.employmentDetails.unemployedLength === 'Over 36 months') {
                form.getCheckBox('Check Box218').check()
            }

            let benefits = []
            if (res.employmentDetails.dValue1) {
                form.getCheckBox('JSA').check()
            }
            if (res.employmentDetails.dValue2) {
                benefits.push('Income Support')
            }
            if (res.employmentDetails.dValue3) {
                benefits.push('Council Tax Benefit')
            }
            if (res.employmentDetails.dValue6) {
                benefits.push('Incapacity Benefit')
            }
            if (res.employmentDetails.dValue5) {
                form.getCheckBox('esa').check()
            }
            if (res.employmentDetails.dValue7) {
                benefits.push('Housing Benefit')
            }
            if (res.employmentDetails.dValue9) {
                benefits.push('Employed but on low wage')
            }

            if (res.employmentDetails.dValue8) {
                form.getCheckBox('UCcheckl').check()
            }
            form.getTextField('benefits').setText(benefits.join())
        }

        if (res.declaration.prefContact1) form.getCheckBox('By post').check()
        if (res.declaration.prefContact2) form.getCheckBox('By phone').check()
        if (res.declaration.prefContact) form.getCheckBox('By email').check()
        if (res.declaration.aboutCourse)
            form.getCheckBox('About courses or learning opportunities').check()
        if (res.declaration.forSurveys)
            form.getCheckBox('For surveys and research').check()


        form.getTextField('Text-course').setText(res.detailsFormData.appliedCourse)
        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        form.getTextField('text-createdAt').setText(date)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then(() => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}

// PortsMouth PDF
export const handlePortsMouth = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/Portsmouth.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Student Signature')
        signImageField.setImage(emblemImage)

        // New Form Stuff

        // New Form Stuff
        form.getTextField('Title').setText(res.personalDetails.title)
        form.getTextField('Surname').setText(res.personalDetails.lastName)
        form.getTextField('Forenames').setText(res.personalDetails.firstName)



        form.getTextField('Home address Postcode').setText(res.personalDetails.addLine1 + ", " + res.personalDetails.city + ', ' + res.personalDetails.county + ', ' + res.personalDetails.postcode)
        form.getTextField('Mobile telephone').setText(res.personalDetails.telephone)
        form.getTextField('Email address').setText(res.personalDetails.email)

        let dobdate = String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        form.getTextField('Date of birth').setText(dobdate)
        form.getTextField('Age').setText(res.personalDetails.age)


        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('Check Box6').check()
        } else {
            form.getCheckBox('Check Box5').check()
        }


        form.getTextField('NI number').setText(res.personalDetails.nationalInsNo)


        form.getTextField('Name').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Relationship to you').setText(res.emergencyDetails.relationshipToLearner)
        form.getTextField('Mobile number').setText(res.emergencyDetails.emergencyTelephone)


        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('Check Box3').check()
        } else {
            form.getCheckBox('Check Box4').check()
        }


        if (res.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            form.getCheckBox('Check Box7').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box8').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box9').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box10').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box11').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box12').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box13').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background') {
            form.getCheckBox('Check Box14').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box15').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box17').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box18').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box19').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box20').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box21').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box22').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
            form.getCheckBox('Check Box23').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box16').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box24').check()
        }



        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Check Box26').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box25').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Check Box27').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Check Box28').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Check Box29').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Check Box30').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Check Box31').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Check Box32').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Check Box33').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Check Box33').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Check Box28').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Check Box29').check()
        }

        form.getTextField('Text5').setText(res.oppDetails.nationality)

        if (res.oppDetails.nationality === 'british' || res.oppDetails.nationality === 'irish') {
            form.getCheckBox('Check Box35').check()
        } else {
            form.getCheckBox('Check Box38').check()
        }
        form.getCheckBox('Check Box36').check()

        if (res.oppDetails.statementScheme === 'Yes') {
            form.getCheckBox('Check Box37').check()
        } else {
            form.getCheckBox('Check Box40').check()
        }


        if (res.oppDetails.perField === 'Asylum Seeker') {
            form.getCheckBox('Check Box45').check()
        } else if (res.oppDetails.perField === 'Indefinite Leave') {
            form.getCheckBox('Check Box48').check()
        } else if (res.oppDetails.perField === 'Exceptional / Discretionary Leave') {
            form.getCheckBox('Check Box49').check()
        } else if (res.oppDetails.perField === 'Humanitarian Protection') {
            form.getCheckBox('Check Box43').check()
        } else if (res.oppDetails.perField === 'Refugee') {
            form.getCheckBox('Check Box47').check()
        }


        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {

            form.getCheckBox("Check Box53").check()

            if (res.employmentDetails.length === 'Up to 3') {
                form.getCheckBox('Check Box56').check()
            } else if (res.employmentDetails.length === '4-6') {
                form.getCheckBox('Check Box57').check()
            } else if (res.employmentDetails.length === '7-12') {
                form.getCheckBox('Check Box58').check()
            } else {
                form.getCheckBox('Check Box59').check()
            }


            if (res.employmentDetails.employmentType === 'Self Employed') {
                form.getCheckBox("Check Box60").check()
            } else {
                form.getCheckBox("Check Box61").check()
            }
            form.getTextField("How many hours a week are you employed").setText(res.employmentDetails.hoursPerWeek)


        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("Check Box54").check()
            } else {
                form.getCheckBox("Check Box55").check()
            }
            form.getTextField('If unemployed how long since your last employment').setText(res.employmentDetails.unemployedLength)

            if (res.employmentDetails.dValue1) {
                form.getCheckBox('Check Box64').check()//JSA
            }
            if (res.employmentDetails.dValue2) {
                form.getCheckBox('Check Box65').check()//others
            }
            if (res.employmentDetails.dValue3) {
                form.getCheckBox('Check Box65').check()//others
            }
            if (res.employmentDetails.dValue6) {
                form.getCheckBox('Check Box65').check()//others
            }
            if (res.employmentDetails.dValue5) {
                form.getCheckBox('Check Box63').check() //esa
            }
            if (res.employmentDetails.dValue7) {
                form.getCheckBox('Check Box65').check()//others
            }
            if (res.employmentDetails.dValue9) {
                form.getCheckBox('Check Box65').check()//others
            }

            if (res.employmentDetails.dValue8) {
                form.getCheckBox('Check Box62').check() //UC
            }
        }
        if (res.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('Check Box66').check()
        } else {
            form.getCheckBox('Check Box67').check()
        }

        if (res.oppDetails.dValue1) {
            form.getCheckBox('Check Box68').check()
        }
        if (res.oppDetails.dValue2) {
            form.getCheckBox('Check Box69').check()
        }
        if (res.oppDetails.dValue3) {
            form.getCheckBox('Check Box70').check()
        }
        if (res.oppDetails.dValue4) {
            form.getCheckBox('Check Box71').check()
        }
        if (res.oppDetails.dValue5) {
            form.getCheckBox('Check Box72').check()
        }
        if (res.oppDetails.dValue6) {
            form.getCheckBox('Check Box74').check()
        }
        if (res.oppDetails.dValue7) {
            form.getCheckBox('Check Box75').check()
        }
        if (res.oppDetails.dValue8) {
            form.getCheckBox('Check Box76').check()
        }
        if (res.oppDetails.dValue9) {
            form.getCheckBox('Check Box77').check()
        }
        if (res.oppDetails.dValue10) {
            form.getCheckBox('Check Box78').check()
        }
        if (res.oppDetails.dValue11) {
            form.getCheckBox('Check Box80').check()
        }
        if (res.oppDetails.dValue12) {
            form.getCheckBox('Check Box81').check()
        }
        if (res.oppDetails.dValue13) {
            form.getCheckBox('Check Box82').check()
        }
        if (res.oppDetails.dValue14) {
            form.getCheckBox('Check Box83').check()
        }
        if (res.oppDetails.dValue15) {
            form.getCheckBox('Check Box85').check()
        }
        if (res.oppDetails.dValue16) {
            form.getCheckBox('Check Box88').check()
        }
        if (res.oppDetails.dValue17) {
            form.getCheckBox('Check Box86').check()
        }
        if (res.oppDetails.dValue18) {
            form.getCheckBox('Check Box89').check()
        }

        form.getTextField('Student Name').setText(res.personalDetails.lastName + " " + res.personalDetails.firstName)

        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        form.getTextField('Date').setText(date)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }
        // if (res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
        //     res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547" ||
        //     res.employmentDetails.employementStatus === "Unemployed, looking for work") {
        //     zip.folder(
        //         res.personalDetails.firstName + res.personalDetails.lastName
        //     ).file(
        //         'Low Income Document.pdf',
        //         collegeCopLowIncomePDF(res),
        //         { base64: true }
        //     )
        // }
        // zip.folder(
        //     res.personalDetails.firstName + res.personalDetails.lastName
        // ).file(
        //     'Charge Letter.pdf',
        //     collegeCopChargeLetterPDF(res),
        //     { base64: true }
        // )


        if (
            res.employmentDetails.dValue2 ||
            res.employmentDetails.dValue1 ||
            res.employmentDetails.dValue3 ||
            res.employmentDetails.dValue5 ||
            res.employmentDetails.dValue6 ||
            res.employmentDetails.dValue7 ||
            res.employmentDetails.dValue8 ||
            res.employmentDetails.dValue9 ||
            res.employmentDetails.dValue10 ||
            res.employmentDetails.pipBenifits
        ) {
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file(
                'BENEFIT WAIVER.pdf',
                collegeCopBenefitsPDF(res),
                { base64: true }
            )
        }
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            'COP Charge Letter.pdf',
            collegeCopDeclartionPDF(res),
            { base64: true }
        )

        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            'FEE WAIVER.pdf',
            collegeCopL3FeePDF(res),
            { base64: true }
        )



        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


// wiltshire PDF
export const handleWiltShireCollege = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/wiltshire.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text Field 279')
        signImageField.setImage(emblemImage)

        // New Form Stuff
        if (res.personalDetails.title === 'Mr') {
            form.getCheckBox('Check Box 1').check()
        } else if (res.personalDetails.title === 'Mrs') {
            form.getCheckBox('Check Box 4').check()
        } else if (res.personalDetails.title === 'Ms') {
            form.getCheckBox('Check Box 6').check()
        } else if (res.personalDetails.title === 'Miss') {
            form.getCheckBox('Check Box 5').check()
        }


        form.getTextField('Text Field 11').setText(res.personalDetails.nationalInsNo[0])
        form.getTextField('Text Field 12').setText(res.personalDetails.nationalInsNo[1])
        form.getTextField('Text Field 13').setText(res.personalDetails.nationalInsNo[2])
        form.getTextField('Text Field 14').setText(res.personalDetails.nationalInsNo[3])
        form.getTextField('Text Field 15').setText(res.personalDetails.nationalInsNo[4])
        form.getTextField('Text Field 16').setText(res.personalDetails.nationalInsNo[5])
        form.getTextField('Text Field 17').setText(res.personalDetails.nationalInsNo[6])
        form.getTextField('Text Field 18').setText(res.personalDetails.nationalInsNo[7])
        form.getTextField('Text Field 19').setText(res.personalDetails.nationalInsNo[8])


        let dobdate = String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        form.getTextField('Text Field 381').setText(dobdate[0])
        form.getTextField('Text Field 384').setText(dobdate[1])
        form.getTextField('Text Field 379').setText(dobdate[3])
        form.getTextField('Text Field 382').setText(dobdate[4])
        form.getTextField('Text Field 386').setText(dobdate[6])
        form.getTextField('Text Field 385').setText(dobdate[7])
        form.getTextField('Text Field 380').setText(dobdate[8])
        form.getTextField('Text Field 383').setText(dobdate[9])


        let yearDOB = parseInt(String(moment(res.personalDetails.dob).year()))
        let monthDOB = parseInt(String(moment(res.personalDetails.dob).month()))
        let calculatedAge = 2023 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }

        form.getTextField('Text Field 4016').setText(calculatedAge.toString())


        form.getTextField('Text Field 405').setText(res.personalDetails.lastName)
        form.getTextField('Text Field 4010').setText(res.personalDetails.firstName)

        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('Check Box 149').check()
        } else {
            form.getCheckBox('Check Box 147').check()
        }


        form.getTextField('Text Field 104').setText(res.personalDetails.addLine1 + ", " + res.personalDetails.city + ', ' + res.personalDetails.county + ', ' + res.personalDetails.postcode)
        form.getTextField('Text Field 300').setText(res.personalDetails.city)
        form.getTextField('Text Field 301').setText(res.personalDetails.postcode)
        form.getTextField('Text Field 105').setText(res.personalDetails.telephone[0])
        form.getTextField('Text Field 106').setText(res.personalDetails.telephone[1])
        form.getTextField('Text Field 107').setText(res.personalDetails.telephone[2])
        form.getTextField('Text Field 108').setText(res.personalDetails.telephone[3])
        form.getTextField('Text Field 109').setText(res.personalDetails.telephone[4])
        form.getTextField('Text Field 110').setText(res.personalDetails.telephone[5])
        form.getTextField('Text Field 111').setText(res.personalDetails.telephone[6])
        form.getTextField('Text Field 112').setText(res.personalDetails.telephone[7])
        form.getTextField('Text Field 113').setText(res.personalDetails.telephone[8])
        form.getTextField('Text Field 114').setText(res.personalDetails.telephone[9])
        form.getTextField('Text Field 115').setText(res.personalDetails.telephone[10])

        form.getTextField('Text Field 446').setText(res.personalDetails.email)


        form.getTextField('Text Field 422').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text Field 423').setText(res.emergencyDetails.relationshipToLearner)
        form.getTextField('Text Field 428').setText(res.emergencyDetails.emergencyTelephone[0])
        form.getTextField('Text Field 424').setText(res.emergencyDetails.emergencyTelephone[1])
        form.getTextField('Text Field 426').setText(res.emergencyDetails.emergencyTelephone[2])
        form.getTextField('Text Field 433').setText(res.emergencyDetails.emergencyTelephone[3])
        form.getTextField('Text Field 430').setText(res.emergencyDetails.emergencyTelephone[4])
        form.getTextField('Text Field 429').setText(res.emergencyDetails.emergencyTelephone[5])
        form.getTextField('Text Field 425').setText(res.emergencyDetails.emergencyTelephone[6])
        form.getTextField('Text Field 427').setText(res.emergencyDetails.emergencyTelephone[7])
        form.getTextField('Text Field 434').setText(res.emergencyDetails.emergencyTelephone[8])
        form.getTextField('Text Field 431').setText(res.emergencyDetails.emergencyTelephone[9])
        form.getTextField('Text Field 432').setText(res.emergencyDetails.emergencyTelephone[10])


        if (res.qualificationDetails.fullTimeEducationElse) {
            if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
                form.getCheckBox('Check Box 160').check()
                form.getCheckBox('Check Box 253').check()
            } else {
                form.getCheckBox('Check Box 161').check()
                form.getCheckBox('Check Box 261').check()
            }
        }


        if (res.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            form.getCheckBox('Check Box 23').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box 24').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box 25').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box 26').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box 14').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box 15').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box 16').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background') {
            form.getCheckBox('Check Box 17').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box 9').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box 10').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box 11').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box 12').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box 13').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box 18').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box 19').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
            form.getCheckBox('Check Box 20').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box 21').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box 22').check()
        }

        if (res.oppDetails.firstLang === 'Yes') {
            form.getCheckBox('Check Box 28').check()
        } else {
            form.getCheckBox('Check Box 27').check()
        }

        // resident for 3 years
        form.getCheckBox('Check Box 140').check()


        if (res.oppDetails.nationality === 'british') {
            form.getCheckBox('Check Box 138').check()
        } else {
            form.getCheckBox('Check Box 139').check()
        }

        form.getTextField('Text Field 305').setText(res.oppDetails.nationality)
        if (res.oppDetails.perField) {
            form.getTextField('Text Field 307').setText(res.oppDetails.perField)
        }
        if (res.oppDetails.perField === 'Asylum Seeker') {
            form.getCheckBox('Check Box 142').check()
        } else {
            form.getCheckBox('Check Box 143').check()
        }

        if (res.oppDetails.disabilities === 'Yes') {
            if (res.oppDetails.dValue1) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 62').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 63').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 64').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 65').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 66').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 67').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 68').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 69').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 70').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 71').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 72').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('Check Box 58').check()
                form.getCheckBox('Check Box 73').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('Check Box 60').check()
                form.getCheckBox('Check Box 74').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('Check Box 60').check()
                form.getCheckBox('Check Box 75').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('Check Box 60').check()
                form.getCheckBox('Check Box 76').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('Check Box 60').check()
                form.getCheckBox('Check Box 77').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('Check Box 60').check()
                form.getCheckBox('Check Box 79').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('Check Box 60').check()
                form.getCheckBox('Check Box 80').check()
            }
        } else {
            form.getCheckBox('Check Box 59').check()
            form.getCheckBox('Check Box 61').check()
        }

        if (res.qualificationDetails.englishGrades) {
            form.getTextField('Text Field 174').setText("English")
            form.getTextField('Text Field 192').setText(res.qualificationDetails.englishGrades)
        }
        if (res.qualificationDetails.mathGrades) {
            form.getTextField('Text Field 175').setText("Maths")
            form.getTextField('Text Field 193').setText(res.qualificationDetails.mathGrades)
        }

        if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box 242').check()
        }



        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            if (res.employmentDetails.employmentType === 'Self Employed') {
                form.getCheckBox("Check Box 201").check()
            } else {
                form.getCheckBox("Check Box 202").check()
            }
            form.getTextField("Text Field 464").setText(res.employmentDetails.hoursPerWeek)


        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("Check Box 248").check()
            } else {
                form.getCheckBox("Check Box 257").check()
            }

            form.getTextField('Text Field 487').setText(res.employmentDetails.unemployedLength)

            if (res.employmentDetails.dValue1) {
                form.getCheckBox('Check Box 112').check()//JSA
            }
            if (res.employmentDetails.dValue2) {
                form.getCheckBox('Check Box 153').check()//incomesuppot
            }
            if (res.employmentDetails.dValue3) {
                form.getCheckBox('Check Box 155').check()//council tax
            }
            if (res.employmentDetails.dValue5) {
                form.getCheckBox('Check Box 113').check() //esa
            }
            if (res.employmentDetails.dValue7) {
                form.getCheckBox('Check Box 154').check()//huosing benefit
            }
        }
        if (
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed") {
            form.getCheckBox('Check Box 235').check()
        }


        form.getTextField('Text Field 242').setText(res.detailsFormData.appliedCourse)


        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('Check Box 123').check()
        } else {
            form.getCheckBox('Check Box 124').check()
        }

        if (res.declaration.aboutCourse) {
            form.getCheckBox('Check Box 236').check()
        }
        if (res.declaration.forSurveys) {
            form.getCheckBox('Check Box 240').check()
        }

        if (res.declaration.prefContact1)
            form.getCheckBox('Check Box 237').check()
        if (res.declaration.prefContact2)
            form.getCheckBox('Check Box 238').check()
        if (res.declaration.prefContact)
            form.getCheckBox('Check Box 239').check()




        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        form.getTextField('Text Field 280').setText(date)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then(() => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


// Basingstoke PDF
export const handleBasingStokeCollege = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/basingstoke.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text Field 279')
        const signImageField2 = form.getTextField('Text Field 279 signature2')
        signImageField.setImage(emblemImage)
        signImageField2.setImage(emblemImage)

        // New Form Stuff
        if (res.personalDetails.title === 'Mr') {
            form.getCheckBox('Check Box 1').check()
        } else if (res.personalDetails.title === 'Mrs') {
            form.getCheckBox('Check Box 4').check()
        } else if (res.personalDetails.title === 'Ms') {
            form.getCheckBox('Check Box 6').check()
        } else if (res.personalDetails.title === 'Miss') {
            form.getCheckBox('Check Box 5').check()
        }

        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('Check Box 149').check()
        } else {
            form.getCheckBox('Check Box 147').check()
        }


        let dobdate = String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))
        form.getTextField('Text Field 381').setText(dobdate[0])
        form.getTextField('Text Field 384').setText(dobdate[1])
        form.getTextField('Text Field 379').setText(dobdate[3])
        form.getTextField('Text Field 382').setText(dobdate[4])
        form.getTextField('Text Field 386').setText(dobdate[6])
        form.getTextField('Text Field 385').setText(dobdate[7])
        form.getTextField('Text Field 380').setText(dobdate[8])
        form.getTextField('Text Field 383').setText(dobdate[9])



        form.getTextField('Text Field 405').setText(res.personalDetails.lastName)
        form.getTextField('Text Field 4010').setText(res.personalDetails.firstName)


        form.getTextField('Text Field 104').setText(res.personalDetails.addLine1 + ", " + res.personalDetails.city + ', ' + res.personalDetails.county + ', ' + res.personalDetails.postcode)
        form.getTextField('Text Field 301').setText(res.personalDetails.postcode)
        form.getTextField('Text Field 105').setText(res.personalDetails.telephone)

        form.getTextField('Text Field 446').setText(res.personalDetails.email)


        form.getTextField('Text Field 422').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text Field 428').setText(res.emergencyDetails.emergencyTelephone)


        if (res.oppDetails.nationality === 'british') {
            form.getCheckBox('Check Box 138').check()
        } else {
            form.getCheckBox('Check Box 139').check()
        }

        if (res.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            form.getCheckBox('Check Box 23').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box 24').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box 25').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box 26').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box 14').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box 15').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box 16').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background') {
            form.getCheckBox('Check Box 17').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box 9').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box 10').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box 11').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box 12').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box 13').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box 18').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box 19').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
            form.getCheckBox('Check Box 20').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box 21').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box 22').check()
        }




        // form.getTextField('Text Field 305').setText(res.oppDetails.nationality)

        if (res.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('CheckBox-disable-yes').check()

            if (res.oppDetails.dValue1) {
                form.getCheckBox('Visual impairment').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('Hearing impairment').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('Disability affecting mobility').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('Profound complex disabilities').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('Social and Emotional Difficulties').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('Mental health difficulty').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('Moderate difficulties').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('Severe difficulties').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('Dyslexia').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('Dyscalculia').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('Autism spectrum disorder').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('Aspergers Syndrome').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('Temporary disability after illness').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('Speech Language and').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('Other physical disability').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('CheckBox-medicalcondition').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('Other_learning').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('Other').check()
            }
        } else {
            form.getCheckBox('CheckBox-disable-no').check()
        }


        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Entry Level eg E1 E2 E3').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('No formal qualifications').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Level 1 5 or more GCSE grades 3 and below OR DG').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('level2').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('level3').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Level 4 HNC NVQ 4').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Level 5 Foundation Degree HND NVQ 5').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Level 6 AwardCertDip').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Level 7  Degree Postgraduate degree').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Level 7  Degree Postgraduate degree').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Full Level 2 5 GCSEs 4 and above OR AC 2 AS Levels 1').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Full Level 3 2 or more Alevels Certificate Diploma').check()
        }

        if (res.qualificationDetails.englishGrades === 'A*') {
            form.getCheckBox('englishGrades A*').check()
        } else if (res.qualificationDetails.englishGrades === 'A') {
            form.getCheckBox('englishGrades A').check()
        } else if (res.qualificationDetails.englishGrades === 'B') {
            form.getCheckBox('englishGrades B').check()
        } else if (res.qualificationDetails.englishGrades === 'C') {
            form.getCheckBox('englishGrades C').check()
        } else if (res.qualificationDetails.englishGrades === 'D') {
            form.getCheckBox('englishGrades D').check()
        } else if (res.qualificationDetails.englishGrades === 'E') {
            form.getCheckBox('englishGrades E').check()
        } else if (res.qualificationDetails.englishGrades === 'F') {
            form.getCheckBox('englishGrades F').check()
        } else if (res.qualificationDetails.englishGrades === 'G') {
            form.getCheckBox('englishGrades G').check()
        } else if (res.qualificationDetails.englishGrades === '9') {
            form.getCheckBox('englishGrades 9').check()
        } else if (res.qualificationDetails.englishGrades === '8') {
            form.getCheckBox('englishGrades 8').check()
        } else if (res.qualificationDetails.englishGrades === '7') {
            form.getCheckBox('englishGrades 7').check()
        } else if (res.qualificationDetails.englishGrades === '6') {
            form.getCheckBox('englishGrades 6').check()
        } else if (res.qualificationDetails.englishGrades === '5') {
            form.getCheckBox('englishGrades 5').check()
        } else if (res.qualificationDetails.englishGrades === '4') {
            form.getCheckBox('englishGrades 4').check()
        } else if (res.qualificationDetails.englishGrades === '3') {
            form.getCheckBox('englishGrades 3').check()
        } else if (res.qualificationDetails.englishGrades === '2') {
            form.getCheckBox('englishGrades 2').check()
        } else if (res.qualificationDetails.englishGrades === '1') {
            form.getCheckBox('englishGrades 1').check()
        }


        if (res.qualificationDetails.mathGrades === 'A*') {
            form.getCheckBox('mathGrades A*').check()
        } else if (res.qualificationDetails.mathGrades === 'A') {
            form.getCheckBox('mathGrades A').check()
        } else if (res.qualificationDetails.mathGrades === 'B') {
            form.getCheckBox('mathGrades B').check()
        } else if (res.qualificationDetails.mathGrades === 'C') {
            form.getCheckBox('mathGrades C').check()
        } else if (res.qualificationDetails.mathGrades === 'D') {
            form.getCheckBox('mathGrades D').check()
        } else if (res.qualificationDetails.mathGrades === 'E') {
            form.getCheckBox('mathGrades E').check()
        } else if (res.qualificationDetails.mathGrades === 'F') {
            form.getCheckBox('mathGrades F').check()
        } else if (res.qualificationDetails.mathGrades === 'G') {
            form.getCheckBox('mathGrades G').check()
        } else if (res.qualificationDetails.mathGrades === '9') {
            form.getCheckBox('mathGrades 9').check()
        } else if (res.qualificationDetails.mathGrades === '8') {
            form.getCheckBox('mathGrades 8').check()
        } else if (res.qualificationDetails.mathGrades === '7') {
            form.getCheckBox('mathGrades 7').check()
        } else if (res.qualificationDetails.mathGrades === '6') {
            form.getCheckBox('mathGrades 6').check()
        } else if (res.qualificationDetails.mathGrades === '5') {
            form.getCheckBox('mathGrades 5').check()
        } else if (res.qualificationDetails.mathGrades === '4') {
            form.getCheckBox('mathGrades 4').check()
        } else if (res.qualificationDetails.mathGrades === '3') {
            form.getCheckBox('mathGrades 3').check()
        } else if (res.qualificationDetails.mathGrades === '2') {
            form.getCheckBox('mathGrades 2').check()
        } else if (res.qualificationDetails.mathGrades === '1') {
            form.getCheckBox('mathGrades 1').check()
        }


        form.getTextField('Text-course').setText(res.detailsFormData.appliedCourse)

        if (res.employmentDetails.dValue1) {
            form.getCheckBox('Check Box 112').check()//JSA
        }
        if (res.employmentDetails.dValue2) {
            form.getCheckBox('Check Box 153').check()//incomesuppot
        }
        if (res.employmentDetails.dValue5) {
            form.getCheckBox('Check Box 153').check() //esa
        }
        if (res.employmentDetails.dValue8) {
            form.getCheckBox('Benefit-Universal').check() //uc
        }

        form.getTextField('Text Field 11').setText(res.personalDetails.nationalInsNo)

        if (
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox('Check Box 235').check()
        }



        if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getCheckBox('fullTimeEducationElse').check()
        } else {
            form.getCheckBox('fullTimeEducationElse-no').check()
        }

        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("0 to 10 hours per week").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Between 11 to 20 hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("21 to 30 hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("31 hours").check()
            }

            if (res.employmentDetails.length === 'Up to 3') {
                form.getCheckBox('Check Up to 3').check()
            } else if (res.employmentDetails.length === '4-6') {
                form.getCheckBox('Check 4-6').check()
            } else if (res.employmentDetails.length === '7-12') {
                form.getCheckBox('Check 7-12').check()
            } else {
                form.getCheckBox('Check more12').check()
            }
            if (res.employmentDetails?.employmentType === "Self Employed") {
                form.getCheckBox('Check Self Employed').check()
            }
            form.getTextField('Text73').setText(res.employmentDetails.employerName)
            form.getTextField('Text75').setText(res.employmentDetails.employerAdd)
            if (res.employmentDetails.postcode) {

                form.getTextField('postcode0').setText(res.employmentDetails.postcode[0])
                form.getTextField('postcode1').setText(res.employmentDetails.postcode[1])
                form.getTextField('postcode2').setText(res.employmentDetails.postcode[2])
                form.getTextField('postcode3').setText(res.employmentDetails.postcode[3])
                form.getTextField('postcode4').setText(res.employmentDetails.postcode[4])
                form.getTextField('postcode5').setText(res.employmentDetails.postcode[5])
                form.getTextField('postcode6').setText(res.employmentDetails.postcode[6])
            }
        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {

            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("Yes_12").check()
            } else {
                form.getCheckBox("No_12").check()
            }

            if (res.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("Less than 6 months").check()
            } else if (res.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("6  11 months").check()
            } else if (res.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("12  23 months").check()
            } else if (res.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("24  35 months").check()
            } else if (res.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("36 months or over").check()
            }

        }



        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('Check Box 123').check()
        } else {
            form.getCheckBox('Check Box 124').check()
        }

        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        form.getTextField('Text Field 280').setText(date)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}



// NSWL PDF
export const handleNSWLCollege = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/nswlc.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('Text-signature')
        signImageField.setImage(emblemImage)

        // New Form Stuff
        form.getTextField('Text-course').setText(res.detailsFormData.appliedCourse)
        form.getTextField('Text-title').setText(res.personalDetails.title)

        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('CheckBox-female').check()
        } else {
            form.getCheckBox('CheckBox-male').check()
        }
        form.getTextField('dob').setText(res.personalDetails.dob)


        form.getTextField('Text-lastname').setText(res.personalDetails.lastName)
        form.getTextField('Text-firstname').setText(res.personalDetails.firstName)
        form.getTextField('Text-middlename').setText(res.personalDetails.middleName)
        form.getTextField('Text-nin').setText(res.personalDetails.nationalInsNo)


        form.getTextField('Text-address').setText(res.personalDetails.addLine1)
        form.getTextField('Text-town').setText(res.personalDetails.city)
        form.getTextField('Text-county').setText(res.personalDetails.county)
        form.getTextField('Text-postcode').setText(res.personalDetails.postcode)
        form.getTextField('Text-yearsataddress').setText(res.personalDetails.yearsAtAdd + ' years')

        form.getTextField('Text-telephone').setText(res.personalDetails.telephone)

        form.getTextField('Text-email').setText(res.personalDetails.email)

        form.getCheckBox('CheckBox-min3years').check()

        if (res.oppDetails.nationality === 'british') {
            form.getCheckBox('CheckBox-uknational').check()
        } else {
            form.getCheckBox('CheckBox-notuknational').check()
        }

        form.getTextField('Text-emergencyname').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text-emergencyphone').setText(res.emergencyDetails.emergencyTelephone)
        form.getTextField('Text-emergencyRelation').setText(res.emergencyDetails.relationshipToLearner)



        let ethnicity

        if (res.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            ethnicity = '31'
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            ethnicity = '32'
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            ethnicity = '33'
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            ethnicity = '34'
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            ethnicity = '35'
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            ethnicity = '36'
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            ethnicity = '37'
        } else if (
            res.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            ethnicity = '38'
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            ethnicity = '39'
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            ethnicity = '40'
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            ethnicity = '41'
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            ethnicity = '42'
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            ethnicity = '43'
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            ethnicity = '44'
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            ethnicity = '45'
        } else if (
            res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            ethnicity = '46'
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            ethnicity = '47'
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            ethnicity = '98'
        }

        form.getTextField('Text-ethnicity').setText(ethnicity)


        // form.getTextField('Text Field 305').setText(res.oppDetails.nationality)

        if (res.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('disablilityORlearning').check()
            let disabilityCode = []
            if (res.oppDetails.dValue1) {
                disabilityCode.push('04')
                // form.getCheckBox('Visual impairment').check()
            }
            if (res.oppDetails.dValue2) {
                disabilityCode.push('05')
                // form.getCheckBox('Hearing impairment').check()
            }
            if (res.oppDetails.dValue3) {
                disabilityCode.push('06')
                // form.getCheckBox('Disability affecting mobility').check()
            }
            if (res.oppDetails.dValue4) {
                disabilityCode.push('07')
                // form.getCheckBox('Profound complex disabilities').check()
            }
            if (res.oppDetails.dValue5) {
                disabilityCode.push('08')
                // form.getCheckBox('Social and Emotional Difficulties').check()
            }
            if (res.oppDetails.dValue6) {
                disabilityCode.push('09')
                // form.getCheckBox('Mental health difficulty').check()
            }
            if (res.oppDetails.dValue7) {
                disabilityCode.push('10')
                // form.getCheckBox('Moderate difficulties').check()
            }
            if (res.oppDetails.dValue8) {
                disabilityCode.push('11')
                // form.getCheckBox('Severe difficulties').check()
            }
            if (res.oppDetails.dValue9) {
                disabilityCode.push('12')
                // form.getCheckBox('Dyslexia').check()
            }
            if (res.oppDetails.dValue10) {
                disabilityCode.push('13')
                // form.getCheckBox('Dyscalculia').check()
            }
            if (res.oppDetails.dValue11) {
                disabilityCode.push('14')
                // form.getCheckBox('Autism spectrum disorder').check()
            }
            if (res.oppDetails.dValue12) {
                disabilityCode.push('15')
                // form.getCheckBox('Aspergers Syndrome').check()
            }
            if (res.oppDetails.dValue13) {
                disabilityCode.push('16')
                // form.getCheckBox('Temporary disability after illness').check()
            }
            if (res.oppDetails.dValue14) {
                disabilityCode.push('17')
                // form.getCheckBox('Speech Language and').check()
            }
            if (res.oppDetails.dValue15) {
                disabilityCode.push('93')
                // form.getCheckBox('Other physical disability').check()
            }
            if (res.oppDetails.dValue16) {
                disabilityCode.push('95')
                // form.getCheckBox('Medical condition').check()
            }
            if (res.oppDetails.dValue17) {
                disabilityCode.push('94')
                // form.getCheckBox('learning-difficulty').check()
            }
            if (res.oppDetails.dValue18) {
                disabilityCode.push('97')
                // form.getCheckBox('Other').check()
            }
            if (disabilityCode[0]) {
                form.getTextField('disability1code').setText(disabilityCode[0])
            } else if (disabilityCode[1]) {
                form.getTextField('disability2code').setText(disabilityCode[1])
            } else if (disabilityCode[2]) {
                form.getTextField('disability3code').setText(disabilityCode[2])
            } else if (disabilityCode[3]) {
                form.getTextField('disability4code').setText(disabilityCode[3])
            } else if (disabilityCode[4]) {
                form.getTextField('disability5code').setText(disabilityCode[4])
            }

        } else {
            form.getCheckBox('disabilityno').check()
        }

        if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getCheckBox('fulltimeeducation').check()
        }

        if (res.qualificationDetails.englishGrades) {
            form.getTextField('Text-gcse-english').setText(res.qualificationDetails.englishGrades)
            form.getTextField('Text-gcse-english2').setText(res.qualificationDetails.englishGrades)
        }
        if (res.qualificationDetails.mathGrades) {
            form.getTextField('Text-gcse-math').setText(res.qualificationDetails.mathGrades)
        }

        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('qualification-entry').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('qualification-none').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('qualification-l1').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('qualification-l2').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('qualification-l3').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('qualification-l4').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('qualification-l5').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('qualification-l6').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('qualification-l7').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('qualification-l7').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('qualification-l2full').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('qualification-l3full').check()
        }


        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("employed-lessthan10").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("employed-11-20").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("employed-21-30").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("employed-31+").check()
            }

            if (res.employmentDetails.length === 'Up to 3') {
                form.getCheckBox('employed-3months').check()
            } else if (res.employmentDetails.length === '4-6') {
                form.getCheckBox('employed-46months').check()
            } else if (res.employmentDetails.length === '7-12') {
                form.getCheckBox('employed-7-12months').check()
            } else {
                form.getCheckBox('employed-12+months').check()
            }
            if (res.employmentDetails?.employmentType === "Self Employed") {
                form.getCheckBox('CheckBox-selfemployed-yes').check()
            } else {
                form.getCheckBox('CheckBox-selfemployed-no').check()
            }
        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {

            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("unemployed-looking for work").check()
            } else {
                form.getCheckBox("unemployed-not looking for work").check()
            }

            if (res.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("unemployed-lessthan6").check()
            } else if (res.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("unemployed-6-11").check()
            } else if (res.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("unemployed-12-23").check()
            } else if (res.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("unemployed-24-35").check()
            } else if (res.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("unemployed-36+").check()
            }

        }

        if (res.declaration.houseHoldMem) {
            form.getCheckBox('CheckBox-nohousehold1').check()
        }
        if (res.declaration.houseHoldMem2) {
            form.getCheckBox('CheckBox-nohousehold2').check()
        }
        if (res.declaration.singleHouse) {
            form.getCheckBox('CheckBox-learnerlivessingle').check()
        }
        if (res.declaration.noneOfThese) {
            form.getCheckBox('CheckBox-notaplicable').check()
        }
        if (res.declaration.confirmation) {
            form.getCheckBox('CheckBox-prefernottosay').check()
        }

        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')


        if (res.personalDetails.age === '19' ||
            res.personalDetails.age === '20' ||
            res.personalDetails.age === '21' ||
            res.personalDetails.age === '22' ||
            res.personalDetails.age === '23'
        ) {
            if (res.qualificationDetails.level === 'Entry Level' ||
                res.qualificationDetails.level === 'Level 1'
            ) {
                form.getCheckBox('19-23 less than full level 2').check()
                form.getCheckBox('CheckBox-ageea').check()
                form.getTextField('Text-date3').setText(date)
            }
            if (res.qualificationDetails.level === 'Level 2'
            ) {
                form.getCheckBox('19-23 full level 2').check()
                form.getCheckBox('CheckBox-ageea').check()
                form.getTextField('Text-date3').setText(date)
            }
        } else if (res.qualificationDetails.level === 'None' || (res.qualificationDetailsGCSEEnglishabove === false && res.qualificationDetailsGCSEMathsabove === false)) {
            form.getCheckBox('dont hold gcse A*-c').check()
            form.getCheckBox('CheckBox-ageea').check()
            form.getTextField('Text-date3').setText(date)
        }


        if (res.employmentDetails.dValue1) {
            form.getCheckBox('jsa').check()//JSA
            form.getCheckBox('CheckBox-remission').check()
            form.getTextField('Text-date').setText(date)
        }
        if (res.employmentDetails.dValue2) {
            form.getCheckBox('esa').check()//incomesuppot
            form.getCheckBox('CheckBox-remission').check()
            form.getTextField('Text-date').setText(date)
        }
        if (res.employmentDetails.dValue5) {
            form.getCheckBox('esa').check() //esa
            form.getCheckBox('CheckBox-remission').check()
            form.getTextField('Text-date').setText(date)
        }
        if (res.employmentDetails.dValue8) {
            form.getCheckBox('UC').check() //uc
            form.getCheckBox('CheckBox-remission').check()
            form.getTextField('Text-date').setText(date)
        }


        if (
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox('lessthanincome').check()
            form.getCheckBox('CheckBox-remission').check()
            form.getTextField('Text-date').setText(date)
        }

        form.getCheckBox('CheckBox-agreement').check()


        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('CheckBox-criminal-yes').check()
        } else {
            form.getCheckBox('CheckBox-criminal-no').check()
        }

        if (res.declaration.prefContact1) form.getCheckBox('contact-post').check()
        if (res.declaration.prefContact2) form.getCheckBox('contact-phone').check()
        if (res.declaration.prefContact) form.getCheckBox('contact-email').check()
        form.getTextField('Text--date2').setText(date)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


// london south east college PDF
export const handleLondonSECollege = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/londonSE.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('signature')
        signImageField.setImage(emblemImage)

        // New Form Stuff
        form.getTextField('Text-title').setText(res.personalDetails.title)


        form.getTextField('Text-sirname').setText(res.personalDetails.lastName)
        form.getTextField('Text-firstname').setText(res.personalDetails.firstName)
        form.getTextField('Text-sirnam2').setText(res.personalDetails.lastName)
        form.getTextField('Text-firstname-2').setText(res.personalDetails.firstName)

        form.getTextField('Text-dob').setText(res.personalDetails.dob)
        let yearDOB = parseInt(String(moment(res.personalDetails.dob).year()))
        let monthDOB = parseInt(String(moment(res.personalDetails.dob).month()))
        let calculatedAge = 2023 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }


        form.getTextField('Text-ageataug').setText(calculatedAge.toString())

        form.getTextField('Text-nin').setText(res.personalDetails.nationalInsNo)

        form.getTextField('Text-address').setText(res.personalDetails.addLine1)
        form.getTextField('Text-town').setText(res.personalDetails.city)
        form.getTextField('Text-county').setText(res.personalDetails.county)
        form.getTextField('Text-postcode').setText(res.personalDetails.postcode)

        form.getTextField('Text-telephone').setText(res.personalDetails.telephone)

        form.getTextField('Text-email').setText(res.personalDetails.email)


        form.getTextField('emergency-name').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('emergency-mobile').setText(res.emergencyDetails.emergencyTelephone)
        form.getTextField('emergency-relatiion').setText(res.emergencyDetails.relationshipToLearner)

        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('CheckBox-female').check()
        } else {
            form.getCheckBox('CheckBox-male').check()
        }
        form.getTextField('Text-nationality').setText(res.oppDetails.nationality)
        if (res.oppDetails.firstLang === 'Yes') {
            form.getTextField('Text-firstlanguage').setText("English")
        }


        if (res.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
            form.getCheckBox('ethnic-english').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('ethnic-irish').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('ethnic-gypsy').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('ethnic-whitebg').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('ethnic-white/black carib').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('ethnic-white/black african').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('ethnic-white-asian').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other mixed/multiple ethnic background') {
            form.getCheckBox('ethnic-mixed').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('ethnic-indian').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('ethnic-pakistani').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('ethnic-bangladeshi').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('ethnic-chinese').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('ethnic-other-asian').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('ethnic-african').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('ethnic-caribbean').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean') {
            form.getCheckBox('CheckBox-anyother-black').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('ethnic-arab').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('ethnic-anyother-ethnic').check()
        }


        if (res.oppDetails.disabilities === 'Yes') {
            form.getCheckBox('CheckBox-disability').check()

            if (res.oppDetails.dValue1) {
                form.getCheckBox('CheckBox-vision-impair').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('CheckBox-hearing-impair').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('CheckBox-disability-mobility').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('CheckBox-complex-disability').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('CheckBox-social/emotional disabilty').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('CheckBox-mental-health').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('CheckBox-modereate-difficulty').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('CheckBox-severe-difficulty').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('CheckBox-dyslexia').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('CheckBox-dyscalculia').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('CheckBox-autism').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('CheckBox-aspergers').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('CheckBox-temporary-illnes').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('CheckBox-speech-disability').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('CheckBox-physical').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('CheckBox-medical-conditon').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('CheckBox-learnng-diff-other').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('CheckBox-disbility-other').check()
            }
        } else {
            form.getCheckBox('CheckBox-no-dsablity').check()
        }


        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('CheckBox-criminal-yes').check()
        } else {
            form.getCheckBox('CheckBox-criminal-no').check()
        }

        form.getCheckBox('CheckBox-livedinuk3years').check()

        if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getCheckBox('CheckBox-attentingcollege-yes').check()
        } else {
            form.getCheckBox('CheckBox-attentingcollege-no').check()
        }

        if (res.oppDetails.perField === 'Asylum Seeker') {
            form.getCheckBox('assylum-yes').check()
        } else if (res.oppDetails.perField === 'Indefinite Leave') {
            form.getCheckBox('indefinite-leave').check()
        } else if (res.oppDetails.perField === 'Exceptional / Discretionary Leave') {
            form.getCheckBox('Exceptional / Discretionary Leave').check()
        } else if (res.oppDetails.perField === 'Refugee') {
            form.getCheckBox('Refugee').check()
        } else if (res.oppDetails.perField === 'British/EU/EEA/Citizen') {
            form.getCheckBox('British/EU/EEA/Citizen').check()
        }

        form.getCheckBox('over18-checkbox').check()



        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("0-10hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("11-20hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("21-30hours").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("31+hours").check()
            }

            if (res.employmentDetails.length === 'Up to 3') {
                form.getCheckBox('3monthsjob').check()
            } else if (res.employmentDetails.length === '4-6') {
                form.getCheckBox('4-6monthsjob').check()
            } else if (res.employmentDetails.length === '7-12') {
                form.getCheckBox('7-12monthsjob').check()
            } else {
                form.getCheckBox('morethan12months').check()
            }
            if (res.employmentDetails?.employmentType === "Self Employed") {
                form.getCheckBox('selfemployed').check()
            }
        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {

            if (res.employmentDetails.lookingForWork === "Yes") {
                form.getCheckBox("lookingforwork").check()
            } else {
                form.getCheckBox("notlookingforwork").check()
            }

            if (res.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("lessthan6months").check()
            } else if (res.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("unemployment-6-11").check()
            } else if (res.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("unemployment-12-23").check()
            } else if (res.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("unemployment-23-35").check()
            } else if (res.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("unemployment-36moths+").check()
            }

        }
        if (res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year") {
            form.getCheckBox("non-london-lessthan-earning").check()
        } else if (res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox("london-lessthan-earning").check()
        }



        if (res.employmentDetails.dValue1) {
            form.getCheckBox("benefits-yes").check()
            form.getCheckBox('CheckBox-jsa').check()
        }

        if (res.employmentDetails.dValue5) {
            form.getCheckBox("benefits-yes").check()
            form.getCheckBox('CheckBox-esa').check()
        }

        if (res.employmentDetails.dValue8) {
            form.getCheckBox("benefits-yes").check()
            form.getCheckBox('CheckBox-uc').check()
        }
        if (res.employmentDetails.dValue2 ||
            res.employmentDetails.dValue3 ||
            res.employmentDetails.dValue6 ||
            res.employmentDetails.dValue7 ||
            res.employmentDetails.dValue9) {
            form.getCheckBox("benefits-yes").check()
            form.getCheckBox('CheckBox-other-benefits').check()
        }


        if (res.qualificationDetails.englishGrades) {
            form.getTextField('gcse-english-grade').setText(res.qualificationDetails.englishGrades)
        }
        if (res.qualificationDetails.mathGrades) {
            form.getTextField('gcse-maths-grade').setText(res.qualificationDetails.mathGrades)
        }

        if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('level2-qualification-dec').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('level3-qualification-dec').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('level3-qualification-dec').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('level3-qualification-dec').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('level3-qualification-dec').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('level3-qualification-dec').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('level3-qualification-dec').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('level3-qualification-dec').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('level3-qualification-dec').check()
        }


        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        form.getTextField('Text-date').setText(date)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


// london south east college PDF
export const handleLMPCollege = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/lmpcollege.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('signature')
        // const signImageField2 = form.getTextField('signature2')
        signImageField.setImage(emblemImage)
        // signImageField2.setImage(emblemImage)

        // New Form Stuff
        form.getTextField('Text-title').setText(res.personalDetails.title)
        form.getTextField('Fulle-nmae').setText(res.personalDetails.firstName + ' ' + res.personalDetails.lastName)


        form.getTextField('Text-sirname').setText(res.personalDetails.lastName)
        form.getTextField('Text-firstname').setText(res.personalDetails.firstName + ' ' + res.personalDetails.middleName)

        form.getTextField('Text-dob').setText(res.personalDetails.dob)
        form.getTextField('Text-age').setText(res.personalDetails.age)
        let yearDOB = parseInt(String(moment(res.personalDetails.dob).year()))
        let monthDOB = parseInt(String(moment(res.personalDetails.dob).month()))
        let calculatedAge = 2024 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }


        form.getTextField('Text-ageataug').setText(calculatedAge.toString())
        form.getTextField('Text-gender').setText(res.personalDetails.gender)

        form.getTextField('Text-nin').setText(res.personalDetails.nationalInsNo)

        form.getTextField('Text-address').setText(res.personalDetails.addLine1)
        form.getTextField('Text-town').setText(res.personalDetails.city)
        form.getTextField('Text-county').setText(res.personalDetails.county)
        form.getTextField('Text-postcode').setText(res.personalDetails.postcode)

        form.getTextField('Text-telephone').setText(res.personalDetails.telephone)

        form.getTextField('Text-email').setText(res.personalDetails.email)


        form.getTextField('emergency-name').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('emergency-mobile').setText(res.emergencyDetails.emergencyTelephone)
        form.getTextField('emergency-relatiion').setText(res.emergencyDetails.relationshipToLearner)

        form.getTextField('criminalConv').setText(res.oppDetails.criminalConv)

        if (res.oppDetails.nationality === 'british') {
            form.getTextField('Text-born-other').setText('Yes')
        } else {
            form.getTextField('Text-57HGnH3k_D').setText(res.oppDetails.countryOfBirth)
        }

        if (res.oppDetails.inWhichCountryDoYouLive)
            form.getTextField('inWhichCountryDoYouLive').setText(res.oppDetails.inWhichCountryDoYouLive)

        form.getTextField('Text-ukresident').setText('Yes')
        form.getTextField('Text-uk12months').setText(res.oppDetails.ukStayPermission12Month ? "Yes" : "No")

        form.getTextField('Text-ethnicity').setText(res.oppDetails.ethnicOrigin)


        form.getTextField('Text-disability-cond').setText(res.oppDetails.disabilities)
        if (res.oppDetails.disabilities === 'Yes') {
            if (res.oppDetails.dValue1) {
                form.getCheckBox('CheckBox-vision-impair').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('CheckBox-hearing-impair').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('CheckBox-disability-mobility').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('CheckBox-complex-disability').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('CheckBox-social/emotional disabilty').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('CheckBox-mental-health').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('CheckBox-modereate-difficulty').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('CheckBox-severe-difficulty').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('CheckBox-dyslexia').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('CheckBox-dyscalculia').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('CheckBox-autism').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('CheckBox-aspergers').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('CheckBox-temporary-illnes').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('CheckBox-speech-disability').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('CheckBox-physical').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('CheckBox-medical-conditon').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('CheckBox-learnng-diff-other').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('CheckBox-disbility-other').check()
            }
            if (res.oppDetails.adhd) {
                form.getCheckBox('CheckBox-adhd').check()
            }
        } else {
            form.getCheckBox('CheckBox-no-dsablity').check()
        }



        if (res.qualificationDetails.englishGrades) {
            form.getTextField('gcse-english-grade').setText(res.qualificationDetails.englishGrades)
        }
        if (res.qualificationDetails.mathGrades) {
            form.getTextField('gcse-maths-grade').setText(res.qualificationDetails.mathGrades)
        }
        form.getTextField('Text-qualification-level').setText(res.qualificationDetails.level)



        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)") {
            form.getTextField('Text-emplyment-status').setText('In paid employment (or self employed)')
        } else if (res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year") {
            form.getTextField('Text-emplyment-status').setText('Employed but on less than threshold per year')
        } else if (res.employmentDetails.employementStatus === "Living in London - Employed or self employed") {
            form.getTextField('Text-emplyment-status').setText('Living in London - Employed or self employed')
        } else if (res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getTextField('Text-emplyment-status').setText('Greater London Employed but on less than threshold')
        }

        if (res.employmentDetails?.employmentType === "Self Employed") {
            form.getTextField('Text-selfemployed').setText('Yes')
        }


        if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
            form.getTextField('Text-fullTimeEducationElse').setText("Yes")
        }

        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {

            form.getTextField('Text-hoursPerWeek').setText(res.employmentDetails.hoursPerWeek)
            form.getTextField('Text-length-emp').setText(res.employmentDetails.length + "Months")
            form.getTextField('Text-employer-name').setText(res.employmentDetails.employerName)
            form.getTextField('Text-employer-add').setText(res.employmentDetails.employerAdd + ', ' + res.employmentDetails.postcode)
            form.getTextField('Text-salary').setText(res.employmentDetails.annualSalary)



        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            form.getTextField('unemployedLength').setText(res.employmentDetails.unemployedLength)
        }


        let benefits = []
        if (res.employmentDetails.dValue1) {
            benefits.push('JSA')
        }
        if (res.employmentDetails.dValue2) {
            benefits.push('Income Support')
        }
        if (res.employmentDetails.dValue3) {
            benefits.push('Council Tax Benefit')
        }
        if (res.employmentDetails.dValue6) {
            benefits.push('Incapacity Benefit')
        }
        if (res.employmentDetails.dValue5) {
            benefits.push('ESA')
        }
        if (res.employmentDetails.dValue7) {
            benefits.push('Housing Benefit')
        }
        if (res.employmentDetails.dValue9) {
            benefits.push('Employed but on low wage')
        }

        if (res.employmentDetails.dValue8) {
            benefits.push('UC')
        }

        form.getTextField('Text-benefits-list').setText(benefits.join())

        if (benefits.length > 0) {
            form.getTextField("Text-recieve-benfits").setText("Yes")
        }



        // let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        // form.getTextField('Text-date').setText(date)
        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}



// FTT college PDF
export const handleFTTCollege = async (ids) => {
    let zip = new JSZip()
    for (let i = 0; i < ids.length; i++) {
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/FTTpdf.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('signature')
        const signImageField2 = form.getTextField('signature2')
        signImageField.setImage(emblemImage)
        signImageField2.setImage(emblemImage)
        form.getTextField('Fulle-nmae').setText(res.personalDetails.firstName + ' ' + res.personalDetails.lastName)
        form.getTextField('Fulle-nmae2').setText(res.personalDetails.firstName + ' ' + res.personalDetails.lastName)

        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        form.getTextField('Text-date').setText(date)
        form.getTextField('Text-date2').setText(date)


        // New Form Stuff
        form.getTextField('Text-title').setText(res.personalDetails.title)


        form.getTextField('Text-sirname').setText(res.personalDetails.lastName)
        form.getTextField('Text-firstname').setText(res.personalDetails.firstName + ' ' + res.personalDetails.middleName)

        form.getTextField('Text-dob').setText(res.personalDetails.dob)
        form.getTextField('Text-gender').setText(res.personalDetails.gender)

        form.getTextField('Text-nin').setText(res.personalDetails.nationalInsNo)

        form.getTextField('Text-address').setText(res.personalDetails.addLine1)
        form.getTextField('Text-town').setText(res.personalDetails.city)
        form.getTextField('Text-county').setText(res.personalDetails.county)
        form.getTextField('Text-postcode').setText(res.personalDetails.postcode)
        form.getTextField('Text-oldpostcode').setText(res.personalDetails.oldPostcode)

        form.getTextField('Text-telephone').setText(res.personalDetails.telephone)

        form.getTextField('Text-email').setText(res.personalDetails.email)
        form.getTextField('Text-ethnicity').setText(res.oppDetails.ethnicOrigin)

        if (res.oppDetails.firstLang === 'Yes') {
            form.getTextField('Text-firstlangenglish').setText("English")
        }

        form.getTextField('emergency-name').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('emergency-mobile').setText(res.emergencyDetails.emergencyTelephone)
        form.getTextField('emergency-relatiion').setText(res.emergencyDetails.relationshipToLearner)

        form.getTextField('criminalConv').setText(res.oppDetails.criminalConv)

        form.getTextField('employmentStatus').setText(res.employmentDetails.employementStatus)
        form.getTextField('Text-employment type').setText(res.employmentDetails.employmentType)
        if (res.employmentDetails.employmentType === 'Self Employed') {
            form.getTextField("Text-selfEmployed").setText('Yes')
        }
        form.getTextField('Text-employmentlength').setText(res.employmentDetails.length + ' months')
        form.getTextField('Text-employerName').setText(res.employmentDetails.employerName)
        form.getTextField('Text-employerAdd').setText(res.employmentDetails.employerAdd)
        form.getTextField('Text-employmentDetailspostcode').setText(res.employmentDetails.postcode)
        form.getTextField('Text-lesssalary').setText('Yes')
        form.getTextField('Text-unemploymentlenght').setText(res.employmentDetails.unemployedLength)

        let benefits = []
        if (res.employmentDetails.dValue1) {
            benefits.push('JSA')
            form.getCheckBox('JSA').check()
        }
        if (res.employmentDetails.dValue2) {
            benefits.push('Income Support')
        }
        if (res.employmentDetails.dValue3) {
            benefits.push('Council Tax Benefit')
        }
        if (res.employmentDetails.dValue6) {
            benefits.push('Incapacity Benefit')
        }
        if (res.employmentDetails.dValue5) {
            benefits.push('ESA')
            form.getCheckBox('esa').check()
        }
        if (res.employmentDetails.dValue7) {
            benefits.push('Housing Benefit')
        }
        if (res.employmentDetails.dValue9) {
            benefits.push('Employed but on low wage')
        }

        if (res.employmentDetails.dValue8) {
            benefits.push('UC')
        }

        form.getTextField('Text-RJBa9lOd8k').setText(benefits.join())



        form.getTextField('countryofbirth').setText(res.oppDetails.countryOfBirth)
        form.getTextField('dateenteredUk').setText(res.oppDetails.dateYouEnteredUK)
        form.getTextField('ukStayPermission12Month').setText(res.oppDetails.ukStayPermission12Month ? 'Yes' : "No")


        if (res.oppDetails.resident === "I am a UK National") {
            form.getCheckBox('CheckBox-50vp8rYfku').check()
        } else if (res.oppDetails.resident === "I have right to abode confirmation") {
            form.getCheckBox('CheckBox-344qJgwgT5').check()
        } else if (res.oppDetails.resident === "I am an Irish Citizen and have been ordinarily resident in the UK or Ireland") {
            form.getCheckBox('CheckBox-_TnxOL7Ihw').check()
        } else if (res.oppDetails.resident === "I have pre-settled status under the EU Settlement Scheme to live and work in the UK") {
            form.getCheckBox('CheckBox-LvNQ5T68CT').check()
        } else if (res.oppDetails.resident === "I have settled status under the EU Settlement Scheme to live and work in the UK") {
            form.getCheckBox('CheckBox-ey06N0rulS').check()
        } else if (res.oppDetails.resident === "I have Indefinite Leave to enter or remain") {
            form.getCheckBox('CheckBox-dUtvJOqdgj').check()
        } else if (res.oppDetails.resident === "I have refugee status") {
            form.getCheckBox('CheckBox-fYaRdlloFp').check()
        } else if (res.oppDetails.resident === "I have Discretionary Leave to enter or remain") {
            form.getCheckBox('CheckBox-g77JLdttN8').check()
        } else if (res.oppDetails.resident === "I have Exceptional Leave to enter or remain") {
            form.getCheckBox('CheckBox-Q7ivicpKaA').check()
        } else if (res.oppDetails.resident === "I have leave to enter or remain under the Ukraine Family Scheme") {
            form.getCheckBox('CheckBox-qSemIBOqBB').check()
        } else if (res.oppDetails.resident === "I have leave to enter or remain under the Ukraine Sponsorship Scheme") {
            form.getCheckBox('CheckBox-ZmUNK6_ZO7').check()
        } else if (res.oppDetails.resident === "I have leave to enter or remain under the Ukraine Extension Scheme") {
            form.getCheckBox('CheckBox--r9scLyQML').check()
        } else if (res.oppDetails.resident === "I am an asylum seeker and have lived in the UK for 6 months or longer whilst my claim is being considered.") {
            form.getCheckBox('CheckBox-4e5jdVehBO').check()
        } else if (res.oppDetails.resident === "Other") {
            form.getCheckBox('CheckBox-80Qu-3Klum').check()
        }


        form.getTextField('Text-qualification-level').setText(res.qualificationDetails.level)

        let disabilityCode = []
        if (res.oppDetails.dValue1) {
            disabilityCode.push('Visual impairment')
        }
        if (res.oppDetails.dValue2) {
            disabilityCode.push('Hearing impairment')
        }
        if (res.oppDetails.dValue3) {
            disabilityCode.push('Disability affecting mobility')
        }
        if (res.oppDetails.dValue4) {
            disabilityCode.push('Profound complex disabilities')
        }
        if (res.oppDetails.dValue5) {
            disabilityCode.push('Social and Emotional Difficulties')
        }
        if (res.oppDetails.dValue6) {
            disabilityCode.push('Mental health difficulty')
        }
        if (res.oppDetails.dValue7) {
            disabilityCode.push('Moderate difficulties')
        }
        if (res.oppDetails.dValue8) {
            disabilityCode.push('Severe difficulties')
        }
        if (res.oppDetails.dValue9) {
            disabilityCode.push('Dyslexia')
        }
        if (res.oppDetails.dValue10) {
            disabilityCode.push('Dyscalculia')
        }
        if (res.oppDetails.dValue11) {
            disabilityCode.push('Autism spectrum disorder')
        }
        if (res.oppDetails.dValue12) {
            disabilityCode.push('Aspergers Syndrome')
        }
        if (res.oppDetails.dValue13) {
            disabilityCode.push('Temporary disability after illness')
        }
        if (res.oppDetails.dValue14) {
            disabilityCode.push('Speech Language')
        }
        if (res.oppDetails.dValue15) {
            disabilityCode.push('Other physical disability')
        }
        if (res.oppDetails.dValue16) {
            disabilityCode.push('Medical condition')
        }
        if (res.oppDetails.dValue17) {
            disabilityCode.push('learning-difficulty')
        }
        if (res.oppDetails.dValue18) {
            disabilityCode.push('Other')
        }
        if (disabilityCode[0]) {
            form.getTextField('disability1code').setText(disabilityCode[0])
        } else if (disabilityCode[1]) {
            form.getTextField('disability2code').setText(disabilityCode[1])
        } else if (disabilityCode[2]) {
            form.getTextField('disability3code').setText(disabilityCode[2])
        } else if (disabilityCode[3]) {
            form.getTextField('disability4code').setText(disabilityCode[3])
        }


        form.getTextField('Paragraph-tFNhy0xV-W').setText(res.whyEnrollInthisProgram)
        form.getTextField('Paragraph-2E9Q1Ksz8R').setText(res.anythingToBeAwareOf)

        if (res.declaration.houseHoldMem) {
            form.getCheckBox('CheckBox-OkTBYzYwo0').check()
        }
        if (res.declaration.houseHoldMem2) {
            form.getCheckBox('CheckBox-tDCKH3r-ex').check()
        }
        if (res.declaration.singleHouse) {
            form.getCheckBox('CheckBox-uZJKy9Kho8').check()
        }
        if (res.declaration.noneOfThese) {
            form.getCheckBox('CheckBox-2oXSy1q0EX').check()
        }
        if (res.declaration.confirmation) {
            form.getCheckBox('CheckBox-h6-WSXcfvL').check()
        }

        if (res.declaration.aboutCourse)
            form.getTextField('Text--SxJgsgRaI').setText("yes")
        if (res.declaration.forSurveys)
            form.getTextField('Text-6426NLlKqi').setText('yes')

        if (res.declaration.prefContact1)
            form.getTextField('Text-SRAQIOPyKV').setText('yes')
        if (res.declaration.prefContact2)
            form.getTextField('Text-OowxO21Ao9').setText('yes')
        if (res.declaration.prefContact)
            form.getTextField('Text-dvYQrG9vYD').setText('yes')


        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })

        let images = [res.nationalId, res.certificateLink],
            index = 0 // for loader
        function loadAsArrayBuffer(url, callback) {
            let xhr = new XMLHttpRequest()
            xhr.open('GET', url)
            xhr.responseType = 'arraybuffer'
            xhr.onerror = function () {
                /* handle errors*/
            }
            xhr.onload = function () {
                if (xhr.status === 200) {
                    callback(xhr.response, url)
                } else {
                    /* handle errors*/
                }
            }
            xhr.send()
        }

        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(ensureHttps(res.nationalId)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(ensureHttps(res.certificateLink)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment1)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment2)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment3)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(ensureHttps(res.oppDetails.attachment4)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence)).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence1)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(ensureHttps(res.employmentDetails.evidence2)).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }

        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}


export const handleRnnClg23OLD = async (ids) => {
    let zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/rnnformOLD23.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = res.signature
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)

        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', '')
        form.getTextField('Text Field 339').setText(date[0] + date[1])
        form.getTextField('Text Field 338').setText(date[2] + date[3])
        form.getTextField('Text Field 337').setText(date[4] + date[5] + date[6] + date[7])

        const signImageField = form.getTextField('Text Field 298')
        signImageField.setImage(emblemImage)


        form.getTextField('Text Field 297').setText(res.personalDetails.firstName + ' ' + res.personalDetails.lastName)


        form.getTextField('Text Field 308').setText(res.detailsFormData.appliedCourse)
        if (res.personalDetails.title === "Mr") {
            form.getCheckBox("Check Box 3").check()
        } else if (res.personalDetails.title === "Miss") {
            form.getCheckBox("Check Box 5").check()
        } else if (res.personalDetails.title === "Mrs") {
            form.getCheckBox("Check Box 4").check()
        } else if (res.personalDetails.title === "other") {
            form.getTextField("Text Field 15").setText("Other")
        }

        form.getTextField('Text Field 13').setText(res.personalDetails.lastName)
        form.getTextField('Text Field 14').setText(res.personalDetails.firstName)

        form.getTextField('Text Field 352').setText(res.personalDetails.nationalInsNo)


        let testDate = (String(moment(res.personalDetails.dob.slice(0, 10)).format('DD/MM/YYYY'))).replaceAll('/', '')
        form.getTextField('Text Field 350').setText(testDate[0] + testDate[1])
        form.getTextField('Text Field 349').setText(testDate[2] + testDate[3])
        form.getTextField('Text Field 348').setText(testDate[4] + testDate[5] + testDate[6] + testDate[7])
        form.getTextField('Text Field 48').setText(res.personalDetails.addLine1 + ", " + res.personalDetails.city + ", " + res.personalDetails.county)
        form.getTextField('Text Field 347').setText(res.personalDetails.postcode)
        form.getTextField('Text Field 55').setText(res.personalDetails.yearsAtAdd + ' years')
        form.getTextField('Text Field 346').setText(res.personalDetails.telephone)
        form.getTextField('Text Field 56').setText(res.personalDetails.email)
        form.getTextField('Text Field 68').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text Field 70').setText(res.emergencyDetails.relationshipToLearner)
        form.getTextField('Text Field 345').setText(res.emergencyDetails.emergencyTelephone)

        if (res.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
            res.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
            res.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
            res.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
            form.getCheckBox("Check Box 8").check()
            form.getCheckBox("Check Box 29").check()
            if (res.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
                form.getCheckBox("Check Box 13").check()
            } else if (res.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
                form.getCheckBox("Check Box 14").check()
            } else if (res.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
                form.getCheckBox("Check Box 16").check()
            } else if (res.employmentDetails.hoursPerWeek === "30+ hours per week") {
                form.getCheckBox("Check Box 17").check()
            }

            if (res.employmentDetails.length === "Up to 3") {
                form.getCheckBox("Check Box 19").check()
            } else if (res.employmentDetails.length === "4-6") {
                form.getCheckBox("Check Box 20").check()
            } else if (res.employmentDetails.length === "7-12") {
                form.getCheckBox("Check Box 21").check()
            } else if (res.employmentDetails.length === "12+") {
                form.getCheckBox("Check Box 22").check()
            }

        } else if (res.employmentDetails.employementStatus === "Unemployed, looking for work") {
            form.getCheckBox("Check Box 18").check()
            form.getCheckBox("Check Box 23").check()
            if (res.employmentDetails.lookingForWork === "No") {
                form.getCheckBox("Check Box 10").check()
            } else {
                form.getCheckBox("Check Box 9").check()
            }

            if (res.employmentDetails.unemployedLength === "0-5 months") {
                form.getCheckBox("Check Box 24").check()
            } else if (res.employmentDetails.unemployedLength === "6-11 months") {
                form.getCheckBox("Check Box 25").check()
            } else if (res.employmentDetails.unemployedLength === "12-23 months") {
                form.getCheckBox("Check Box 26").check()
            } else if (res.employmentDetails.unemployedLength === "24-35 months") {
                form.getCheckBox("Check Box 27").check()
            } else if (res.employmentDetails.unemployedLength === "Over 36 months") {
                form.getCheckBox("Check Box 28").check()
            }

        }

        if (res.employmentDetails.dValue1) {
            form.getCheckBox('Check Box 31').check()
        }

        if (res.employmentDetails.dValue5) {
            form.getCheckBox('Check Box 35').check()
        }

        if (res.employmentDetails.dValue2) {
            form.getCheckBox('Check Box 34').check()
        }
        if (res.employmentDetails.dValue3) {
            form.getCheckBox('Check Box 33').check()
        }

        if (res.employmentDetails.dValue7) {
            form.getCheckBox('Check Box 32').check()
        }

        if (res.employmentDetails.dValue8) {
            form.getCheckBox('Check Box 30').check()
        }
        if (res.employmentDetails.dValue9) {
            form.getCheckBox('Check Box 36').check()
        }
        if (res.oppDetails.statementScheme === 'No') {
            form.getCheckBox('Check Box 38').check()
        } else {
            form.getCheckBox('Check Box 37').check()
        }

        form.getTextField('Text Field 98').setText(res.oppDetails.nationality)
        if (res.oppDetails.inWhichCountryDoYouLive)
            form.getTextField('Text Field 97').setText(res.oppDetails.inWhichCountryDoYouLive)
        form.getCheckBox("Check Box 39").check()
        form.getCheckBox('Check Box 56').check()

        if (res.qualificationDetails.fullTimeEducationElse) {
            if (res.qualificationDetails.fullTimeEducationElse === "Yes") {
                form.getCheckBox('Check Box 73').check()
            } else {
                form.getCheckBox('Check Box 74').check()
            }

        }

        if (res.qualificationDetails.level === 'Entry Level') {
            form.getCheckBox('Check Box 81').check()
        } else if (res.qualificationDetails.level === 'None') {
            form.getCheckBox('Check Box 75').check()
        } else if (res.qualificationDetails.level === 'Level 1') {
            form.getCheckBox('Check Box 76').check()
        } else if (res.qualificationDetails.level === 'Level 2') {
            form.getCheckBox('Check Box 82').check()
        } else if (res.qualificationDetails.level === 'Level 3') {
            form.getCheckBox('Check Box 83').check()
        } else if (res.qualificationDetails.level === 'Level 4') {
            form.getCheckBox('Check Box 84').check()
        } else if (res.qualificationDetails.level === 'Level 5') {
            form.getCheckBox('Check Box 79').check()
        } else if (res.qualificationDetails.level === 'Level 6') {
            form.getCheckBox('Check Box 85').check()
        } else if (res.qualificationDetails.level === 'Level 7') {
            form.getCheckBox('Check Box 80').check()
        } else if (res.qualificationDetails.level === 'Level 7 and above') {
            form.getCheckBox('Check Box 80').check()
        } else if (res.qualificationDetails.level === 'Full Level 2') {
            form.getCheckBox('Check Box 77').check()
        } else if (res.qualificationDetails.level === 'Full Level 3') {
            form.getCheckBox('Check Box 78').check()
        }
        if (res.qualificationDetails.englishGrades) {
            form.getTextField('Text Field 136').setText(res.qualificationDetails.englishGrades)
        }
        if (res.qualificationDetails.mathGrades) {
            form.getTextField('Text Field 139').setText(res.qualificationDetails.mathGrades)
        }
        if (res.qualificationDetails.GCSEEnglishabove) {
            form.getCheckBox('Check Box 86').check()
        }
        if (res.qualificationDetails.GCSEMathsabove) {
            form.getCheckBox('Check Box 89').check()
        }



        if (res.personalDetails.gender === 'Female') {
            form.getCheckBox('Check Box 93').check()
        } else {
            form.getCheckBox('Check Box 92').check()
        }
        if (res.oppDetails.criminalConv === "Yes") {
            form.getCheckBox('Check Box 98').check()
        } else {
            form.getCheckBox('Check Box 99').check()
        }


        if (
            res.oppDetails.ethnicOrigin ===
            'English/Welsh/Scottish/Northern Irish/British'
        ) {
            form.getCheckBox('Check Box 100').check()
        } else if (res.oppDetails.ethnicOrigin === 'Irish') {
            form.getCheckBox('Check Box 101').check()
        } else if (res.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
            form.getCheckBox('Check Box 102').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other white background') {
            form.getCheckBox('Check Box 103').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black Carribean') {
            form.getCheckBox('Check Box 104').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Black African') {
            form.getCheckBox('Check Box 105').check()
        } else if (res.oppDetails.ethnicOrigin === 'White and Asian') {
            form.getCheckBox('Check Box 123').check()
        } else if (
            res.oppDetails.ethnicOrigin ===
            'Any other mixed/multiple ethnic background'
        ) {
            form.getCheckBox('Check Box 107').check()
        } else if (res.oppDetails.ethnicOrigin === 'Indian') {
            form.getCheckBox('Check Box 125').check()
        } else if (res.oppDetails.ethnicOrigin === 'Pakistani') {
            form.getCheckBox('Check Box 126').check()
        } else if (res.oppDetails.ethnicOrigin === 'Bangladeshi') {
            form.getCheckBox('Check Box 127').check()
        } else if (res.oppDetails.ethnicOrigin === 'Chinese') {
            form.getCheckBox('Check Box 128').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other Asian background') {
            form.getCheckBox('Check Box 106').check()
        } else if (res.oppDetails.ethnicOrigin === 'African') {
            form.getCheckBox('Check Box 124').check()
        } else if (res.oppDetails.ethnicOrigin === 'Caribbean') {
            form.getCheckBox('Check Box 129').check()
        } else if (
            res.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
        ) {
            form.getCheckBox('Check Box 108').check()
        } else if (res.oppDetails.ethnicOrigin === 'Arab') {
            form.getCheckBox('Check Box 130').check()
        } else if (res.oppDetails.ethnicOrigin === 'Any other ethnic group') {
            form.getCheckBox('Check Box 131').check()
        }

        if (res.oppDetails.disabilities === 'Yes') {

            if (res.oppDetails.dValue1) {
                form.getCheckBox('Check Box 118').check()
            }
            if (res.oppDetails.dValue2) {
                form.getCheckBox('Check Box 132').check()
            }
            if (res.oppDetails.dValue3) {
                form.getCheckBox('Check Box 110').check()
            }
            if (res.oppDetails.dValue4) {
                form.getCheckBox('Check Box 112').check()
            }
            if (res.oppDetails.dValue5) {
                form.getCheckBox('Check Box 134').check()
            }
            if (res.oppDetails.dValue6) {
                form.getCheckBox('Check Box 133').check()
            }
            if (res.oppDetails.dValue7) {
                form.getCheckBox('Check Box 135').check()
            }
            if (res.oppDetails.dValue8) {
                form.getCheckBox('Check Box 139').check()
            }
            if (res.oppDetails.dValue9) {
                form.getCheckBox('Check Box 115').check()
            }
            if (res.oppDetails.dValue10) {
                form.getCheckBox('Check Box 136').check()
            }
            if (res.oppDetails.dValue11) {
                form.getCheckBox('Check Box 116').check()
            }
            if (res.oppDetails.dValue12) {
                form.getCheckBox('Check Box 121').check()
            }
            if (res.oppDetails.dValue13) {
                form.getCheckBox('Check Box 120').check()
            }
            if (res.oppDetails.dValue14) {
                form.getCheckBox('Check Box 137').check()
            }
            if (res.oppDetails.dValue15) {
                form.getCheckBox('Check Box 119').check()
            }
            if (res.oppDetails.dValue16) {
                form.getCheckBox('Check Box 111').check()
            }
            if (res.oppDetails.dValue17) {
                form.getCheckBox('Check Box 140').check()
            }
            if (res.oppDetails.dValue18) {
                form.getCheckBox('Check Box 113').check()
            }
        } else {
            form.getCheckBox('Check Box 109').check()
            form.getCheckBox('Check Box 114').check()
        }


        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })


        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(res.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(res.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(res.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(res.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(res.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(res.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(res.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(res.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(res.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.newDeclaration) {
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res),
                { base64: true }
            )
        }
        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}



export const handleHeartOfYorkshireCollege = async (ids) => {
    let zip = new JSZip()
    // console.log('Function Started')
    for (let i = 0; i < ids.length; i++) {
        // console.log('Id:', ids[i])
        const response = await authService.getTestForm(ids[i])
        const res = response.data.data
        const formPdfBytes = await fetch('/yorkshire.pdf', { cache: 'reload' }).then(
            (res) => res.arrayBuffer()
        )
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        const form = pdfDoc.getForm()
        const emblemUrl = ensureHttps(res.signature) //
        const emblemImageBytes = await fetch(emblemUrl).then((res) => res.arrayBuffer())
        const emblemImage = await pdfDoc.embedPng(emblemImageBytes)
        const signImageField = form.getTextField('signature')
        signImageField.setImage(emblemImage)

        let date = (String(moment(res.createdAt).format('DD/MM/YYYY'))).replaceAll('/', ' ')
        form.getTextField('Text-date').setText(date)


        // New Form Stuff
        form.getTextField('Text-title').setText(res.personalDetails.title)


        form.getTextField('Text-sirname').setText(res.personalDetails.lastName)
        form.getTextField('Text-firstname').setText(res.personalDetails.firstName + ' ' + res.personalDetails.middleName)

        form.getTextField('Text-dob').setText(res.personalDetails.dob)
        form.getTextField('Text-gender').setText(res.personalDetails.gender)
        
        let yearDOB = parseInt(String(moment(res.personalDetails.dob).year()))
        let monthDOB = parseInt(String(moment(res.personalDetails.dob).month()))
        
        let calculatedAge = 2024 - yearDOB
        monthDOB++
        if (monthDOB > 8) {
            calculatedAge--
        }
        form.getTextField('Text-agebyaug').setText(calculatedAge.toString())


        form.getTextField('Text-address').setText(res.personalDetails.addLine1)
        form.getTextField('Text-town').setText(res.personalDetails.city)
        form.getTextField('Text-county').setText(res.personalDetails.county)
        form.getTextField('Text-postcode').setText(res.personalDetails.postcode)

        form.getTextField('Text-telephone').setText(res.personalDetails.telephone)

        form.getTextField('Text-email').setText(res.personalDetails.email)
        form.getTextField('Text-ethnicity').setText(res.oppDetails.ethnicOrigin)


        form.getTextField('criminalConv').setText(res.oppDetails.criminalConv)
        form.getTextField('nationality').setText(res.oppDetails.nationality)
        form.getTextField('CountryOfResidence_es_').setText(res.oppDetails?.inWhichCountryDoYouLive)
        form.getTextField('residency').setText(res.oppDetails?.perField)       
        

        form.getTextField('Text-emergencyname').setText(res.emergencyDetails.emergencyContactName)
        form.getTextField('Text-emergencyphone').setText(res.emergencyDetails.emergencyTelephone)


        form.getTextField('Text-qualification-level').setText(res.qualificationDetails.level)

        form.getTextField('employmentStatus').setText(res.employmentDetails.employementStatus)
        form.getTextField('Text-hoursperweek').setText(res.employmentDetails.hoursPerWeek);
        
        form.getTextField('Text-unemploymentlenght').setText(res.employmentDetails.unemployedLength)
            



        if (res.declaration.prefContact1)
            form.getCheckBox('CheckBox-cw89L7SqIi').check()
        if (res.declaration.prefContact2)
            form.getCheckBox('CheckBox-hAJgYXLA6L').check()
        if (res.declaration.prefContact)
            form.getCheckBox('CheckBox-zSK-1RcUt5').check()

        form.getTextField('Text Field 96').setText(res.detailsFormData.appliedCourse)


        form.flatten()

        const pdfBytes = await pdfDoc.save()

        const file = new Blob([pdfBytes], {
            type: 'application/pdf',
        })

        convertFileToBase64(file).then((res) => {
            const { fileName, base64 } = res
        })


        const fileURL = URL.createObjectURL(file)
        zip.folder(
            res.personalDetails.firstName + res.personalDetails.lastName
        ).file(
            res.personalDetails.firstName +
            res.personalDetails.lastName +
            '.pdf',
            file,
            { base64: true }
        )
        if (res.nationalId) {
            const imageBlob = fetch(res.nationalId).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('National_ID.' + (res.nationalId).substr((res.nationalId).length - 3), imageBlob)
        }
        if (res.certificateLink) {
            const imageBlob = fetch(res.certificateLink).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Certificate.' + (res.certificateLink).substr((res.certificateLink).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment1) {
            const imageBlob = fetch(res.oppDetails.attachment1).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment1.' + (res.oppDetails.attachment1).substr((res.oppDetails.attachment1).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment2) {
            const imageBlob = fetch(res.oppDetails.attachment2).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment2.' + (res.oppDetails.attachment2).substr((res.oppDetails.attachment2).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment3) {
            const imageBlob = fetch(res.oppDetails.attachment3).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment3.' + (res.oppDetails.attachment3).substr((res.oppDetails.attachment3).length - 3), imageBlob)
        }
        if (res.oppDetails.attachment4) {
            const imageBlob = fetch(res.oppDetails.attachment4).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('Attachment4.' + (res.oppDetails.attachment4).substr((res.oppDetails.attachment4).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence) {
            const imageBlob = fetch(res.employmentDetails.evidence).then((response) =>
                response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence.' + (res.employmentDetails.evidence).substr((res.employmentDetails.evidence).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence1) {
            const imageBlob = fetch(res.employmentDetails.evidence1).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence1.' + (res.employmentDetails.evidence1).substr((res.employmentDetails.evidence1).length - 3), imageBlob)
        }
        if (res.employmentDetails.evidence2) {
            const imageBlob = fetch(res.employmentDetails.evidence2).then(
                (response) => response.blob()
            )
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file('evidence2.' + (res.employmentDetails.evidence2).substr((res.employmentDetails.evidence2).length - 3), imageBlob)
        }
        if (res.newDeclaration) {
            zip.folder(
                res.personalDetails.firstName + res.personalDetails.lastName
            ).file(
                'New Declaration.pdf',
                newDeclarationPDF(res),
                { base64: true }
            )
        }
        if (res.assessment) {
            if (res.assessment.english) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment1(res),
                    { base64: true }
                )
            }
            if (res.assessment.answer1) {
                zip.folder(
                    res.personalDetails.firstName + res.personalDetails.lastName
                ).file(
                    'English Assessment.pdf',
                    englishassessment2(res),
                    { base64: true }
                )
            }
        }
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Group.zip')
    })
}
